import { markingFilterData } from '../components/Marking/MarkingFilterData';
import { Iloop54Facets } from '../types/loop54Facets';

export const filterFacetCombiner = (
  filters: Iloop54Facets[],
  sweetenedFilters,
  sweetFilterTypes?: string[]
) => {
  const combinedFilters = Object.values(filters || [])?.map(filter => {
    if (sweetFilterTypes?.includes(filter.name)) {
      const extendedFilter = markingFilterData(sweetenedFilters, filter);
      return {
        ...filter,
        options: extendedFilter?.sort((optA, optB) =>
          optA?.sortOrder && optB?.sortOrder
            ? optA.sortOrder > optB.sortOrder
              ? 1
              : -1
            : 1
        )
      };
    } else {
      const F = filter.options.map(option => {
        const matchedFilter = sweetenedFilters?.find(
          item => item.code === option.option
        );

        return matchedFilter
          ? { ...option, niceName: matchedFilter.name }
          : option;
      });
      return {
        ...filter,
        options: F
      };
    }
  });
  return combinedFilters;
};
