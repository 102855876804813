import React, { useState, useEffect, useRef } from 'react';
import cs from 'classnames';

import { withCollapse } from '../../components/Collapseable';

import './ShrinkedOpacityText.scss';

interface IShrinkedOpacityText {
  collapse: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  collapsed: boolean;
  children: any;
}

const ShrinkedOpacityText: React.FC<IShrinkedOpacityText> = ({
  collapse,
  collapsed,
  children
}) => {
  let filteredChildren = children.filter(Boolean);
  const [isFullHeight, setActive] = useState(true);
  const ref = useRef<any>(null);
  collapsed = isFullHeight ? collapsed : false;

  useEffect(() => {
    const styles = getComputedStyle(ref.current);
    if (ref.current.clientHeight < parseInt(styles.maxHeight, 10)) {
      setActive(false);
    }
  }, []);

  return (
    <div className="c-shrinkreveal">
      <div
        ref={ref}
        className={cs('c-shrinkreveal__content', {
          collapsed: collapsed
        })}
      >
        {filteredChildren}
      </div>
      {isFullHeight && (
        <div
          className="c-shrinkreveal__toggle"
          role="button"
          tabIndex={0}
          onClick={collapse}
        >
          {collapsed ? <span>Läs mer</span> : <span>Läs mindre</span>}
        </div>
      )}
    </div>
  );
};

export default withCollapse(ShrinkedOpacityText, true);
