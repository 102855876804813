import React from 'react';
import Grid from '../Grid';
import RecipeCard from '../RecipeCard';
import { RecipeCard as SkeletonRecipeCard } from '../Skeletons';
import Card from '../Card';
import Spot from '../Spot';
import './RecipeList.scss';
import Pagination from '../Pagination';
import { RecipeSchemaList } from '../SchemaMeta';
import { IExtendedRecipe } from '../../types/Loop54Recipes';
import qs from 'query-string';

interface TRecipeList {
  fetching: boolean;
  categoryId: number;
  requestMoreRecipes: Function;
  pagination: any;
  setRecipeFavorite: Function;
  removeFavorite: Function;
  items: IExtendedRecipe[];
  impressionListName?: string;
  listTeaser?: any;
  skeletons?: number;
  withoutPaginationAndFilter?: boolean;
  minimal?: boolean;
}

const RecipeList = ({
  fetching,
  categoryId,
  requestMoreRecipes,
  pagination,
  setRecipeFavorite,
  removeFavorite,
  items = [],
  listTeaser,
  skeletons = 20,
  withoutPaginationAndFilter,
  minimal,
  impressionListName
}: TRecipeList) => {
  return (
    <>
      <RecipeSchemaList items={items} />
      <div className="c-recipelist">
        <Grid className="cards">
          {listTeaser && listTeaser.image && <Spot {...listTeaser} />}
          {items.map((item, index) => (
            <Card key={item.id}>
              <RecipeCard
                {...item}
                setRecipeFavorite={setRecipeFavorite}
                removeFavorite={removeFavorite}
                minimal={minimal}
                impressionListName={impressionListName}
                impressionListPosition={index + 1}
              />
            </Card>
          ))}
          {fetching &&
            items?.length === 0 &&
            [...Array(skeletons)].map((e, i) => (
              <SkeletonRecipeCard key={i} className="card" />
            ))}
        </Grid>

        {!withoutPaginationAndFilter && pagination && items?.length > 0 && (
          <Pagination
            fetchPage={page => {
              const params = qs.parse(location.search);

              requestMoreRecipes(categoryId, {
                page: page,
                SortOrder: params?.SortOrder,
                SortField: params?.SortField,
                PriceOnly: params?.PriceOnly
              });
            }}
            pageCount={pagination?.pageCount}
            pageIndex={pagination?.pageIndex}
            totalCount={pagination?.totalCount}
          />
        )}
      </div>
    </>
  );
};

export default RecipeList;
