import React from 'react';
import Select from '../../Select';
import './CategoryFiltersSort.scss';
import { sortParamsHelper } from '../../../lib/sorting';

const CategoryFiltersSort = ({ id, activeSort, options, sortAction }) => {
  return (
    <div className="c-category-filters-sort">
      <div className="c-category-filters-sort__label">Sortera efter</div>
      <div className="c-category-filters-sort__select-wrap">
        <Select
          onChange={value => {
            sortAction(id, sortParamsHelper(value, options));
          }}
          options={options}
          defaultValue={activeSort}
        />
      </div>
    </div>
  );
};

export default CategoryFiltersSort;
