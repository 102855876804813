import { createSelector } from 'reselect';
import { activeStoreNoSelector } from '../assortments';
import { setImage } from '../../lib/product';
import groupby from 'lodash.groupby';
import { IMAGE_PATHS } from '../../constants/imagePaths';

export const getSingleCateredMeal = createSelector(
  (state, id) => ({
    items: state.catering.products,
    storeId: activeStoreNoSelector(state),
    id
  }),
  catering => ({
    products: catering.items
      .filter(item => item.id === catering.id)
      .map(item => {
        const filteredVariants = item.variants.filter(x => x.storeDetails);

        const variants = filteredVariants.map((variant, index) => ({
          ...variant,
          prices: variant.storeDetails.v_current_price,
          isChecked: index === 0
        }));

        return {
          ...item,
          image: setImage(item.variants[0].images, 0, IMAGE_PATHS.CATERING),
          variants: groupby(variants, variant => variant.name)
        };
      })
  })
);
