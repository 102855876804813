import React, { useState, useEffect, useRef } from 'react';

import ProductList from '../ProductList';
import { setProductFavorite, removeFavorite } from '../../actions/auth';

import { useDispatch, useSelector } from 'react-redux';
import { fetchRecommendedProducts } from '../../api/endpoints/product';
import { selectCurrentAssortment } from '../../selectors/assortments';
import { AppState } from '../../reducers';
import { LoadMoreToolbar } from '../LoadMoreToolbar';
import * as ga4 from '@citygross/analytics';
import { formatPrice } from '../../store/middleware/analytics';
import { loopProductDefaults } from '../../selectors/product/productSelectors';
import { IExtendedProduct } from '../../types/storefront/product';
import { ga4ImpressionList } from '../../lib/analytics/analytics';

interface IProps {
  productId: string;
  excludeCategoryByCode?: string;
  isFlyout?: boolean;
}

const RecommendedProducts: React.FC<IProps> = ({
  productId,
  isFlyout,
  excludeCategoryByCode
}) => {
  const [products, setProducts] = useState<IExtendedProduct[]>([]);
  const [paginationIndex, setPaginationIndex] = useState<number>(0);
  const dispatch = useDispatch();

  const activeStore = useSelector((state: AppState) =>
    selectCurrentAssortment(state)
  );

  const componentMounted = useRef(true);

  const amountOfProductsToFetch = 8;

  useEffect(() => {
    const ac = new AbortController();

    fetchRecommendedProducts(
      amountOfProductsToFetch,
      productId,
      excludeCategoryByCode
    )
      .then(({ data }) => {
        if (data.items && data.items.length > 0 && componentMounted.current) {
          const products = data.items.map(item => loopProductDefaults(item));
          setProducts(products);
        }

        try {
          const impressionListName = 'ProductSingle - Liknande varor';
          ga4ImpressionList(data.items, impressionListName);
        } catch (error) {
          console.error(error);
        }
      })
      .catch(e => {
        console.error(e);
        if (componentMounted.current) {
          setProducts([]);
        }
      });
    return () => {
      ac.abort();
      componentMounted.current = false;
      if (isFlyout) setProducts([]);
    };
  }, [activeStore]);

  const getRecommendedProducts = () => {
    if (paginationIndex === 0) {
      const halfLength = Math.ceil(products.length / 2);
      const arrayCopy = [...products];
      return arrayCopy.splice(0, halfLength);
    }
    return products;
  };

  if (!products || !products.length) {
    return null;
  }

  return (
    <div className="c-productdetails__related">
      <h2>Rekommenderade varor</h2>
      <ProductList
        fetching={false}
        setProductFavorite={(id, item) =>
          dispatch(setProductFavorite(id, item))
        }
        removeFavorite={x => dispatch(removeFavorite(x))}
        items={getRecommendedProducts()}
        colStructure={'l-column-15_xs-30_sm-20_md-15_lg-15_xlg-15-mobileGutter'}
      />
      {/* We do not want pagination on recommendations, duo to a fluent relevance, therefore this nasty hack. */}
      <LoadMoreToolbar
        requestMore={() => setPaginationIndex(1)}
        count={
          paginationIndex === 0
            ? Math.ceil(products.length / 2)
            : amountOfProductsToFetch
        }
        totalCount={Math.min(products?.length, amountOfProductsToFetch)}
        fetching={false}
      />
    </div>
  );
};

export default RecommendedProducts;
