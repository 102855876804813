import { loopRequest } from '../../lib/request';
import queryParams from '../../lib/queryParams';

import { ILoop54ProductResponse } from '../../types/loop54Product';
import { IValidParams } from '../../lib/getValidParams';

export const DEFAULT_PRODUCTPAGE_SIZE = 24;
export const DEFAULT_PRODUCTBLOCK_SIZE = 20;

interface ILoop54PageParams {
  page: number;
  size: number;
}
export const fetchLoopProductById = (
  productId: string | string[],
  // @TODO handle
  sort?: string | null,
  size?: number | null,
  sortOrder?: string
): Promise<ILoop54ProductResponse> => {
  return loopRequest(
    `/Loop54/products/${productId}?${sort ? `SortField=${sort}&` : ''}${
      size ? `take=${size}&` : ''
    }${sort && sortOrder ? `SortOrder=${sortOrder}` : ''}`
  );
};

export const fetchLoopProductsByCategory = (
  categoryId: string,
  params,
  defaultParams?: IValidParams
): Promise<ILoop54ProductResponse> => {
  const { categoryName, store, SortField, SortOrder, ...rest } = params;
  let sort = {};
  if (SortField) {
    sort = {
      SortField: SortField,
      SortOrder: SortOrder
    };
  }
  const opts = queryParams({
    skip: (params?.page ?? 0) * DEFAULT_PRODUCTPAGE_SIZE,
    ...rest,
    ...sort,
    categoryName: categoryName,
    ...defaultParams,
    store: store,
    take: DEFAULT_PRODUCTPAGE_SIZE
  });

  const queryString = `/Loop54/category/${categoryId}/products?${opts}`;

  return loopRequest(queryString);
};

// @TODO wait for endpoint/filter
export const fetchProductsByFilterQuery = (
  raw: string,
  page = 0,
  size = DEFAULT_PRODUCTBLOCK_SIZE
): Promise<ILoop54ProductResponse> => {
  return loopRequest(
    `/Loop54/products?${raw}&skip=${page *
      DEFAULT_PRODUCTBLOCK_SIZE}&take=${size}`
  );
};

export const fetchRelatedProducts = (
  productId: string,
  size: number,
  offerName?: string,
  signal?: any
): Promise<ILoop54ProductResponse> => {
  return loopRequest(
    `/Loop54/products/${productId}/related?skip=0&take=${size}${
      offerName ? `&ExcludedOfferId=${offerName}` : ''
    }`,
    signal
  );
};

export const fetchTopSellers = ({
  page,
  size = 20
}: ILoop54PageParams): Promise<ILoop54ProductResponse> => {
  const opts = queryParams({
    take: size,
    skip: (page ?? 0) * size
  });
  return loopRequest(`/Loop54/products/topsellers?${opts}`);
};

// TODO: pass the optional values into a settings object instead of queueing them in the func call.
// One should not need to pass in everything?

export const fetchRecommendedProducts = (
  size: number,
  productId?: string,
  excludeBfCategoryCode?: string,
  signal?: any
): Promise<ILoop54ProductResponse> => {
  return loopRequest(
    `/Loop54/products/${productId}/recommended?skip=0&take=${size}&ExcludedBfCategoryCode=${excludeBfCategoryCode}`,
    signal
  );
};

export const fetchProductsInOffer = (
  offerId: string,
  currentProductId?: string
): Promise<ILoop54ProductResponse> => {
  return loopRequest(
    `/Loop54/offer/${offerId}/products?ExcludeProductId=${currentProductId}`
  );
};
