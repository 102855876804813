import qs from 'query-string';
import * as endpoints from '../../api/endpoints/product';
import {
  isFetchingProduct,
  isFetchingProducts,
  receiveProduct,
  receiveProducts,
  paginateProducts,
  isPaginatingProducts,
  fetchProductError,
  fetchProductsError
} from './sync';
import { updateHistory } from '../../lib/utils';
import { AppThunk } from '../../reducers';
import {
  ILoop54Product,
  ILoop54ProductResponse
} from '../../types/loop54Product';
import { getValidParams, IValidParams } from '../../lib/getValidParams';
import { mapLoopDataToProductState } from '../../lib/loop54Data';
import { removeFalsyValues } from '../../lib/removeFalseyKeyValue';

const ignoredKeys = ['page', 'f.brand', 'Sort'];

/* Kind of a toggle, if there's already an entry in the current query with the same
   value. We remove it otherwise we just add it with the rest */
const uniqueEntries = (currentFilter, val) => {
  const current = currentFilter.split(',');
  return current.includes(val)
    ? current.filter(v => v !== val)
    : [].concat(current, val);
};

/* Merges query parameters, array values are unique */
const mergeQuery = (next: Map<string, string>, { search }: Location) => {
  const current = qs.parse(search, { arrayFormat: 'index' });
  const merged = Object.entries(next).reduce((acc, [key, val]) => {
    acc[key] = current[key]
      ? [...uniqueEntries(current[key], val)].join()
      : [].concat(val).join();

    return acc;
  }, {});

  return Object.assign({}, current, merged);
};

export const getProductById = (productId: string) => dispatch => {
  dispatch(isFetchingProduct(true));
  return endpoints
    .fetchLoopProductById(productId)
    .then(({ data }) => {
      dispatch(receiveProduct(data?.items));

      return data;
    })
    .catch(err => {
      dispatch(fetchProductError(err));
      dispatch(isFetchingProduct(false));
      return Promise.reject(err);
    });
};

const getProducts = (
  endpoint: () => Promise<ILoop54ProductResponse>,
  receiveFunc: Function = receiveProducts,
  isFetching: Function = isFetchingProducts
): AppThunk => dispatch => {
  dispatch(isFetching(true));
  return endpoint()
    .then(({ data }) => {
      dispatch(receiveFunc(data));
      return data;
    })
    .catch((err: string) => {
      dispatch(fetchProductsError(err));

      return Promise.reject(err);
    });
};

type TProductsByCategoryParams = {
  size?: number;
  page?: number;
};

export const getProductsByCategory = (
  categoryId: string,
  params: TProductsByCategoryParams,
  defaultParams?: IValidParams
) => {
  const validParams = getValidParams(defaultParams ?? {});
  return getProducts(
    () =>
      endpoints.fetchLoopProductsByCategory(
        categoryId,
        removeFalsyValues(params),
        validParams
      ),
    response => {
      const { data, meta } = mapLoopDataToProductState<ILoop54Product[]>(
        response,
        params
      );

      return receiveProducts({ data, meta });
    }
  );
};

export const requestMoreProducts = (
  id: string,
  params: Map<string, any>,
  location: Location,
  store: number
) => dispatch => {
  const query = qs.parse(location.search);
  const queryP = Object.assign({}, query, removeFalsyValues(params));
  const page = queryP?.page ? Number(queryP?.page) + 1 : 1;
  dispatch(updateHistory({ ...queryP, page }, location, ignoredKeys));

  return dispatch(
    getProducts(
      () =>
        endpoints.fetchLoopProductsByCategory(id, {
          ...queryP,
          store,
          page: page - 1
        }),
      response => {
        const { data, meta } = mapLoopDataToProductState<ILoop54Product[]>(
          response,
          params
        );
        return paginateProducts({ data, meta });
      },
      isPaginatingProducts
    )
  );
};

export const filterProducts = (
  id: string,
  filters: Map<string, any>,
  location: Location,
  params: IValidParams
): AppThunk => dispatch => {
  const query = {
    ...mergeQuery(filters, location),
    page: 1
  };

  dispatch(updateHistory(query, location, ignoredKeys));
  const validParams = getValidParams(params);
  return dispatch(
    getProductsByCategory(id, { ...query, page: 0 }, validParams)
  );
};
// @TODO
export const sortProducts = (
  id: string,
  params: IValidParams,
  location: Location,
  store: number,
  pageDefaultParams: IValidParams = {}
): AppThunk => dispatch => {
  const query = qs.parse(location.search);
  const validParams = getValidParams(pageDefaultParams);
  const queryP = Object.assign({}, query, params);
  dispatch(updateHistory({ ...queryP, page: 1 }, location, ignoredKeys));

  return dispatch(
    getProducts(
      () =>
        endpoints.fetchLoopProductsByCategory(id, {
          ...queryP,
          ...validParams,
          store,
          page: 0
        }),
      response => {
        const { data, meta } = mapLoopDataToProductState<ILoop54Product[]>(
          response,
          params
        );
        return receiveProducts({ data, meta });
      }
    )
  );
};
