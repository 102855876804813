import React from 'react';
import ContentItem from './ContentItem';
import Pagination from '../../../components/Pagination';
import './ContentView.scss';
const ContentView = ({ ...props }) => {
  const { pagination, items, requestMoreContent } = props;

  return (
    <>
      <div className="c-searchresultcontent">
        {props.items?.map((item, i) => (
          <div key={item.name + i}>
            <ContentItem item={item} />
          </div>
        ))}
      </div>
      {items.length > 0 && pagination && (
        <Pagination
          fetchPage={requestMoreContent}
          pageCount={pagination?.pageCount}
          totalCount={pagination?.totalCount}
          itemsPerPage={pagination.pageSize}
        />
      )}
    </>
  );
};

export default ContentView;
