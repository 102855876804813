import { loopRequest } from '../../lib/request';

export const loop54ClickNotifier = itemNo => {
  loopRequest(`/Loop54/notify/click?EntitytId=${itemNo}`, {
    method: 'POST'
  });
};
export const loop54AddToCartNotifier = itemNo => {
  loopRequest(`/Loop54/notify/adding-to-cart?EntitytId=${itemNo}`, {
    method: 'POST'
  });
};
