import React from 'react';
import ProductList from '../../../components/ProductList';
import { ga4ImpressionList } from '../../../lib/analytics/analytics';

const IMPRESSION_LIST_NAME = 'Searchresult products';

class ProductView extends React.PureComponent {
  componentDidMount() {
    const { items } = this.props;
    // runs when products gets updated
    if (!items || !items.length > 0) {
      return;
    }

    try {
      ga4ImpressionList(items, IMPRESSION_LIST_NAME);
    } catch (error) {
      console.error(error);
    }
  }

  componentDidUpdate(prevProps) {
    const { items } = this.props;
    if (items && prevProps.items.length !== this.props.items.length) {
      try {
        ga4ImpressionList(items, IMPRESSION_LIST_NAME);
      } catch (error) {
        console.error(error);
      }
    }
  }

  render() {
    const {
      items,
      pagination,
      requestMoreProducts,
      fetching,
      setProductFavorite,
      removeFavorite,
      isAuthenticated,
      redirectToLogin
    } = this.props;

    const setProductFavoriteFn = isAuthenticated
      ? setProductFavorite
      : redirectToLogin;
    const removeFavoriteFn = isAuthenticated ? removeFavorite : redirectToLogin;

    return (
      <ProductList
        fetching={fetching}
        setProductFavorite={setProductFavoriteFn}
        removeFavorite={removeFavoriteFn}
        requestMoreProducts={requestMoreProducts}
        pagination={pagination}
        items={items}
        impressionListName={IMPRESSION_LIST_NAME}
        itemsPerPage={20}
        isSearch
      />
    );
  }
}
export default ProductView;
