import {
  resolveRoutes,
  pathLookup,
  fromTree,
  resolveWilcardRoutes
} from '../../lib/route';
import staticRoutes from './staticRoutes';
import makeReducer from '../makeReducer';
import {
  FETCH_NAVIGATION_REQUESTED,
  FETCH_NAVIGATION_RECEIVED,
  FETCH_NAVIGATION_FAILED,
  TOGGLE_NOTIFICATION,
  SET_NOTIFICATION_HEIGHT,
  INavigationReceivedAction,
  SET_SIDEBAR
} from '../../types/reducers/navigation';

const initialState = {
  fetching: false,
  hasFetched: false,
  tree: {},
  header: [],
  footer: [],
  routes: [],
  rootRoutes: [],
  allRoutes: [],
  pathLookup: {},
  error: null,
  notificationStatus: false,
  notificationHeight: 0,
  hasSidebar: false
};

// TODO: No more use of rootNodes. Should be grouped in response & sidebar nav should work everywhere.
// TODO alot of redudant data ...

const groupRoutes = routes => {
  return routes.reduce(
    (acc, { subRoutes, ...rest }) => {
      acc.rootRoutes = acc.rootRoutes.concat(rest);
      acc.routes = acc.routes.concat(subRoutes);

      return acc;
    },
    { rootRoutes: [], routes: [] }
  );
};

const ACTION_HANDLERS = {
  [FETCH_NAVIGATION_REQUESTED]: (state, action) => ({
    ...state,
    fetching: action.fetching
  }),
  [SET_SIDEBAR]: (state, action) => {
    return {
      ...state,
      hasSidebar: action.payload
    };
  },

  [FETCH_NAVIGATION_RECEIVED]: (state, action) => {
    const { payload }: INavigationReceivedAction = action;

    // Removes all ContentStructureContainer items from the tree
    // TODO: Need to discuss if this should be removed completely
    const recursiveContentStructureContainerRemover = item => {
      if (item.type === 'ContentStructureContainer') {
        return true;
      }
      if (item && item.children && item.children.length > 0) {
        item.children = item.children.filter(
          recursiveContentStructureContainerRemover
        );
      }
      return true;
    };

    // Make a deep copy of tree
    const treeCopy =
      (payload.tree && JSON.parse(JSON.stringify(payload.tree))) || {};
    // Make a copy of the tree without ContentStructureContainers
    const unstructuredTree = {
      ...payload.tree,
      children:
        (treeCopy &&
          treeCopy.children.filter(
            recursiveContentStructureContainerRemover
          )) ||
        []
    };

    // Routes are grouped by tree level. THe first level is labeled as
    // a 'root route', the root routes together with the header definition is
    // used to calculate actual root routes. 'matvaror' 'recipes' etc. While
    // the rest of the routes are for the navigation section in the header.
    // Any routes that need to be resolved, only specify a lookup are appended to the correct level
    // in resolveRoutes.
    const { rootRoutes, routes } = groupRoutes(
      resolveRoutes(unstructuredTree, staticRoutes)
    );

    // The routes are flattended to a single list, this is to make it easy
    // to determine and filter matches when SSR, wildcard routes i.e product pages are added
    // to the flat route definition.
    const flatRoutes = fromTree(rootRoutes.concat(routes));
    const allRoutes = flatRoutes.concat(resolveWilcardRoutes(flatRoutes));

    // filter out those objects that has !visible recursively
    const filterVisible = data => {
      if (!data) {
        return [];
      }
      const r = (Array.isArray(data) ? data : data.children).filter(o => {
        if (o.children) {
          o.children = filterVisible(o);
        }
        if (o.type === 'ContentStructureContainer') {
          return true;
        }
        return o.visible;
      });
      return r;
    };

    const treeChildren =
      payload.tree &&
      payload.tree.children &&
      filterVisible(payload.tree).concat(staticRoutes);

    const tree = Object.assign(payload.tree, { children: treeChildren });

    return {
      ...state,
      tree: tree,
      top: payload.top,
      header: filterVisible(payload.header),
      footer: payload.footer,
      customerService: payload.customerService,
      pathLookup: pathLookup(unstructuredTree),
      fetching: false,
      hasFetched: true,
      rootRoutes,
      routes,
      allRoutes
    };
  },

  [FETCH_NAVIGATION_FAILED]: (state, action) => ({
    ...state,
    error: action.error,
    fetching: false,
    hasFetched: true
  }),

  [TOGGLE_NOTIFICATION]: (state, action) => ({
    ...state,
    notificationStatus: action.active
  }),

  [SET_NOTIFICATION_HEIGHT]: (state, action) => ({
    ...state,
    notificationHeight: action.height
  })
};

export default makeReducer(ACTION_HANDLERS, initialState);
