/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import {
  Button,
  Modal,
  SideModal as SideModalComponent
} from '@citygross/components';
import React, { useEffect, useRef, useState } from 'react';
import SideModalHeader from './SideModalHeader';
import { theme } from '@citygross/design-tokens';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeSideModal,
  selectShowModal,
  selectModalTab,
  selectTabHistory,
  selectModalForceOpen,
  sideModalForceOpen,
  openSideModal
} from '../../slices/sideModalSlice';
import { SideModalLogin } from '../SideModalTabs/SideModalLogin';
import SideModalMenu from '../SideModalTabs/SideModalMenu/SideModalMenu';
import { SideModalDeliveryChoicePicker } from '../SideModalTabs/SideModalDeliveryChoicePicker';
import ShoppingOrder from '../../containers/ShoppingOrderSideModal';
import * as styles from './SideModal.styles';
import { TSideModalTabs } from '../../types/sideModalTypes';
import { AnimatePresence } from 'framer-motion/dist/framer-motion';
import { BasicAnimatedStep } from '../SideModalTabs/AnimatedStep';
import { SideModalRegister } from '../SideModalTabs/SideModalRegister';
import {
  selectRegisterCurrentStep,
  setCurrentRegisterStep
} from '../../slices/loginSlice';
import { useDetectDevice, useWindowSize } from '@citygross/utils';
import { getAssortmentCookie } from '../../lib/assortments';

export const SideModalTabs: TSideModalTabs = {
  LOGIN: 'login',
  REGISTER: 'register',
  MENU: 'menu',
  DELIVERY_CHOICE_PICKER: 'deliveryChoicePicker',
  CART: 'cart'
};

export const SideModal = ({ confirmModalRef }: any) => {
  const dispatch = useDispatch();
  const [initialScroll, setInitialScroll] = useState(true);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const { width } = useWindowSize();
  const { isMobile } = useDetectDevice();
  const [visualHeight, setVisualHeight] = useState<string>('100%');
  const tabPlaceholder = useRef<HTMLButtonElement | null>(null);

  const closeModal = () => {
    dispatch(closeSideModal());
    setInitialScroll(true);
  };
  const checkVisualViewPort = () => {
    if (width > 991 || !isMobile) {
      setVisualHeight('100dvh');
    } else {
      setVisualHeight(window.visualViewport?.height + 'px');
    }
  };

  const assortmentCookie = getAssortmentCookie();

  const showModal = useSelector(selectShowModal);
  const modalTab = useSelector(selectModalTab);
  const forceOpen = useSelector(selectModalForceOpen);
  const currentRegisterStep = useSelector(selectRegisterCurrentStep);
  const tabHistory = useSelector(selectTabHistory);
  const [prevPosition, setPrevPosition] = useState(0);

  const isInitial = tabHistory.length < 2;

  const closeModalClick = () => {
    forceOpen
      ? () => {}
      : modalTab === SideModalTabs.REGISTER && currentRegisterStep > 0
      ? setShowRegisterModal(true)
      : closeModal();
  };

  useEffect(() => {
    if (assortmentCookie?.deliveryType === 'homeDelivery') {
      dispatch(openSideModal(SideModalTabs.DELIVERY_CHOICE_PICKER));
      dispatch(sideModalForceOpen(true));
    }
  }, [assortmentCookie?.deliveryType]);

  useEffect(() => {
    const pos = window?.scrollY;
    // imbox
    const imboxContainer = document.getElementById('imbox-container');

    if (showModal && document?.body) {
      tabPlaceholder.current?.focus(); // @TODO temporary solution to focus flyout on open
      // @TODO need to save where the flyout was opened so when it closes focus returns to that element
      setPrevPosition(pos);
      document.body.style.overflowY = 'scroll';
      document.body.style.position = 'fixed';
      document.body.style.top = -pos + 'px';
      if (imboxContainer) {
        imboxContainer.style.display = 'none';
      }
    } else {
      document.body.style.removeProperty('top');
      document.body.style.overflowY = 'auto';
      document.body.style.position = 'relative';
      if (prevPosition) {
        window.scrollTo(0, prevPosition);
      }
      if (imboxContainer) {
        imboxContainer.style.display = 'block';
      }
    }
    checkVisualViewPort();

    visualViewport?.addEventListener('resize', checkVisualViewPort);

    return () =>
      visualViewport?.removeEventListener('resize', checkVisualViewPort);
  }, [showModal]);

  return (
    <>
      <SideModalComponent
        isVisible={showModal}
        width={375}
        onBackdropClick={() => closeModalClick()}
        maxWidth={
          modalTab === SideModalTabs.CART && width && width < 480
            ? '100%'
            : '80%'
        }
        background={theme.palette?.white}
      >
        {/* @TODO temp quickfix to focus modal, */}
        <styles.TabButtonPlaceholder tabIndex={0} ref={tabPlaceholder} />
        <AnimatePresence initial={!isInitial}>
          {(() => {
            switch (modalTab) {
              case SideModalTabs.LOGIN:
                return (
                  <styles.SideModalLoginSlideContainer>
                    <SideModalLogin closeModal={closeModal} />
                  </styles.SideModalLoginSlideContainer>
                );
              case SideModalTabs.REGISTER:
                return (
                  <styles.SideModalLoginSlideContainer
                    clientHeight={visualHeight}
                  >
                    <SideModalRegister closeModal={closeModalClick} />
                  </styles.SideModalLoginSlideContainer>
                );
              case SideModalTabs.MENU:
                return (
                  <styles.SideModalMenuSlideContainer
                    clientHeight={visualHeight}
                  >
                    <SideModalMenu
                      closeModal={closeModal}
                      initialScroll={initialScroll}
                      setInitialScroll={setInitialScroll}
                    />
                  </styles.SideModalMenuSlideContainer>
                );
              case SideModalTabs.DELIVERY_CHOICE_PICKER:
                return (
                  <BasicAnimatedStep
                    slideInDirection={-1}
                    key={SideModalTabs.DELIVERY_CHOICE_PICKER}
                  >
                    <styles.SideModalDeliverySlideContainer
                      clientHeight={visualHeight}
                    >
                      <SideModalDeliveryChoicePicker
                        clientHeight={visualHeight}
                        closeModal={closeModalClick}
                      />
                    </styles.SideModalDeliverySlideContainer>
                  </BasicAnimatedStep>
                );
              case SideModalTabs.CART:
                return (
                  <BasicAnimatedStep
                    slideInDirection={1}
                    key={SideModalTabs.CART}
                  >
                    <styles.SideModalCartSlideContainer>
                      <SideModalHeader
                        title={'Varukorg'}
                        closeSideModal={closeModal}
                        marginBottom={0}
                        isCart
                      />
                      <styles.CartContainer visualHeight={visualHeight}>
                        <ShoppingOrder confirmModalRef={confirmModalRef} />
                      </styles.CartContainer>
                    </styles.SideModalCartSlideContainer>
                  </BasicAnimatedStep>
                );
              default:
                return null;
            }
          })()}
        </AnimatePresence>
      </SideModalComponent>
      {showRegisterModal && (
        <Modal
          isConfirm
          onBackdropClick={() => {
            setShowRegisterModal(false);
          }}
          title={'Avsluta registrering'}
          subTitle={'Om du stänger nu måste du börja om från början.'}
          footer={
            <>
              <Button
                onClick={() => {
                  setShowRegisterModal(false);
                }}
                fullWidth
                color="white"
              >
                Avbryt
              </Button>
              <Button
                onClick={() => {
                  dispatch(
                    setCurrentRegisterStep({
                      currentStep: 0,
                      previousStep: 0
                    })
                  );
                  setShowRegisterModal(false);
                  closeModal();
                }}
                fullWidth
                color="secondary"
              >
                Ja, avslut
              </Button>
            </>
          }
        ></Modal>
      )}
    </>
  );
};
