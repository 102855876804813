export const generatePagination = pagination => {
  let paginationFilter;
  let selectedOption;
  let amountOfPagesToShow;
  let count;

  if (pagination && pagination.filters) {
    paginationFilter = pagination.filters.find(p => p.name === 'Type');
    selectedOption = paginationFilter?.options.find(o => o.selected);
    count = selectedOption.count;
    amountOfPagesToShow = count / pagination.pageSize;
  }

  return { count, amountOfPagesToShow };
};
