import { ILoop54Recipes } from '../../types/Loop54Recipes';
import * as ga4 from '@citygross/analytics';

export const handleGA4Event = (
  data: ILoop54Recipes[],
  storeId: number,
  impressionName: string
) => {
  try {
    const ga4ImpressionItems = data?.map((recipe, index) => {
      let recipePrice = 0;
      if (storeId) {
        recipePrice = recipe?.recipeStoreDetails?.preview.minimumPrice || 0;
      }
      return {
        item_id: recipe.id,
        item_name: recipe.name,
        item_brand: recipe.source || 'City Gross',
        item_category:
          '/recept' +
            recipe.url?.substring(0, recipe.url.lastIndexOf('/') + 1) || '',
        item_variant: recipe.id,

        item_list_name: impressionName,
        item_list_id: impressionName,
        index: index + 1,

        price: recipePrice,
        quantity: 1,
        discount: 0
      };
    });

    ga4.viewItemList({
      items: ga4ImpressionItems,
      item_list_id: impressionName,
      item_list_name: impressionName
    });
  } catch (error) {
    console.error(error);
  }
};

export const applyRecipeTagsToObjectValues = tags => {
  const modifiedObj = Object.keys(tags).map(key => {
    const newKey = `${key}`;
    return { [newKey]: tags[key] };
  });
  return Object.assign({}, ...modifiedObj);
};
