import React, { Fragment } from 'react';
import axios from 'axios';
import { storage } from '../../actions/auth';
import { makeToast } from '../toast';
import { domSafe } from '../document';
import config from '../../config/config';
import Cookie from 'js-cookie';
const { AUTH_STORAGE_KEY } = config;
// if using cookies add
// credentials: true
export const defaultOpts = {
  method: 'GET',
  credentials: 'true',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
};

const makeErrorToast = ({ res, messages = [], malformedError }) => {
  if (domSafe()) {
    const {
      request: { responseURL: url },
      status,
      statusText
    } = res;

    const pathRegex = /(?:https?:\/\/)?(?:[^?/\s]+[?/])(.*)/;
    const pathMatches = url.match(pathRegex);
    const path = pathMatches.length >= 2 ? `/${pathMatches[1]}` : url;

    let title = 'Hoppsan, något gick fel!';
    if (url.includes('/carts/')) {
      title = 'Error - Carts service';
    } else if (url.includes('/search/')) {
      title = 'Error - Search service';
    } else if (url.includes('/customer/')) {
      title = 'Error - Customer service';
    } else if (url.includes('/deliveries/')) {
      title = 'Error - Delivery service';
    } else if (url.includes('/mandrill/')) {
      title = 'Error - Mandrill service';
    } else if (url.includes('/orders/')) {
      title = 'Error - Carts service';
    } else if (url.includes('/payments/')) {
      title = 'Error - Payments service';
    } else if (url.includes('/PageData/')) {
      title = 'Error - Epi service';
    } else if (url.includes('/settings/')) {
      title = 'Error - Epi service';
    } else if (url.includes('/articlepage/')) {
      title = 'Error - Epi service';
    } else if (url.includes('/connect/')) {
      title = 'Error - Auth service';
    }

    makeToast({
      title: title,
      richText: (
        <Fragment>
          {path}
          <br />
          <hr />
          {status && (
            <Fragment>
              <strong>Status:</strong> {status} - {statusText}
              <br />
            </Fragment>
          )}
          {messages.map(({ label, text }, key) => (
            <Fragment key={key}>
              {label && <strong>{label}: </strong>}
              {text}
              <br />
            </Fragment>
          ))}
          {malformedError && (
            <Fragment>
              <hr />
              <strong>{malformedError}</strong>
            </Fragment>
          )}
        </Fragment>
      )
    });
  }
};

const checkFetchError = (err, url) => {
  makeErrorToast({
    res: { request: { responseURL: url } },
    messages: [{ text: err.toString() }]
  });

  return Promise.reject(new Error(err));
};

const isExceptionRequest = (response, data) => {
  if (data && data.error === 'invalid_grant') {
    return true;
  } else if (response.request.responseURL.includes('adminlogin')) {
    return true;
  }
  return false;
};

const checkOkAndToast = deserialized => {
  const { response, data } = deserialized;

  if (isExceptionRequest(response, data)) {
    return deserialized;
  } else if (data && data.errors && data.errors.length > 0) {
    data.errors.forEach(err => {
      makeErrorToast({
        res: response,
        messages: [
          { label: 'Error code', text: err.code },
          { label: 'Error message', text: err.message }
        ]
      });
    });
  } else if (data && data.error) {
    makeErrorToast({
      res: response,
      messages: [{ label: 'Error', text: data.error }],
      malformedError: 'Warning: Error description is malformed.'
    });
  } else if (data && data.error_description) {
    makeErrorToast({
      res: response,
      messages: [{ label: 'Error description', text: data.error_description }],
      malformedError: 'Warning: Error description is malformed.'
    });
  } else if (data && typeof data === 'string') {
    makeErrorToast({
      res: response,
      messages: [{ label: 'Response', text: data }],
      malformedError: 'Warning: Error description is malformed.'
    });
  } else if (response.status < 200 || response.status >= 300) {
    makeErrorToast({
      res: response,
      malformedError: 'Warning: No error description.'
    });
    return Promise.reject(response);
  }
  return deserialized;
};

export const deserialize = res => {
  const contentType = res.headers['content-type'];
  if (contentType && contentType.includes('application/json')) {
    return { response: res, data: res.data };
  } else {
    // No json in response, we just pass it on instead
    return { response: res };
  }
};

export const checkAuthorized = res => {
  if (res.status === 401) {
    makeErrorToast({
      res: res,
      messages: [
        {
          label: '401 Unauthorized',
          text: 'Not logged in or bad user.'
        }
      ]
    });

    if (domSafe()) {
      Cookie.remove(AUTH_STORAGE_KEY);
      Cookie.remove(AUTH_STORAGE_KEY + '_user');
      window.location.reload(false);
    }
    return Promise.reject(
      new Error(res.request.responseURL + ' 401 Unauthorized')
    );
  }

  return res;
};

export const checkOk = deserialized => {
  const { response, data } = deserialized;
  // If response contains an error message, reject
  if (
    response &&
    response.status &&
    response.status < 200 &&
    response.status >= 300
  ) {
    console.error(deserialized);
    return Promise.reject(deserialized);
  }

  // If response is not status 200-299, reject
  if (response.status >= 200 && response.status < 300) {
    return {
      data: data,
      response: {
        // Add as needed, must be serializeable
        status: response.status,
        contentType: response.headers['content-type']
      }
    };
  } else {
    console.error(deserialized);
    return Promise.reject(deserialized);
  }
};

export const ok = ({ response, data }) => {
  return response.ok ? data : Promise.reject(data);
};

export const requestWithErrorToast = (url, opts = {}, handle = checkOk) => {
  if (!opts.method) {
    opts.method = 'GET';
  }

  if (opts.body) {
    opts.data = opts.body;
    opts.body = null;
  }

  opts = { ...defaultOpts, ...opts, url };

  return axios({
    ...opts
  })
    .catch(err => checkFetchError(err, url))
    .then(checkAuthorized)
    .then(deserialize)
    .then(checkOkAndToast)
    .then(handle);
};

export const request = (url, opts = {}) => {
  if (!opts.method) {
    opts.method = 'GET';
  }

  if (opts.body) {
    opts.data = opts.body;
    opts.body = null;
  }

  opts = { ...defaultOpts, ...opts, url };
  return axios(url, {
    ...defaultOpts,
    ...opts
  })
    .catch(err =>
      Promise.reject({
        ...err
      })
    )
    .then(checkAuthorized)
    .then(deserialize)
    .then(checkOk);
};
