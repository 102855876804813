import { BodyText } from '@citygross/typography';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { loop54ClickNotifier } from '../../../api/endpoints/loopNotify';
import { useDispatch } from 'react-redux';
import { setMode } from '../../../actions/shoppingOrder';
import { closeSideModal } from '../../../slices/sideModalSlice';
import { UNTOGGLE_BEHAVIOUR } from '../../../types/reducers/shoppingOrder';

type TCartCardTitle = {
  url: string;
  name: string;
  onClick: () => void;
  itemId: string;
};

export const CartCardTitle = ({
  url,
  name,
  onClick,
  itemId
}: TCartCardTitle) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const linkClick = () => {
    onClick && onClick();
    loop54ClickNotifier(itemId);
    dispatch(closeSideModal());
    dispatch(setMode(false, false, UNTOGGLE_BEHAVIOUR.none));
  };

  return (
    <Link
      to={{
        pathname: url,
        state: {
          flyout: false,
          title: ' ',
          nopadding: true,
          tight: false
        }
      }}
      onClick={linkClick}
      draggable={false}
      className="c-card-title__link "
    >
      <BodyText color={theme.palette?.darkest} fontWeight="medium">
        {name}
      </BodyText>
    </Link>
  );
};
