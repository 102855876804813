import React, { Component } from 'react';
import FilterButton from '../../Buttons/FilterButton';
import { TriggerLink } from '../../Link';
import './CategoryFiltersGroup.scss';

const DEFAULT_ITEMS = 15;
const translateTag = tag => {
  switch (tag?.toLowerCase()) {
    case 'vegan':
      return 'veganskt';
    case 'vegetarian':
      return 'vegetariskt';
    case 'lactosefree':
      return 'laktosfritt';
    case 'glutenfree':
      return 'glutenfritt';
    case 'organic':
      return 'ekologiskt';
    default:
      return 'default';
  }
};

const FilterItem = ({ option, recipe, selected, count, niceName, onClick }) => (
  <li className="c-category-filters-group__item">
    <FilterButton
      buttonType={'filter'}
      active={selected}
      onClick={onClick}
      className={translateTag(option)}
    >
      {niceName || option}
      {!recipe ? <span> &bull; {count}</span> : null}
    </FilterButton>
  </li>
);

class CategoryFiltersGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAll: false
    };
  }

  toggleShowAll = () => {
    this.setState(prevState => ({
      showAll: !prevState.showAll
    }));
  };

  render() {
    const { name, recipe, heading, options = [], filterAction } = this.props;
    const { showAll } = this.state;

    const visibleOptions = showAll
      ? [...options]
      : [...options].slice(0, DEFAULT_ITEMS);

    const sorter = (a, b) => {
      return a.option
        .replace(/\s/g, '')
        .localeCompare(b.option.replace(/\s/g, ''), 'en', { numeric: true });
    };

    return (
      <div key={name} className="c-category-filters-group">
        <div className="c-category-filters-group__heading">
          {heading || name}
        </div>
        <ul className="c-category-filters-group__list">
          {visibleOptions?.sort(sorter)?.map(item => {
            const query = { [`${name}`]: item.option };
            return (
              <FilterItem
                key={item.id}
                filterType={name}
                recipe={recipe}
                onClick={() => {
                  return filterAction(query);
                }}
                {...item}
              />
            );
          })}
        </ul>
        {!showAll && options.length > DEFAULT_ITEMS && (
          <TriggerLink onClick={this.toggleShowAll}>Visa fler</TriggerLink>
        )}
      </div>
    );
  }
}

export default CategoryFiltersGroup;
