import React, { useCallback, useEffect, useMemo, useState } from 'react';
import withBaseBlock from '../../components/BaseBlock';
import { connect } from 'react-redux';
import { selectCurrentAssortment } from '../../selectors/assortments';
import {
  fetchLoopCateringCategory,
  fetchLoopCateringProductByIds
} from '../../api/endpoints/cateredmeals';
import { sendCateringItemAddTracking } from '../../lib/analytics/analytics';
import { setVariant } from '../../lib/catering';
import { redirectToLogin } from '../../actions/app';

import CateringCard from '../../components/CateringCard';
import { TCateringProductCard } from '../../types/xim/catering';
import { mapCateredMealToProductCard } from '../../lib/catering.js';
import ProductCarousel from '../../components/ProductCarousel';
import ProductArticleSkeleton from '../../components/ProductArticleSkeleton';
import CardGrid from '../../components/CardGrid';
import { authenticatedSelector } from '../../selectors/user';
import { getCategoryPageByUrl } from '../../selectors/routing/routingSelector.js';
import { ILoop54ProductsData } from '../../types/loop54Product';
import { TCateringArticleBlock } from './CateringArticleBlock.types';

const CateringArticleBlock = ({
  storeId,
  ximEntitiesList,
  name,
  isAuthenticated,
  redirectToLogin,
  maxItems,
  title,
  blockOptions,
  previewCategoryPage,
  previewFromCategoryPage,
  link
}: TCateringArticleBlock) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchingFailed, setFetchingFailed] = useState<boolean>(false);
  const [fetchedProducts, setFetchedProducts] = useState<
    TCateringProductCard[]
  >([]);

  const handleFetchedCateredMeals = (data: ILoop54ProductsData, storeId) => {
    if (data?.items.length === 0) {
      setFetchingFailed(true);
      return;
    }
    setLoading(false);
    setFetchingFailed(false);
    setFetchedProducts(mapCateredMealToProductCard(data?.items, storeId));
  };

  const fetchProducts = useCallback(() => {
    if (
      previewFromCategoryPage &&
      link?.categoryPageId &&
      previewCategoryPage?.name
    ) {
      fetchLoopCateringCategory(link?.categoryPageId, {
        categoryName: previewCategoryPage?.name
      })
        .then(data => {
          handleFetchedCateredMeals(data.data, storeId);
        })
        .catch(() => setFetchingFailed(true));
    } else if (ximEntitiesList && ximEntitiesList.length > 0) {
      setLoading(true);

      fetchLoopCateringProductByIds(
        ximEntitiesList?.map(c => c.replace('c', ''))?.join(',')
      )
        .then(data => {
          handleFetchedCateredMeals(data.data, storeId);
        })
        .catch(() => setFetchingFailed(true));
    }
  }, [ximEntitiesList, storeId]);

  useEffect(() => {
    fetchProducts();
  }, [storeId]);

  const CateringCards = useMemo(
    () =>
      fetchedProducts
        ?.map((product, index) => {
          const hasSides = product.sides && product.sides.length > 0;
          const hasVariants =
            product.variants && Object.keys(product.variants).length > 0;

          const isBuffe = !!product.minOrder && !!product.maxOrder;
          const variants = Object.keys(product.variants);
          const isStandard = variants.findIndex(
            variant => variant.toLowerCase() === 'standard'
          );
          const defaultVariant = variants[isStandard] || variants[0];
          const defaultHasPrice = product?.variants?.[defaultVariant]?.some(
            v => v.storeDetails?.v_has_price
          );

          return hasVariants && product.sellable && defaultHasPrice ? (
            <CateringCard
              id={product.id}
              key={product.id}
              title={product.name}
              label={product.label}
              singularLabel={product.singularLabel}
              description={product.description || ''}
              unit={product.unit}
              variants={hasVariants && product.variants}
              hasVariants={hasVariants}
              hasSides={hasSides}
              sides={(hasSides && product.sides) || []}
              url={product.url}
              productImageUrl={product.image.url}
              isBuffe={isBuffe}
              minOrder={product.minOrder}
              maxOrder={product.maxOrder}
              sendCateringItemAddTracking={orderlines =>
                sendCateringItemAddTracking(product, orderlines, index + 1)
              }
              defaultVariant={variants[isStandard] || variants[0]}
              setVariant={(variantType, selectedVariant) =>
                setVariant(product.id, variantType, selectedVariant)
              }
              isAuthenticated={isAuthenticated}
              redirectToLogin={redirectToLogin}
              impressionListName={name}
              impressionListPosition={index + 1}
              onCarousel
            />
          ) : null;
        })
        ?.filter(c => c),
    [fetchedProducts]
  );

  if (fetchingFailed) {
    return null;
  }

  if (fetchedProducts.length === 0 || loading) {
    return (
      <CardGrid
        noPad
        isCarousel={true}
        title={title}
        backgroundColor={blockOptions?.backgroundColor}
        renderItem={() => (
          <ProductArticleSkeleton
            loading={loading}
            productsLength={fetchedProducts.length}
            maxItems={maxItems}
          />
        )}
      />
    );
  }
  const products = fetchedProducts?.filter(c => {
    const hasSides = c?.sides && c.sides?.length > 0;
    return hasSides && c.sellable;
  });
  return (
    <CardGrid
      noPad
      isCarousel={true}
      title={title}
      backgroundColor={blockOptions?.backgroundColor}
      renderItem={() => (
        <ProductCarousel productsLength={products?.length}>
          {CateringCards}
        </ProductCarousel>
      )}
    />
  );
};

const mapDispatchToProps = {
  redirectToLogin
};

const mapStateToProps = (state, props) => {
  return {
    isAuthenticated: authenticatedSelector(state),
    storeId: selectCurrentAssortment(state),
    previewCategoryPage:
      props?.previewFromCategoryPage && props?.link?.url
        ? getCategoryPageByUrl(state, props?.link?.url)
        : null
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withBaseBlock(CateringArticleBlock));
