import { cachedRequest, loopRequest } from '../../lib/request';
import queryParams from '../../lib/queryParams';
import { ILoop54ItemResponse } from '../../types/Loop54Recipes';

export const DEFAULT_RECIPEPAGE_SIZE = 20;

export const fetchLoopRecipesByIds = (
  recipeIds: string | string[]
): Promise<ILoop54ItemResponse> | null => {
  if (!recipeIds || recipeIds?.length === 0) {
    return null;
  }
  return loopRequest(`/Loop54/recipes/${recipeIds}`);
};

export const fetchLoopRecipesByCategory = (
  categoryId,
  params: any = {}
): Promise<ILoop54ItemResponse> => {
  const skip = (params.page || 0) * DEFAULT_RECIPEPAGE_SIZE;
  const { SortField, SortOrder, ...rest } = params;
  let sort = {};
  if (SortField) {
    sort = {
      SortField: SortField,
      SortOrder: SortOrder
    };
  }
  const opts = queryParams({
    take: DEFAULT_RECIPEPAGE_SIZE,
    skip,
    ...rest,
    ...sort
  });

  return loopRequest(`/Loop54/category/${categoryId}/recipes?${opts}`);
};

// article blocks
export const fetchRecipesByTags = (raw, page = 0) => {
  return loopRequest(
    `/Loop54/recipes?${raw}&skip=${page *
      DEFAULT_RECIPEPAGE_SIZE}&take=${DEFAULT_RECIPEPAGE_SIZE}`
  );
};

export const fetchRecipeVariantPrice = (
  recipeId,
  numberOfMeals = 4,
  organic = false,
  lactoseFree = false,
  glutenFree = false,
  vegetarian = false,
  vegan = false,
  storeId
) => {
  return cachedRequest(
    `/recipes/${recipeId}/articles?storeNumber=${storeId ?? ''}&lactoseFree=${
      lactoseFree ? lactoseFree : null
    }&glutenFree=${glutenFree ? glutenFree : null}&vegetarian=${
      vegetarian ? vegetarian : null
    }&vegan=${vegan ? vegan : null}&quantity=${numberOfMeals}`,
    {
      method: 'GET'
    }
  );
};

export const fetchBatchRecipeVariantPrice = items => {
  return cachedRequest('/recipes/batchprices', {
    method: 'POST',
    body: JSON.stringify([...items])
  });
};
