import { FormControl, SaveButton } from '@citygross/components';
import React, { useEffect, useState } from 'react';
import * as styles from './SideModalLogin.styles';
import { IAnimatedStepsChildProps } from '../AnimatedStep';
import { loginSteps } from './SideModalLogin';
import { loginUser, allUserInfo } from '../../../actions/auth';
import { getUserOrders } from '../../../actions/order';
import { useDispatch } from 'react-redux';
import { closeSideModal } from '../../../slices/sideModalSlice';
import LoginInput from '../../LoginInput/LoginInput';
import { Icons } from '@citygross/icons';
import { useTheme } from 'styled-components';
import { fireCallbackOnEnter } from '../../../lib/utils';
import { LoginErrorBox, TLoginError } from '../../LoginErrorBox';

type TLoginPassword = {
  email: string;
  prefilledPassword: string;
} & IAnimatedStepsChildProps;

const LoginPassword = ({
  email,
  goToStep,
  stepKey,
  animating,
  prefilledPassword
}: TLoginPassword) => {
  const [error, setError] = useState<TLoginError | null>(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [password, setPassword] = useState(prefilledPassword ?? '');
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();

  const stepFn = (step: number) => {
    goToStep && (stepKey || stepKey === 0) && goToStep(step, stepKey);
  };

  const doRequest = (e, { username, password }) => {
    e.preventDefault();

    setLoading(true);
    return (
      dispatch(loginUser({ username, password }))
        // @ts-ignore
        .then(_ => {
          // @ts-ignore
          dispatch(allUserInfo());
        })
        .then(() => {
          try {
            dispatch(getUserOrders());
          } catch (e) {
            console.error('Failed to fetch users orders/carts', e);
          }
        })
        .then(_ => {
          setSuccess(true);
        })
        .catch(({ response }) => {
          const data = response?.data;
          if (
            data &&
            data.error === 'invalid_grant' &&
            data.error_description === 'invalid_username_or_password'
          ) {
            setError('credentials');
            setLoading(false);
          } else {
            setError('unknown');
            setLoading(false);
          }
        })
    );
  };

  const onSuccessLogin = () => {
    dispatch(closeSideModal());
  };
  return (
    <styles.LoginWrapper>
      <styles.LoginInputContainer>
        {error && <LoginErrorBox error={error} />}
        <styles.LoginTitle>Lösenord</styles.LoginTitle>

        <styles.LoginInputGroup
          onSubmit={e => doRequest(e, { username: email, password: password })}
        >
          <styles.LoginInputLabel>
            <styles.LoginEmailPreview>{email}</styles.LoginEmailPreview>
            <styles.ChangeLink
              tabIndex={0}
              onClick={() => {
                setError(null);
                stepFn(loginSteps.LOGIN_EMAIL);
              }}
              onKeyDown={fireCallbackOnEnter(() => {
                setError(null);
                stepFn(loginSteps.LOGIN_EMAIL);
              })}
            >
              ändra
            </styles.ChangeLink>
          </styles.LoginInputLabel>
          <FormControl>
            <LoginInput
              placeholder="Lösenord"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={e => {
                setPassword(e.target.value);
                setError(null);
              }}
              name={'password'}
              loading={animating}
              icon={<Icons.EyeOn />}
              iconOnClick={() => setShowPassword(!showPassword)}
            />
          </FormControl>
        </styles.LoginInputGroup>

        <SaveButton
          onSavedColor={theme.palette?.alertGreen}
          fullWidth
          onClick={e => {
            if (error) {
              setError(null);
            }
            doRequest(e, { username: email, password: password });
          }}
          afterSaveText="Inloggning lyckad"
          beforeSaveText="Logga in"
          onAnimationComplete={onSuccessLogin}
          icon={<Icons.CheckMark width={16} height={16} color={'white'} />}
          saved={success}
          loading={loading && !success}
        >
          Logga in
        </SaveButton>
        <styles.LoginTextCenter>
          <styles.LoginLink
            tabIndex={0}
            onClick={() => {
              setError(null);
              stepFn(loginSteps.LOGIN_FORGOT);
            }}
            onKeyDown={fireCallbackOnEnter(() => {
              setError(null);
              stepFn(loginSteps.LOGIN_FORGOT);
            })}
          >
            Jag har glömt mitt lösenord
          </styles.LoginLink>
        </styles.LoginTextCenter>
      </styles.LoginInputContainer>
    </styles.LoginWrapper>
  );
};

export default LoginPassword;
