import styled from 'styled-components';
import { H2 } from '@citygross/typography';

type TSideModalHeaderWrapper = {
  leftElementExists: boolean;
  titleExists: boolean;
  marginBottom?: number;
  isLogin?: boolean;
  isCart?: boolean;
};

type TSideModalHeaderTitle = {
  hasLeftElement?: boolean;
};

type TSideModalCartContainer = {
  clientHeight: string;
};

export const SideModalHeaderWrapper = styled.div<TSideModalHeaderWrapper>`
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom || marginBottom === 0 ? marginBottom : 22}px;
  display: grid;
  grid-template-columns: 1fr;
  height: 64px;
  min-height: 64px;
  position: fixed;
  width: ${props =>
    props.isLogin
      ? `calc(100% - 40px)`
      : props.isCart
      ? `calc(100% - 8px)`
      : `calc(100% - 32px)`};
  margin: 0 auto;
  background: white;
  z-index: 900;
  padding: 10px 0;
  top: 0;
  > * {
    grid-area: 1/1/1/1;
    justify-self: start;
  }
`;
export const TabButtonPlaceholder = styled.button`
  position: absolute;
  opacity: 0;
`;
export const CloseButtonWrapper = styled.span`
  grid-area: 1/1/1/1;
  justify-self: end;
  height: 48px;
  display: grid;
  place-content: center;
`;

export const SideModalHeaderTitle = styled(H2)<TSideModalHeaderTitle>`
  margin: 0;
  grid-area: 1/1/1/1;
  text-align: center;
  justify-self: center;
`;

export const LoginNewsBoxTitle = styled(H2)`
  margin-top: 0;
  margin-bottom: 8px;
`;

export const SideModalHeaderLogo = styled.div`
  justify-self: start;
`;

export const CartContainer = styled.div<TSideModalCartContainer>`
  position: relative;
  width: 100%;
  @supports (height: 100dvh) {
    height: calc(100dvh - 32px - 32px);
  }
  @supports not (height: 100dvh) {
    height: calc(${props => props.visualHeight} - 32px - 32px);
  }
  * {
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: #f7f7f7;
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
    }
  }
`;

export const SideModalSlideContainer = styled.div<TSideModalCartContainer>`
  padding: 64px 16px 0;
  overscroll-behavior: none;
  @media (max-width: 991px) {
    height: ${props =>
      props?.clientHeight ? `calc(${props.clientHeight} + 0px)` : 'auto'};
    overflow-y: scroll;
    padding-bottom: 36px;
  }
`;

export const SideModalDeliverySlideContainer = styled(SideModalSlideContainer)`
  padding-bottom: 0;
`;
export const SideModalLoginSlideContainer = styled(SideModalSlideContainer)`
  height: 100%;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    height: ${props =>
      `calc(${props?.clientHeight ? props.clientHeight : '100vh'} -  0px)`};
  }
`;
export const SideModalCartSlideContainer = styled.div`
  padding: 64px 0px 0;
`;
export const SideModalMenuSlideContainer = styled(SideModalSlideContainer)`
  height: 100%;
  overflow-y: auto;
  max-height: 100%;
  position: relative;

  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #e7e7e7;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  @media (max-width: 991px) {
    padding-bottom: 80px;
  }
`;

export const RegisterModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RegisterModalButtonContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const RegisterModalClose = styled.div`
  cursor: pointer;
`;
