import RecipeList from '../../components/RecipeList';
import { connect } from 'react-redux';
import { authenticatedSelector } from '../../selectors/user';
import { makeBlockRecipeEnhancerSelector } from '../../selectors/recipe/recipeSelectors';
import { makeRecipeEnhancerSelector } from '../../selectors/recipe/recipeSelectors';
import { push } from 'connected-react-router';
import { setRecipeFavorite, removeFavorite } from '../../actions/auth';

const mapStateToProps = (state, props) => {
  let recipes;

  if (props.withoutPaginationAndFilter) {
    recipes = makeBlockRecipeEnhancerSelector(state, props.recipes);
  } else {
    const recipeSelector = makeRecipeEnhancerSelector();
    recipes = recipeSelector(state);
  }

  return {
    isAuthenticated: authenticatedSelector(state),
    items: recipes
  };
};

const mapDispatchToProps = {
  push,
  setRecipeFavorite,
  removeFavorite
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipeList);
