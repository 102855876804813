import React from 'react';
import { Link } from 'react-router-dom';
import ClockIcon from '../assets/clock.svg';
import FallbackImage from '../assets/recept-fallback.svg';
import { Button, FavoriteButton, CartButton } from '../Buttons';
import { Marking } from '../Marking';
import { ImageFitTypes, LazyProgressivePicture } from '../ProgressivePicture';
import './RecipeCard.scss';
import ToolTip from '../ToolTip';
import { formatRecipeUnit } from '../../lib/utils';
import { IExtendedRecipe } from '../../types/Loop54Recipes';
import { loop54ClickNotifier } from '../../api/endpoints/loopNotify';
import { ga4ImpressionClickTracking_Recipe } from '../../lib/analytics/analytics';
interface IRecipeCard extends IExtendedRecipe {
  minimal: boolean;
  promotedSource: string;
  setRecipeFavorite: Function;
  removeFavorite: Function;
  isCarousel: boolean;
  impressionListName: string;
  impressionListPosition: number;
}
export const RecipeCard = ({
  id,
  minimal,
  url,
  name,
  quantity,
  sellable,
  promotedSource,
  cookingTime,
  image,
  preview,
  source,
  markings,
  favorite,
  isFavoriteLoading,
  setRecipeFavorite,
  removeFavorite,
  weightWatchersBluePoints,
  weightWatchersGreenPoints,
  weightWatchersPurplePoints,
  weightWatchersPoints,
  isCarousel,
  impressionListName,
  impressionListPosition
}: IRecipeCard) => {
  const onFavoriteClick = () => {
    if (favorite) {
      removeFavorite(favorite);
    } else {
      setRecipeFavorite(id, name);
    }
  };

  const dividedPrice = preview?.fullySatisfied
    ? (preview.minimumPrice / quantity.value).toFixed(2)
    : null; // @TODO replace 4 with new value from PIM
  // @ts-ignore cant typecast
  const recipeForTracking: IExtendedRecipe = {
    id,
    name,
    source,
    preview,
    url
  };
  return (
    <Link
      to={{
        pathname: url
      }}
      draggable={isCarousel ? false : true}
      className={`c-recipecard ${!minimal ? 'is-minimal' : ''}`}
      onClick={() => {
        loop54ClickNotifier(id);
        ga4ImpressionClickTracking_Recipe({
          impressionListName,
          impressionListPosition,
          recipe: recipeForTracking
        });
      }}
    >
      <div data-productid={id}>
        <div className="actions">
          {setRecipeFavorite && (
            <FavoriteButton
              active={favorite}
              loading={isFavoriteLoading}
              handleClick={onFavoriteClick}
            />
          )}
          {preview?.fullySatisfied ? (
            <ToolTip label={'Köpbart recept'} alignment={'top'}>
              <CartButton onClick={onFavoriteClick} />
            </ToolTip>
          ) : null}
        </div>
        <div className="markings">
          {markings &&
            markings
              .slice(0, 5)
              .map(
                marking =>
                  marking.image && (
                    <Marking
                      altText={marking.altText}
                      code={marking.code}
                      key={marking.id}
                      imageUrl={marking.image.url}
                      tooltipAlign="top"
                    />
                  )
              )}
        </div>

        <div className="represent">
          {/* @TODO where does this come from ? */}
          {promotedSource && <div className="tag">{promotedSource}</div>}
          <LazyProgressivePicture
            largeImage={{
              ...image,
              url: (process.env.PUBLIC_URL || '') + image.url,
              width: 328,
              height: 230
            }}
            inheritHeight
            fitType={ImageFitTypes.Cover}
            maxWidth={{
              large: 481,
              small: 364
            }}
            placeholder={{
              image: FallbackImage,
              bgColor: 'transparent'
            }}
          />
          {weightWatchersPoints ? (
            <div className="smartpoints">
              <div
                className={`wwpoints ${
                  weightWatchersPoints.length === 1 ? 'singlepoint' : ''
                }`}
              >
                {weightWatchersPoints}
              </div>
            </div>
          ) : (
            (weightWatchersPurplePoints ||
              weightWatchersGreenPoints ||
              weightWatchersBluePoints) && (
              <div className="smartpoints">
                {weightWatchersGreenPoints && (
                  <div className="smartpoint" style={{ background: '#08514a' }}>
                    {weightWatchersGreenPoints}
                  </div>
                )}
                {weightWatchersBluePoints && (
                  <div className="smartpoint" style={{ background: '#0073d6' }}>
                    {weightWatchersBluePoints}
                  </div>
                )}
                {weightWatchersPurplePoints && (
                  <div className="smartpoint" style={{ background: '#7a1d5b' }}>
                    {weightWatchersPurplePoints}
                  </div>
                )}
              </div>
            )
          )}
        </div>
        <div className="infobar">
          {source && <div className="source">{source}</div>}

          {cookingTime && (
            <div className="time">
              <img
                src={ClockIcon}
                width="15"
                height="15"
                alt="tillagningstid"
              />
              <span className="text">{`${cookingTime} min`}</span>
            </div>
          )}
        </div>

        <div className="name">{name}</div>
        {!minimal && (
          <div className="meta">
            {sellable && preview?.fullySatisfied && (
              <React.Fragment>
                <div className="description">Från ca:</div>
                <div className="price">
                  {`${dividedPrice} kr /${formatRecipeUnit(quantity.unit)}`}
                </div>
              </React.Fragment>
            )}
            <div className="cartactions">
              <div className="addtocart active">
                <Button>Se recept</Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Link>
  );
};

export default RecipeCard;
