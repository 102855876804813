import React from 'react';
import { Marking } from '../Marking';
import { IExtendedMarking } from '../../types/storefront/product';

type TMarkings = {
  markings: IExtendedMarking[];
};

export const Markings: React.FC<TMarkings> = ({ markings }) => (
  <>
    {markings
      ?.slice(0, 3)
      .map(
        marking =>
          marking.image && (
            <Marking
              altText={marking.altText}
              code={marking.code}
              key={marking.id}
              imageUrl={marking.image.url}
              tooltipAlign="left"
            />
          )
      )}
  </>
);
