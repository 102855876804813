import { cachedRequest, request } from '../../lib/request';
import qs from 'query-string';
import { domSafe } from '../../lib/document';
import { StoreReq } from '../../types/episerver/store';
import { AssortmentCookie } from '../../types/assortments';
import {
  getAssortmentCookie,
  setAssortmentCookie
} from '../../lib/assortments';

export const fetchPageById = (contentId, search, noCache, userSiteId) => {
  const queryParams = qs.parse(search);

  let assortmentValues: AssortmentCookie = getAssortmentCookie();
  if (userSiteId == '') {
    userSiteId = assortmentValues?.userSiteId;
  }

  // tmp fix
  if (assortmentValues && assortmentValues.userPostalCode == null) {
    delete assortmentValues.userPostalCode;
    setAssortmentCookie(assortmentValues);
  }

  //workId is used to enable episerver preview.
  if (queryParams.workId && queryParams.auth) {
    const opts = {
      headers: {
        cookie: `cg.preview=${queryParams.auth}`
      }
    };
    return request(
      `/PageData/${contentId}?workId=${queryParams.workId}${
        userSiteId ? '&siteId=' + userSiteId : ''
      }`,
      opts
    );
  }

  if ((domSafe() && qs.parse(window.location.search).noCache) || noCache) {
    return request(
      `/PageData/${contentId}?nocache=true${
        userSiteId ? '&siteId=' + userSiteId : ''
      }`
    );
  }
  return cachedRequest(
    `/PageData/${contentId}${userSiteId ? '?siteId=' + userSiteId : ''}`
  );
};

interface StoreRequest {
  data: StoreReq[];
}

interface GetStoreRequest {
  selectableStores: StoreReq[];
  allStores: StoreReq[];
}

export const getStores = async (): Promise<GetStoreRequest> => {
  const selectableStores = await getAllStores();

  return {
    selectableStores: selectableStores?.data?.filter(
      site => site.data?.displayInSiteStoreSelector
    ),
    allStores: selectableStores?.data
  };
};

const getAllStores = (): Promise<StoreRequest> => request(`/PageData/stores`);
