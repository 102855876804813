import React, { useEffect, useState, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Pagination as PaginationComponent } from '@citygross/components';
import { useWindowSize } from '@citygross/utils';
import { Icons } from '@citygross/icons';
import './Pagination.scss';

type TPagination = {
  pageIndex?: number;
  totalCount?: number;
  pageCount?: number;
  itemsPerPage?: number;
  fetchPage?: Function;
  parentRef?: React.RefObject<HTMLInputElement>;
};

const Pagination = ({
  pageCount,
  totalCount,
  fetchPage,
  itemsPerPage = 20
}: TPagination) => {
  const [pageIndex, setPageIndex] = useState(0);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let urlPage = Number(params.get('page') || 1);

    if (urlPage === 0) {
      urlPage = 1;
      params.set('page', '1');
      history.replace({ search: params.toString() });
    }

    if (pageIndex === null || pageIndex !== urlPage - 1) {
      setPageIndex(urlPage - 1);
    }
  }, [location.search, pageIndex, history]);

  const size = useWindowSize();
  const maxPagesToShow = useMemo(() => {
    return (
      (pageCount || pageCount === 0) &&
      Math.min(
        pageCount,
        size && size.width < 576 ? (size.width > 480 ? 8 : 6) : 10
      )
    );
  }, [size]);

  const updateURL = (page: number) => {
    const params = new URLSearchParams(location.search);
    params.set('page', String(page));
    history.push({ search: params.toString() });
  };

  const handlePageChange = (page: number) => {
    setPageIndex(page - 1);
    fetchPage && fetchPage(page - 1);
    updateURL(page);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <div className="c-pagination">
      <PaginationComponent
        currentPage={(pageIndex ?? 0) + 1}
        itemsPerPage={itemsPerPage || 20}
        totalItems={totalCount || 0}
        fetchItems={handlePageChange}
        numOfPaginationsToShow={maxPagesToShow || 1}
        rightIcon={<Icons.ChevronRight width={16} height={16} />}
        leftIcon={<Icons.ChevronLeft width={16} height={16} />}
      />
    </div>
  );
};

export default Pagination;
