import React, { useEffect, useState } from 'react';
import { ILoop54Recipes } from '../../types/Loop54Recipes';
import qs from 'query-string';
import { pageLayoutSelector } from '../../selectors/page';
import { selectCurrentAssortment } from '../../selectors/assortments';
import {
  fetchRecipesByTags,
  fetchLoopRecipesByIds,
  fetchLoopRecipesByCategory
} from '../../api/endpoints/recipe';

import withBaseBlock from '../../components/BaseBlock';
import RecipeGrid from '../../modules/RecipeGrid';
import { connect } from 'react-redux';
import { getCategoryPageByUrl } from '../../selectors/routing/routingSelector.js';
import {
  applyRecipeTagsToObjectValues,
  handleGA4Event
} from './RecipeArticleBlock.utils';
import { TRecipeArticleBlock } from './RecipeArticleBlock.types';

const RecipeArticleBlock = (props: TRecipeArticleBlock) => {
  const [fetchedProducts, setFetchedProducts] = useState<ILoop54Recipes[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchingFailed, setFetchingFailed] = useState<boolean>(false);
  const [currentStoreId, setCurrentStoreId] = useState<number | null>(null);
  const [initialLoad, setInitialLoad] = useState(false);

  useEffect(() => {
    if (!initialLoad || (currentStoreId !== props.storeId && !loading)) {
      fetchProducts();
      setInitialLoad(true);
      setCurrentStoreId(props.storeId);
    }
  }, [initialLoad]);

  const handleFetchedItems = (data: ILoop54Recipes[], storeId: number) => {
    if (!data?.length) {
      setFetchingFailed(true);
    }
    setFetchedProducts(data);
    setFetchingFailed(false);
    setLoading(false);
    handleGA4Event(data, storeId, props.name);
  };

  const fetchProducts = () => {
    setLoading(true);
    const { tags, ximEntitiesList, ximEntities, storeId } = props;
    if (
      props?.previewFromCategoryPage &&
      props?.link?.categoryPageId &&
      props?.previewCategoryPage?.name
    ) {
      fetchLoopRecipesByCategory(props?.link?.categoryPageId, {
        categoryName: props?.previewCategoryPage?.name
      })
        .then(({ data }) => {
          handleFetchedItems(data.items, storeId);
        })
        .catch(() => setFetchingFailed(true));
    } else if (
      (ximEntitiesList && ximEntitiesList.length > 0) ||
      (ximEntities && ximEntities.length > 0)
    ) {
      // should be removed hopefully soon
      const tempIds = ximEntities && ximEntities.map(entity => entity.id);
      fetchLoopRecipesByIds(ximEntitiesList || tempIds)
        ?.then(({ data }) => {
          handleFetchedItems(data.items, storeId);
        })
        .catch(() => setFetchingFailed(true));
    } else if (tags && tags?.length > 0) {
      fetchRecipesByTags(
        qs.stringify(applyRecipeTagsToObjectValues({ tags: tags }))
      )
        .then(({ data }) => {
          handleFetchedItems(data.items, storeId);
        })
        .catch(() => setFetchingFailed(true));
    }
  };

  const { ...rest } = props;

  if (fetchingFailed) {
    return null;
  }

  return (
    <RecipeGrid
      noPad
      recipes={fetchedProducts}
      impressionListName={props.name}
      {...rest}
    />
  );
};
const mapStateToProps = (state, props) => {
  return {
    storeId: selectCurrentAssortment(state),
    pageLayout: pageLayoutSelector(state, props),
    previewCategoryPage:
      props?.previewFromCategoryPage && props?.link?.url
        ? getCategoryPageByUrl(state, props?.link?.url)
        : null
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withBaseBlock(RecipeArticleBlock));
