import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userKdbIdSelector, authenticatedSelector } from '../../selectors/user';
import {
  fetchLoopProductById,
  fetchMostBoughtProducts
} from '../../api/endpoints';
import ProductList from '../../containers/ProductList';
import RichText from '../../containers/RichText';
import { selectCurrentAssortment } from '../../selectors/assortments';
import { FilterProducts } from '../../components/FilterProducts';
import { redirectToLogin } from '../../actions/app';
import { setProductFavorite, removeFavorite } from '../../actions/auth';
import * as ga4 from '@citygross/analytics';
import {
  ga4ImpressionList,
  generateGA4ImpressionsArrayFromEecImpressions
} from '../../lib/analytics/analytics';
import { hasAvailability } from '../../lib/availability';
import {
  ILoopSort,
  PRODUCT_SORT_OPTS,
  sortParamsHelper
} from '../../lib/sorting';

const DEFAULT_DAYS_TO_FETCH = 90;
const DEFAULT_MAX_ITEMS_TO_FETCH = 50;
const DEFAULT_NBR_OF_SKELETONS = 6;
const impressionListName = 'Most bought products';

interface IMostBoughtProducts {
  daysToFetch: number;
  maxItemsToFetch: number;
  skeletons: number;
  notLoggedInText?: string;
  missingDataText?: string;
  isAuthenticated: boolean;
  kdbId: number | null;
  storeId: number | null;
  redirectToLogin: () => void;
  removeFavorite: () => void;
  setProductFavorite: (
    productId: string,
    item: {
      name: string;
      value: number;
    }
  ) => Promise<void>;
}
interface IMostBoughtState {
  fetching: boolean;
  products: any[] | null;
  fetchError: boolean;
  show: boolean | null;
  selectedSort: string | null;
  productIdsWithoutPaperBag: string[];
}

class MostBoughtProducts extends Component<
  IMostBoughtProducts,
  IMostBoughtState
> {
  static defaultProps = {
    daysToFetch: DEFAULT_DAYS_TO_FETCH,
    maxItemsToFetch: DEFAULT_MAX_ITEMS_TO_FETCH,
    skeletons: DEFAULT_NBR_OF_SKELETONS,
    notLoggedInText:
      '<p>Här kan du se dina mest köpta varor när du har loggat in.</p>',
    missingDataText:
      'Du har inte handlat några varor och vi kan därför inte visa dina mest köpta varor.'
  };
  state: IMostBoughtState = {
    fetching: true,
    products: [],
    fetchError: false,
    show: null,
    selectedSort: null,
    productIdsWithoutPaperBag: []
  };

  componentDidMount() {
    const { isAuthenticated } = this.props;
    if (isAuthenticated) {
      this.getProducts();
    }

    this.setState({
      show: true
    });

    this.setState({
      selectedSort: null
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isAuthenticated && prevProps.kdbId !== this.props.kdbId) {
      this.getProducts();
    }

    if (
      this.props.isAuthenticated &&
      !this.state.fetching &&
      prevProps.storeId !== this.props.storeId
    ) {
      this.setState({
        products: [],
        fetching: true
      });
      this.getProducts();
    }
    if (prevState.selectedSort !== this.state.selectedSort) {
      this.getLoop54Products();
    }
  }
  getLoop54Products() {
    const sort = sortParamsHelper(
      this.state.selectedSort || '',
      PRODUCT_SORT_OPTS
    );
    fetchLoopProductById(
      this.state.productIdsWithoutPaperBag,
      sort?.SortField,
      this.state.productIdsWithoutPaperBag.length,
      sort?.SortOrder
    ).then(({ data }) => {
      if (data?.items && data?.items?.length > 0) {
        this.setState({
          products: data.items?.filter(product =>
            hasAvailability(product?.productStoreDetails)
          ),
          fetching: false
        });

        try {
          ga4ImpressionList(data.items, impressionListName);
        } catch (error) {
          console.error(error);
          console.log('error', error);
        }
      } else {
        this.setState({
          products: [],
          fetching: false
        });
      }
    });
  }

  getProducts() {
    const { daysToFetch, maxItemsToFetch } = this.props;

    if (daysToFetch > 0 && maxItemsToFetch > 0) {
      fetchMostBoughtProducts(daysToFetch, maxItemsToFetch)
        .then(data => {
          const productsIds = data;
          if (productsIds.length > 0) {
            const paperBagIds = ['8837429', '101408629'];
            const productIdsWithoutPaperBag = productsIds.filter(
              c => !paperBagIds.includes(c)
            );
            this.setState({
              productIdsWithoutPaperBag: productIdsWithoutPaperBag
            });
            fetchLoopProductById(
              productIdsWithoutPaperBag,
              this.state.selectedSort,
              productIdsWithoutPaperBag.length
            )
              .then(({ data }) => {
                if (data?.items && data?.items?.length > 0) {
                  this.setState({
                    products: data?.items?.filter(product =>
                      hasAvailability(product?.productStoreDetails)
                    ),
                    fetching: false
                  });
                  try {
                    const impressionList = data?.items?.map((prod, index) => {
                      const price = prod?.productStoreDetails?.prices;
                      return {
                        id: prod.gtin,
                        name: prod.name,
                        category:
                          prod.url?.substring(
                            0,
                            prod.url.lastIndexOf('/') + 1
                          ) || undefined,
                        brand: prod.brand || 'City Gross',
                        list: impressionListName,
                        position: index,
                        dimension8: price?.hasDiscount
                          ? 'On Sale'
                          : 'Not On Sale'
                      };
                    });
                    ga4.viewItemList({
                      items: generateGA4ImpressionsArrayFromEecImpressions(
                        impressionList
                      ),
                      item_list_id: impressionListName,
                      item_list_name: impressionListName
                    });
                  } catch (error) {
                    console.error(error);
                    console.log('error', error);
                  }
                } else {
                  this.setState({
                    products: [],
                    fetching: false
                  });
                }
              })
              .catch(err => {
                console.log(err);
                this.setState({
                  fetchError: true,
                  products: null,
                  fetching: false
                });
              });
          } else {
            this.setState({
              products: [],
              fetching: false
            });
          }
        })
        .catch(err => {
          console.error(err); // eslint-disable-line no-console
          alert(2);
          this.setState({
            fetchError: true,
            products: null,
            fetching: false
          });
        });
    } else if (daysToFetch === 0 && maxItemsToFetch === 0) {
      alert(1);
      this.setState({
        fetching: false,
        products: []
      });
    }
  }

  render() {
    const { fetchError, fetching, products, show } = this.state;
    const {
      isAuthenticated,
      skeletons,
      notLoggedInText,
      missingDataText
    } = this.props;

    if (!isAuthenticated) {
      return (
        <div className="c-most-bought-products">
          <RichText text={notLoggedInText} />
        </div>
      );
    }

    return (
      show && (
        <div className="c-most-bought-products">
          {((!fetching && !products) || fetchError) && (
            <p>
              Just nu kan vi inte hämta dina mest köpta produkter. Försök igen
              senare.
            </p>
          )}
          {!fetching && products && products.length === 0 && (
            <div className="c-account--empty">
              <div className="mb-10">
                <RichText text={missingDataText} />
              </div>

              <Link className="c-cmdbtn primary" to="/matvaror">
                Börja handla
              </Link>
            </div>
          )}
          {(fetching || (products && products.length > 0)) && (
            <>
              <FilterProducts
                onChange={e => {
                  this.setState({ selectedSort: e });
                }}
              />
              <ProductList
                skeletons={skeletons}
                products={products}
                fetching={fetching}
                withoutPaginationAndFilter
                impressionListName={impressionListName}
              />
            </>
          )}
        </div>
      )
    );
  }
}

const mapStateToProps = state => ({
  kdbId: userKdbIdSelector(state),
  isAuthenticated: authenticatedSelector(state),
  storeId: selectCurrentAssortment(state)
});

const mapDispatchToProps = {
  redirectToLogin,
  setProductFavorite,
  removeFavorite
};

export default connect(mapStateToProps, mapDispatchToProps)(MostBoughtProducts);
