import { format } from 'date-fns';
import { sv } from 'date-fns/locale';
import React from 'react';
import { ILoop54Promotion } from '../../../types/loop54Product';
import { BodyText, TextTypes } from '@citygross/typography';
import { theme } from '@citygross/design-tokens';

type TPromotionDates = {
  promotion: ILoop54Promotion;
};

export const PromotionDates = ({ promotion }: TPromotionDates) => {
  const date = new Date();
  const fromDate = new Date(promotion.from);
  const toDate = new Date(promotion.to);

  if (date < fromDate) {
    return null;
  }

  return date >= fromDate && date <= toDate ? (
    <BodyText color={theme.palette?.darkest} size={TextTypes.TextSize.SMALL}>
      {`Gäller ${format(fromDate, 'EEEE d/M', {
        locale: sv
      })} till ${format(toDate, 'EEEE d/M', { locale: sv })}`}
    </BodyText>
  ) : null;
};
