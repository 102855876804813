import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getCateringProductById } from '../../actions/catering';
import { authenticatedSelector } from '../../selectors/user';
import { getSingleCateredMeal } from '../../selectors/catering/cateringSelectors';
import { redirectToLogin } from '../../actions/app';
import LoadBlock from '../../components/LoadingBlock';
import CateringSingleDetailPage from './CateringSingleDetailPage';
import { setVariant } from '../../lib/catering';
import { breadCrumbSelector } from '../../selectors/routing/routingSelector';
import {
  pageSelector,
  pageIdSelector,
  seoSelector
} from '../../selectors/page';
import './CateringSinglePage.scss';
import { selectCurrentAssortment } from '../../selectors/assortments';
import { sendCateringItemAddTracking } from '../../lib/analytics/analytics';

const CateringSinglePage = ({
  isAuthenticated,
  catering,
  getCateringProductById,
  storeId,
  redirectToLogin,
  ...props
}) => {
  const retrieveId = props => {
    const ownProps = props.ownProps || { match: { params: {} } };
    return (
      props.match.params.cateringName || ownProps.match.params.cateringName
    );
  };

  useEffect(() => {
    getCateringProductById(retrieveId(props));
  }, [props.location?.pathname, storeId]);

  const cateringProduct = catering.products[0];

  return (
    <>
      {catering.products.length > 0 ? (
        <CateringSingleDetailPage
          title={cateringProduct.name}
          id={cateringProduct.id}
          description={cateringProduct.description}
          storeId={storeId}
          isFlyout={props.isFlyout}
          unit={cateringProduct.unit}
          variants={
            cateringProduct.variants &&
            Object.keys(cateringProduct.variants).length > 0 &&
            cateringProduct.variants
          }
          hasVariants={
            cateringProduct.variants &&
            Object.keys(cateringProduct.variants).length > 0
          }
          hasSides={cateringProduct.sides && cateringProduct.sides.length > 0}
          sides={
            (cateringProduct.sides &&
              cateringProduct.sides.length > 0 &&
              cateringProduct.sides) ||
            []
          }
          url={cateringProduct.url}
          cateringImageUrl={cateringProduct.image.url}
          isBuffe={!!cateringProduct.minOrder && !!cateringProduct.maxOrder}
          minOrder={cateringProduct.minOrder}
          maxOrder={cateringProduct.maxOrder}
          sendCateringItemAddTracking={orderlines =>
            sendCateringItemAddTracking(catering.products?.[0], orderlines)
          }
          defaultVariant={
            Object.keys(cateringProduct.variants)[
              Object.keys(cateringProduct.variants).findIndex(
                variant => variant.toLowerCase() === 'standard'
              )
            ] || Object.keys(cateringProduct.variants)[0]
          }
          setVariant={(variantType, selectedVariant) =>
            setVariant(cateringProduct.id, variantType, selectedVariant)
          }
          label={cateringProduct.unit === 'per styck' ? 'st' : 'portioner'}
          singularLabel={
            cateringProduct.unit === 'per styck' ? 'st' : 'portion'
          }
          isAuthenticated={isAuthenticated}
          redirectToLogin={redirectToLogin}
          sellable={cateringProduct.sellable}
        />
      ) : (
        <LoadBlock />
      )}
    </>
  );
};

CateringSinglePage.defaultProps = {
  variants: [],
  catering: [],
  isFetching: true
};

CateringSinglePage.propTypes = {
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      isChecked: PropTypes.bool,
      label: PropTypes.string
    })
  )
};

CateringSinglePage.required = [
  (state, { params }) => {
    return getCateringProductById(params.cateringName);
  }
];

const mapStateToProps = (state, props) => ({
  storeId: selectCurrentAssortment(state),
  catering: getSingleCateredMeal(state, props.match.params.cateringName),
  title: breadCrumbSelector(state, props),
  isAuthenticated: authenticatedSelector(state),
  id: pageIdSelector(state, props),
  page: pageSelector(state, props),
  fetching: state.product.fetchings,
  seo: seoSelector(state, props)
});

const mapDispatchToProps = {
  getCateringProductById,
  redirectToLogin
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CateringSinglePage)
);
