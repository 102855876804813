import React from 'react';
import { Link } from 'react-router-dom';
import { loop54ClickNotifier } from '../../../api/endpoints/loopNotify';

const categoryTypes = [
  'cateringcategorypage',
  'recipecategorypage',
  'productcategorypage'
];

function ItemType(item) {
  let prefix = '';
  const hasPrefix = categoryTypes.includes(item.pagetype?.toLowerCase());
  if (hasPrefix) {
    switch (item.pagetype?.toLowerCase()) {
      case 'productcategorypage': {
        if (item.parentName) {
          prefix = `${item.parentName} > `;
        }
        break;
      }
      case 'recipecategorypage': {
        prefix = `Recept > `;
        break;
      }
      case 'cateringcategorypage': {
        prefix = `Catering > `;
        break;
      }
      default: {
        console.warn(`Unhandled type: ${item.pagetype}`);
      }
    }
  }

  return { prefix };
}

const ContentItem = ({ item }) => {
  const { prefix } = ItemType(item);
  const { name, url } = item;

  return (
    <div className="c-searchresultcontent__item">
      <Link to={url} onClick={() => loop54ClickNotifier(item.loop54Id)}>
        {prefix}
        {name}
      </Link>
    </div>
  );
};

export default ContentItem;
