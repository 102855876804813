import React, { useState } from 'react';

import Select from '../Select';
import '../CategoryFilter/CategoryFiltersSort/CategoryFiltersSort.scss';
import { PRODUCT_SORT_OPTS } from '../../lib/sorting';

type TFilterProductsProps = {
  onChange: (e?: any) => void;
};

export const FilterProducts = ({ onChange }: TFilterProductsProps) => {
  return (
    <div
      style={{
        padding: '12px',
        marginLeft: 'auto',
        display: 'flex',
        justifyContent: 'flex-end'
      }}
      className="c-category-filters-sort"
    >
      <div className="c-category-filters-sort__label">Sortera efter</div>
      <div className="c-category-filters-sort__select-wrap">
        <Select options={PRODUCT_SORT_OPTS} onChange={onChange} />
      </div>
    </div>
  );
};
