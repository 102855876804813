import React from 'react';
import OfferSingleDetails from './OfferSingleDetails';
import './OfferSingle.scss';

import { TOffer } from '../../types/offers/offers';

type TOfferSingle = {
  isFlyout: boolean;
  offer: TOffer;
};

export const OfferSingle = ({ isFlyout, offer }: TOfferSingle) => (
  <div className="c-productdetails">
    <OfferSingleDetails isFlyout={isFlyout} offer={offer} />
  </div>
);
