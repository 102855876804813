import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cs from 'classnames';
import { LazyProgressivePicture, ImageFitTypes } from '../ProgressivePicture';
import Markings from '../Markings';
import { FavoriteButton } from '../Buttons';
import BuyButton from '../BuyButton';
import Availability from '../Availability';
import './ProductCard.scss';
import {
  getProductAvailability,
  getProductDescription,
  setImage
} from '../../lib/product';
import { useSelector } from 'react-redux';
import { AppState } from '../../reducers';
import { IExtendedProduct } from '../../types/storefront/product';
import {
  productFavoritesSelector,
  favoriteProductsLoadingSelector
} from '../../selectors/product/productSelectors';
import WarningLabel from '../WarningLabel';
import { ga4ImpressionClickTracking_Product } from '../../lib/analytics/analytics';
import { loop54ClickNotifier } from '../../api/endpoints/loopNotify';
import {
  EPriceVariant,
  EProductHeader,
  PriceStripe,
  PriceTag,
  ProductHeader
} from '@citygross/components';
import PriceDetails from '../PriceDetails';
import { getPriceInfo } from '../../lib/price';
import { comparisonTypes } from '@citygross/utils';
import { BodyText, TextTypes } from '@citygross/typography';

const ProductPlaceholder = require('../assets/produkt-fallback.svg');

type TProductCard = {
  buttonText?: string;
  colStructure?: string;
  impressionListName?: string;
  impressionListPosition?: number;
  onCarousel?: boolean;
  onClose?: Function;
  product: IExtendedProduct;
  removeFavorite: Function;
  setProductFavorite: Function;
};

export const ProductCard: React.FC<TProductCard> = ({
  buttonText,
  colStructure = 'l-column-30_xs-30_sm-20_md-15_lg-12_xlg-10-mobileGutter',
  impressionListName,
  impressionListPosition,
  onCarousel,
  onClose = () => {},
  product,
  removeFavorite,
  setProductFavorite
}) => {
  const handleLinkClick = () => {
    operationOnProductClick();
    if (impressionListName && impressionListPosition) {
      try {
        ga4ImpressionClickTracking_Product({
          product,
          impressionListName,
          impressionListPosition
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  };

  const isFavorite = useSelector((state: AppState) =>
    productFavoritesSelector(state).find(x => x.itemNo === product.id)
  );

  const isFavoriteLoading = useSelector((state: AppState) =>
    Boolean(favoriteProductsLoadingSelector(state).find(x => x === product.id))
  );

  const favoriteButtonClick = () => {
    isFavorite
      ? removeFavorite(isFavorite)
      : setProductFavorite(product.id, {
          name: product.name,
          value: priceToDisplay
        });
  };

  const [showMax, setShowMax] = useState<boolean>(false);

  const operationOnProductClick = () => {
    loop54ClickNotifier(product.id);
    onClose && onClose();
  };

  const productAvailability = getProductAvailability(
    product.productStoreDetails
  );

  const imageUrl = setImage(product.images, 0).url;

  const {
    hasReturnablePackageDeposit,
    limitedStock,
    multiPrice,
    priceVariant,
    priceObjectToDisplay,
    priceToDisplay,
    productStorePrices
  } = getPriceInfo(product);

  const productHeaderSubTitle = getProductDescription({
    bfCategoryCode: product.bfCategoryCode,
    brand: product.brand,
    countryOfOrigin: product.countryOfOrigin,
    descriptiveSize: product.descriptiveSize
  });

  return (
    <div
      className={cs(colStructure, {
        'p-carousel_card': onCarousel
      })}
      data-productid={product.gtin}
    >
      <Link
        className="productcard-container"
        tabIndex={0}
        to={{
          pathname: product.url,
          state: {
            flyout: true,
            title: ' ',
            nopadding: true,
            tight: false
          }
        }}
        onClick={handleLinkClick}
        draggable={false}
      >
        <div className="product-card__upper-container">
          <div className="top">
            <FavoriteButton
              active={isFavorite ? true : false}
              handleClick={favoriteButtonClick}
              loading={isFavoriteLoading}
            />

            <div className="end">
              {priceVariant !== EPriceVariant.REGULAR && (
                <BodyText size={TextTypes.TextSize.TINY}>
                  Villkor gäller
                </BodyText>
              )}

              {productAvailability && (
                <Availability availability={productAvailability} />
              )}
            </div>
          </div>

          <div className="image-container">
            <div className="product-image">
              <link href={imageUrl} />

              <LazyProgressivePicture
                largeImage={{
                  url: imageUrl,
                  width: 164,
                  height: 164
                }}
                fitType={ImageFitTypes.Contain}
                inheritHeight
                maxWidth={{
                  large: 164,
                  small: 164
                }}
                placeholder={{
                  image: ProductPlaceholder,
                  bgColor: 'transparent'
                }}
              />
            </div>

            {priceVariant === EPriceVariant.PRIO && (multiPrice ?? 0) > 1 && (
              <div className="price-stripe-container">
                <PriceStripe variant={EPriceVariant.PRIO} />
              </div>
            )}

            {!!product.mappedMarkings?.length && (
              <div className="markings">
                <Markings markings={product.mappedMarkings} />
              </div>
            )}
          </div>

          <div className="price-tag-container">
            <PriceTag
              multiPrice={multiPrice}
              pant={hasReturnablePackageDeposit}
              price={priceToDisplay || 0}
              unit={comparisonTypes(priceObjectToDisplay?.unit)}
              variant={priceVariant}
            />
          </div>
        </div>

        <div className="product-card__lower-container">
          <ProductHeader
            location={EProductHeader.CARD}
            title={product.name}
            subTitle={productHeaderSubTitle}
          />

          <div className="push-to-bottom push-to-bottom--ie-support full-width">
            <div className="price-details-container">
              {limitedStock && <WarningLabel label="Begr. tillgänglighet" />}

              {showMax ? (
                <span className="max-amount">Max antal uppnått</span>
              ) : (
                <PriceDetails
                  currentPrice={priceObjectToDisplay}
                  displayLowestPriceLast30Days={
                    productStorePrices?.promotions?.[0]
                      ?.displayLowestPriceLast30Days
                  }
                  fontSize={TextTypes.TextSize.TINY}
                  hasReturnablePackageDeposit={hasReturnablePackageDeposit}
                  lowestPriceLast30Days={
                    productStorePrices?.lowestPriceLast30Days
                  }
                  ordinaryPrice={productStorePrices?.ordinaryPrice}
                  priceVariant={priceVariant}
                  unit={product.unit}
                />
              )}
            </div>

            <div className="cartactions">
              <BuyButton
                product={product}
                buttonText={buttonText}
                fullWidth={true}
                setShowMax={setShowMax}
              />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
