import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import BuyButton from '../../../components/BuyButton';

import { getProductDescription, setImage } from '../../../lib/product';
import { IExtendedProduct } from '../../../types/storefront/product';
import './ProductSuggestion.scss';
import './ProductSuggestion.scss';
import { SearchListItem } from '@citygross/components';
import Image from '../../../components/Image';
import { loop54ClickNotifier } from '../../../api/endpoints/loopNotify';
import FallbackImage from '../../../components/assets/produkt-fallback.svg';
import { getPriceInfo } from '../../../lib/price';
interface IProductSuggestion {
  product: IExtendedProduct;
  setKeepOpen: () => void;
}

export const ProductSuggestion = ({
  product,
  setKeepOpen
}: IProductSuggestion) => {
  const { images, name, url } = product;

  const image = setImage(images, 0);

  const description = getProductDescription({
    bfCategoryCode: product.bfCategoryCode,
    brand: product.brand,
    countryOfOrigin: product.countryOfOrigin,
    descriptiveSize: product.descriptiveSize
  });

  const linkOnClick = () => {
    setKeepOpen();
    loop54ClickNotifier(product.id);
  };

  const [showMax, setShowMax] = useState<boolean>(false);

  const {
    hasReturnablePackageDeposit,
    multiPrice,
    priceVariant,
    priceToDisplay,
    priceToDisplayUnit
  } = getPriceInfo(product);

  const priceTag = {
    multiPrice,
    pant: hasReturnablePackageDeposit,
    price: priceToDisplay || 0,
    variant: priceVariant,
    unit: priceToDisplayUnit
  };

  return (
    <Link
      to={{
        pathname: url,
        state: { flyout: true, title: ' ', nopadding: true, tight: false }
      }}
      onClick={linkOnClick}
    >
      <div className="c-productsuggestion-card__container">
        <SearchListItem
          item={{
            description: description,
            image: image.url,
            name: name
          }}
          markings={product.mappedMarkings
            ?.filter(x => x.code === 'RestrictedToSell18')
            ?.map(marking => ({
              image: (
                <div style={{ height: 20, width: 20 }}>
                  <Image
                    src={marking.image?.url}
                    alt={marking?.altText}
                    noLazy
                  />
                </div>
              ),
              backgroundColor: 'rgba(0,0,0,0.9)',
              tooltipText: marking?.altText
            }))}
          priceTag={priceTag}
          button={
            <div className="in-dropdown">
              <BuyButton
                product={product}
                minWidth="120px"
                small
                setShowMax={setShowMax}
              />
            </div>
          }
          fallBackImage={FallbackImage}
          showMax={showMax}
        />
      </div>
    </Link>
  );
};
