/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './DeliverySummary.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectedStore,
  selectedDeliverySettings,
  assortmentsLoading,
  sitesLoading
} from '../../../selectors/assortments';
import { AppState } from '../../../reducers';
import { openSideModal } from '../../../slices/sideModalSlice';
import { SideModalTabs } from '../../SideModal';
import { getFirstDeliveryDate } from '../../../lib/cart';
import { Icons } from '@citygross/icons';
import { useTheme } from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { setCurrentStep } from '../../../slices/deliveryPickerSlice';

type TDeliverySummary = {
  cartId?: string;
  isReplacement?: boolean;
  isLoggedInAsCustomer?: boolean;
};

type TDeliverySlot = {
  deliveryDate?: string;
  deliveryFromTime?: string;
  deliveryToTime?: string;
};
const dayNamesSV = [
  'måndag',
  'tisdag',
  'onsdag',
  'torsdag',
  'fredag',
  'lördag',
  'söndag'
];
export const DeliverySummary = ({
  cartId,
  isLoggedInAsCustomer,
  isReplacement
}: TDeliverySummary) => {
  const dispatch = useDispatch();
  const isSitesLoading = useSelector((state: AppState) => sitesLoading(state));
  const store = useSelector((state: AppState) => selectedStore(state));
  // @TODO: don't show for now
  // const [firstSlot, setFirstSlot] = useState<TDeliverySlot | null>(null);
  const activeDeliverySettings = useSelector((state: AppState) =>
    selectedDeliverySettings(state)
  );
  const loading = useSelector(assortmentsLoading);
  const theme = useTheme();
  // @TODO: don't show for now
  // const getFirstAvailableSlot = async () => {
  //   const { deliveryMethod, siteId, zipCode } = activeDeliverySettings;
  //   await getFirstDeliveryDate(
  //     deliveryMethod,
  //     zipCode,
  //     cartId,
  //     siteId,
  //     isReplacement
  //   )
  //     .then(slot => {
  //       setFirstSlot(slot);
  //     })
  //     .catch(e => console.log(e, 'error'));
  // };

  // useEffect(() => {
  //   getFirstAvailableSlot();
  // }, [cartId, activeDeliverySettings?.deliveryMethod]);

  // const getDateName = date => {
  //   const today = new Date()?.getDay();
  //   const dateDay = new Date(date)?.getDay();

  //   if (dateDay - today === 1) {
  //     return 'i morgon';
  //   }
  //   return dayNamesSV[dateDay - 1];
  // };

  return !activeDeliverySettings.deliveryMethod ? (
    <div className="c-cart-delivery-box">
      <div className="c-cart-first-delivery-time">
        {!isSitesLoading && (
          <>
            <span>
              <span
                onClick={() => {
                  dispatch(setCurrentStep({ currentStep: 0, previousStep: 0 }));
                  dispatch(openSideModal(SideModalTabs.DELIVERY_CHOICE_PICKER));
                }}
                tabIndex={0}
                className={'c-change-delivery-link'}
              >
                Välj butik
              </span>{' '}
              för att se ditt sortiment
            </span>
          </>
        )}
      </div>
    </div>
  ) : activeDeliverySettings.deliveryMethod === 'homeDelivery' ? (
    <div className="c-cart-delivery-box">
      <div className="c-cart-first-delivery-time">
        <span className="c-cart-first-delivery-icon">
          <Icons.TruckDelivery
            color={theme.palette?.alertBlue}
            height={20}
            width={20}
          />
        </span>
        <span>
          Hemleverans till{' '}
          <span className="delivery-summary-hide-m">postnummer </span>
          <span
            onClick={() =>
              dispatch(openSideModal(SideModalTabs.DELIVERY_CHOICE_PICKER))
            }
            tabIndex={0}
            className={'c-change-delivery-link'}
          >
            {activeDeliverySettings.zipCode}
          </span>
        </span>
      </div>
      {/* @TODO: don't s */}
      {/* {firstSlot?.deliveryDate && (
        <>
          <div className="c-cart-first-delivery-time delivery-summary-hide-m">
            <span>
            <span className="c-cart-delivery-text ">tidigast </span>
              <span className="c-cart-delivery-time-label">
                <span> {getDateName(firstSlot?.deliveryDate)}</span> kl{' '}
                <span>{firstSlot?.deliveryFromTime?.slice(0, 2)}</span> -{' '}
                <span>{firstSlot?.deliveryToTime?.slice(0, 2)}</span>
              </span>how for now
            </span>
          </div>
        </>
      ) } */}
    </div>
  ) : (
    <div className="c-cart-delivery-box">
      <div className="c-cart-first-delivery-time">
        <span className="c-cart-first-delivery-icon">
          <Icons.Store
            color={theme.palette?.alertBlue}
            height={20}
            width={20}
          />
        </span>
        <span>
          <span>Vald butik</span>{' '}
          {loading ? (
            <span>
              {' '}
              <Skeleton width={120} />{' '}
            </span>
          ) : (
            <span
              onClick={() =>
                dispatch(openSideModal(SideModalTabs.DELIVERY_CHOICE_PICKER))
              }
              tabIndex={0}
              className={'c-change-delivery-link'}
            >
              {store?.name}
            </span>
          )}
        </span>
      </div>
      {/* @TODO: don't show for now */}
      {/* {firstSlot?.deliveryDate && (
        <>
          <div className="c-cart-first-delivery-time delivery-summary-hide-m">
            <span>
              {' '}
              <Skeleton width={120} />{' '}
            </span>
          ) : (
            <span
              onClick={() =>
                dispatch(openSideModal(SideModalTabs.DELIVERY_CHOICE_PICKER))
              }
              tabIndex={0}
              className={'c-change-delivery-link'}
            >
              {store?.name}
            </span>

          </div>
        </>
      )} */}
    </div>
  );
};

DeliverySummary.propTypes = {
  deliveryMethod: PropTypes.string,
  notLoggedIn: PropTypes.bool,
  openInFlyout: PropTypes.func,
  siteId: PropTypes.number,
  zipCode: PropTypes.string
};

DeliverySummary.defaultProps = {
  zipCode: null
};

export default DeliverySummary;
