import React from 'react';
import RecipeList from '../../../components/RecipeList/RecipeList';

const RecipeView = ({
  items,
  pagination,
  requestMoreRecipes,
  fetching,
  isAuthenticated,
  setRecipeFavorite,
  removeFavorite,
  redirectToLogin,
  impressionListName,
  categoryId
}) => {
  const setRecipeFavoriteFn = isAuthenticated
    ? setRecipeFavorite
    : redirectToLogin;
  const removeFavoriteFn = isAuthenticated ? removeFavorite : redirectToLogin;

  return (
    <RecipeList
      categoryId={categoryId}
      fetching={fetching}
      requestMoreRecipes={requestMoreRecipes}
      setRecipeFavorite={setRecipeFavoriteFn}
      removeFavorite={removeFavoriteFn}
      pagination={pagination}
      items={items}
      impressionListName={impressionListName}
    />
  );
};
export default RecipeView;
