import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import CategorySuggestions from '../CategorySuggestions';
import GroupedCategoriesSuggestion from '../GroupedCategoriesSuggestion';
import ProductSuggestion from '../ProductSuggestion';
import Suggestions from '../Suggestions';

import './SearchSuggestions.scss';
import { Link } from 'react-router-dom';
import { Button } from '../../../components/Buttons';

export const SearchSuggestions = ({
  addToCart,
  cateredmeal,
  page,
  category,
  decreaseItemQuantity,
  isAuthenticated,
  increaseItemQuantity,
  onClose,
  onShowAll,
  products,
  query,
  recipes,
  redirectToLogin,
  resultCount,
  cateringCategoryPage,
  productCategoryPage,
  recipeCategoryPage,
  store,
  totalCount,
  storeId,
  setKeepOpen
}) => {
  const addToCartFn = isAuthenticated ? addToCart : redirectToLogin;

  const hasProducts = Boolean(products.items.length);
  const hasCategories = Boolean(category.items.length);

  const hasCategorized =
    Boolean(cateredmeal.items.length) ||
    Boolean(page.items.length) ||
    Boolean(recipes.items.length) ||
    Boolean(cateringCategoryPage.items.length) ||
    Boolean(productCategoryPage.items.length) ||
    Boolean(recipeCategoryPage.items.length);

  const hasCateredmeal = Boolean(cateredmeal.items.length);
  const hasPageContent = Boolean(page.items.length);
  const hasRecipes = Boolean(recipes.items.length);
  const hasCateringCategory = Boolean(cateringCategoryPage.items.length);
  const hasProductCategory = Boolean(productCategoryPage.items.length);
  const hasRecipeCategory = Boolean(recipeCategoryPage.items.length);
  const hasStore = Boolean(store?.items.length);

  const hasGroupedCategories =
    hasCateringCategory || hasProductCategory || hasRecipeCategory;

  const groupedCategoriesResultCount =
    hasGroupedCategories &&
    parseInt(
      resultCount?.find(countItem => countItem.option === 'category')?.count,
      10
    );
  const cateredmealResultCount = parseInt(
    resultCount?.find(countItem => countItem.option === 'cateredmeal')?.count,
    10
  );
  const cmscontentResultCount = parseInt(
    resultCount?.find(countItem => countItem.option === 'page')?.count,
    10
  );
  const productsResultCount = parseInt(
    resultCount?.find(countItem => countItem.option === 'product')?.count,
    10
  );
  const recipeResultCount = parseInt(
    resultCount?.find(countItem => countItem.option === 'recipe')?.count,
    10
  );
  const storePageResultCount = parseInt(
    resultCount?.find(countItem => countItem.option === 'store')?.count,
    10
  );
  const categoryResultCount = parseInt(
    resultCount?.find(countItem => countItem.option === 'category')?.count,
    10
  );

  return (
    <div className={'c-search-suggestions'}>
      {hasProducts ? (
        <>
          <div
            className={cs('c-search-suggestions__main-suggestions', {
              'c-search-suggestions__main-suggestions--has-sidebar': hasCategorized
            })}
          >
            <div className={'inner-wrapper'}>
              <Suggestions
                count={productsResultCount}
                onShowAll={onShowAll}
                query={query}
                title={products.title}
                type="product"
                onClose={onClose}
              >
                {products.items.slice(0, 6).map(item => (
                  <ProductSuggestion
                    product={item}
                    key={item.id}
                    noLazy={false}
                    setKeepOpen={setKeepOpen}
                    addToCart={() => addToCartFn(item)}
                    increaseItemQuantity={quantity =>
                      increaseItemQuantity(item.cartItemId, quantity)
                    }
                    decreaseItemQuantity={quantity =>
                      decreaseItemQuantity(item.cartItemId, quantity)
                    }
                  />
                ))}
              </Suggestions>
            </div>
          </div>
          <div className="c-search-result__view-all c-search-view-all__mobile">
            <Link
              to={`/sok?Q=${encodeURIComponent(query)}&store=${
                storeId ? storeId : ''
              }&page=0`}
            >
              <Button
                disabled={!totalCount > 0}
                onClick={onClose}
                minWidth={'170px'}
              >
                {' '}
                Visa fler sökresultat
              </Button>
            </Link>
          </div>
        </>
      ) : null}

      {hasCategorized ? (
        <div
          className={cs('c-search-suggestions__categorized-suggestions', {
            'c-search-suggestions__categorized-suggestions--is-sidebar': hasProducts,
            'c-search-suggestions__categorized-suggestions--is-main-area': !hasProducts
          })}
        >
          <div className={'inner-wrapper'}>
            {hasCateredmeal && (
              <CategorySuggestions
                count={cateredmealResultCount}
                itemClickHandler={onClose}
                items={cateredmeal.items}
                onClose={onClose}
                onShowAll={onShowAll}
                query={query}
                title={cateredmeal.title}
                type="cateredmeal"
                isSidebar={hasProducts}
                isPageCategoryOrStore={false}
              />
            )}

            {hasRecipes && (
              <CategorySuggestions
                count={recipeResultCount}
                itemClickHandler={onClose}
                items={recipes.items}
                onClose={onClose}
                onShowAll={onShowAll}
                query={query}
                title={recipes.title}
                type="recipe"
                isSidebar={hasProducts}
                isPageCategoryOrStore={false}
              />
            )}

            {hasGroupedCategories && (
              <GroupedCategoriesSuggestion
                count={groupedCategoriesResultCount}
                itemClickHandler={onClose}
                cateringCategoryPageItems={cateringCategoryPage.items}
                productCategoryPageItems={productCategoryPage.items}
                recipeCategoryPageItems={recipeCategoryPage.items}
                onClose={onClose}
                onShowAll={onShowAll}
                query={query}
                title={'Kategorier'}
                type="category"
                isSidebar={hasProducts}
                isPageCategoryOrStore={false}
              />
            )}

            {hasStore && (
              <CategorySuggestions
                count={storePageResultCount}
                itemClickHandler={onClose}
                items={store.items}
                onClose={onClose}
                onShowAll={onShowAll}
                query={query}
                title={store.title}
                type="store"
                isSidebar={hasProducts}
                isPageCategoryOrStore={true}
              />
            )}
            {hasCategories && (
              <CategorySuggestions
                count={categoryResultCount}
                itemClickHandler={onClose}
                items={category.items}
                onClose={onClose}
                onShowAll={onShowAll}
                query={query}
                title={'Kategorier'}
                type="category"
                isSidebar={hasProducts}
                isPageCategoryOrStore={true}
              />
            )}
            {hasPageContent && (
              <CategorySuggestions
                count={cmscontentResultCount}
                itemClickHandler={onClose}
                items={page.items}
                onClose={onClose}
                onShowAll={onShowAll}
                query={query}
                title={page.title}
                type="page"
                isSidebar={hasProducts}
                isPageCategoryOrStore={true}
              />
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

SearchSuggestions.propTypes = {
  addToCart: PropTypes.any,
  page: PropTypes.any,
  decreaseItemQuantity: PropTypes.any,
  increaseItemQuantity: PropTypes.any,
  isAuthenticated: PropTypes.any,
  onClose: PropTypes.any,
  onShowAll: PropTypes.any,
  products: PropTypes.any,
  recipes: PropTypes.any,
  redirectToLogin: PropTypes.any,
  resultCount: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      count: PropTypes.number
    })
  ),
  query: PropTypes.any
};

SearchSuggestions.defaultProps = {
  cateredmeal: []
};

export default SearchSuggestions;
