import * as endpoints from '../../api/endpoints/recipe';
import qs from 'query-string';
import {
  isFetchingRecipe,
  isFetchingRecipes,
  isPaginatingRecipes,
  receiveRecipe,
  receiveRecipes,
  paginateRecipes,
  fetchRecipeError,
  fetchRecipesError
} from './sync';
import { isNumber } from '../../lib/number';
import { updateHistory } from '../../lib/utils';
import { domSafe } from '../../lib/document';
import { ILoop54ItemsData, ILoop54Recipes } from '../../types/Loop54Recipes';
import { mapLoopDataToProductState } from '../../lib/loop54Data';
import { getValidParams, IValidParams } from '../../lib/getValidParams';
const whiteList = ['page', 'f.marking', 'f.cookingtime', 'Sort'];
// TODO: If filters works the same as products we should generalize this, currently in both recipes and products.
/* Kind of a toggle, if there's already an entry in the current query with the same
   value. We remove it otherwise we just add it with the rest */

export const RECIPE_FILTER_PARAMS = {
  PRICE_ONLY: 'PriceOnly'
};

const uniqueEntries = (currentFilter, val) => {
  const current = currentFilter.split(',');
  return current.includes(val)
    ? current.filter(v => v !== val)
    : [].concat(current, val);
};
/* Merges query parameters, array values are unique */
const mergeQuery = (next, { search }) => {
  const current = qs.parse(search, { arrayFormat: 'index' });
  const merged = Object.entries(next).reduce((acc, [key, val]: any) => {
    acc[key] = current[key]
      ? [...uniqueEntries(current[key], val)].join()
      : [].concat(val).join();

    return acc;
  }, {});
  return Object.assign({}, current, merged);
};

export const getRecipeById = recipeId => dispatch => {
  dispatch(isFetchingRecipe(true));

  return endpoints
    .fetchLoopRecipesByIds(recipeId)
    ?.then(({ data }) => {
      const { data: recipes } = mapLoopDataToProductState<ILoop54Recipes[]>(
        data
      );

      dispatch(receiveRecipe(recipes?.[0]));
      return data;
    })
    .catch(err => {
      dispatch(fetchRecipeError(err));

      return Promise.reject(err);
    });
};

const getRecipes = (
  endpoint,
  receiveFunc = receiveRecipes,
  isFetching = isFetchingRecipes
) => dispatch => {
  dispatch(isFetching(true));
  return endpoint()
    .then(({ data }) => {
      dispatch(receiveFunc(data));
      return data;
    })
    .catch(err => {
      dispatch(fetchRecipesError(err));

      return Promise.reject(err);
    });
};
const getLoopRecipes = (
  endpoint,
  receiveFunc = receiveRecipes,
  isFetching = isFetchingRecipes
) => dispatch => {
  dispatch(isFetching(true));
  return endpoint()
    .then(({ data }) => {
      dispatch(receiveFunc(data));
      return data;
    })
    .catch(err => {
      dispatch(fetchRecipesError(err));

      return Promise.reject(err);
    });
};

export const getRecipesByCategory = (
  categoryId: string,
  params: any,
  defaultParams: IValidParams = {}
) => dispatch => {
  const validParams = getValidParams(defaultParams);
  return dispatch(
    getLoopRecipes(
      () =>
        endpoints.fetchLoopRecipesByCategory(categoryId, {
          ...params,
          ...validParams
        }),
      (response: ILoop54ItemsData) => {
        const { data, meta } = mapLoopDataToProductState<ILoop54Recipes[]>(
          response,
          params
        );
        if (params && isNumber(params.size)) {
          meta.pageIndex;
          params.page ??
            Math.max(
              0,
              Math.floor(params.size / endpoints.DEFAULT_RECIPEPAGE_SIZE) - 1
            );
        }
        if (domSafe()) {
          dispatch(
            updateHistory(
              { ...params, page: params?.page ? Number(params.page) + 1 : 1 },
              location,
              whiteList
            )
          );
        }
        return receiveRecipes({ data, meta });
      }
    )
  );
};

export const requestMoreRecipes = (
  id,
  params,
  location: any = {},
  defaultParams: IValidParams = {}
) => dispatch => {
  const query = qs.parse(location?.search);
  const queryP = Object.assign({}, query, params);
  const page = queryP?.page ? Number(queryP?.page) + 1 : 1;
  dispatch(updateHistory({ ...queryP, page: page }, location, whiteList));
  const validParams = getValidParams(defaultParams);

  return dispatch(
    getRecipes(
      () =>
        endpoints.fetchLoopRecipesByCategory(id, {
          ...queryP,
          ...validParams,
          page: page - 1
        }),
      response => {
        const { data, meta } = mapLoopDataToProductState<ILoop54Recipes[]>(
          response,
          params
        );
        return paginateRecipes({ data, meta });
      },
      isPaginatingRecipes
    )
  );
};

export const filterRecipes = (
  id,
  filters,
  location,
  params: any = null,
  defaultParams: IValidParams = {}
) => dispatch => {
  const query = {
    ...mergeQuery(filters, location),
    page: 1
  };
  const validParams = getValidParams(defaultParams);

  return dispatch(
    getRecipesByCategory(id, { ...query, ...params, page: 0 }, validParams)
  );
};

// @TODO add sorting  for loop
export const sortRecipes = (
  id,
  params,
  location: any = {},
  defaultParams: IValidParams = {}
) => dispatch => {
  const query = qs.parse(location.search);
  const queryP = Object.assign({}, query, params);
  const validParams = getValidParams(defaultParams);

  dispatch(updateHistory({ ...queryP, page: 1 }, location, whiteList));
  return dispatch(
    getRecipesByCategory(id, { ...query, ...params, page: 0 }, validParams)
  );
};
