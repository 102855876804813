import React, { Fragment, useEffect, useRef, useState } from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SearchMetaCompletions from './SearchMetaCompletions';
import SearchSuggestions from './SearchSuggestions';
import ProductSuggestion from './ProductSuggestion';
import Suggestions from './Suggestions';
import { Button } from '../../components/Buttons';
import { CloseButton } from '../../components/Buttons';
import Clock from '../../components/assets/clock.svg';
import SearchLoader from './SearchLoader/index.tsx';
import './SearchResult.scss';
import { domSafe } from '../../lib/document';
import { fetchTopSellers } from '../../api/endpoints/product';
import { useDispatch, useSelector } from 'react-redux';
import { setNeedle, setOpenSearch } from '../../slices/searchSlice';
import {
  loopProductDefaults,
  sweetenedFiltersSelector,
  sweetenedSignalWordsSelector,
  sweetenedSymbolsSelector
} from '../../selectors/product/productSelectors';
import { loop54ClickNotifier } from '../../api/endpoints/loopNotify';

export const SearchResult = props => {
  const {
    addToCart,
    cateredmeal,
    page,
    decreaseItemQuantity,
    increaseItemQuantity,
    isAuthenticated,
    meta,
    onClose,
    onShowAll,
    product,
    query,
    recipe,
    store,
    redirectToLogin,
    searchNode,
    cateringCategoryPage,
    productCategoryPage,
    recipeCategoryPage,
    loading,
    storeId,
    notificationStatus,
    notificationHeight,
    maxHeight,
    setKeepOpen,
    category
  } = props;
  const { totalCount } = meta;
  const addToCartFn = isAuthenticated ? addToCart : redirectToLogin;
  const recentSearches =
    !query && meta.recentSearches ? meta.recentSearches : [];

  const hasResults =
    (product.items && Boolean(product.items.length)) ||
    (recipe.items && Boolean(recipe.items.length)) ||
    (page.items && Boolean(page.items.length)) ||
    (cateringCategoryPage.items &&
      Boolean(cateringCategoryPage.items.length)) ||
    (productCategoryPage.items && Boolean(productCategoryPage.items.length)) ||
    (recipeCategoryPage.items && Boolean(recipeCategoryPage.items.length));
  store?.items && Boolean(store?.items.length);

  const hasCorrections =
    !hasResults && meta.corrections && Boolean(meta.corrections.length);
  const hasSuggestions = meta.completions && Boolean(meta.completions.length);
  const sweetenedFilters = useSelector(sweetenedFiltersSelector);
  const sweetenedSignalWords = useSelector(sweetenedSignalWordsSelector);
  const sweetenedSymbols = useSelector(sweetenedSymbolsSelector);
  const handleRevisited = () => {};

  const headerHeight = domSafe() ? document.getElementById('header') : null;
  const topHeight = notificationHeight + headerHeight?.offsetHeight;
  const [topsellers, setTopSellers] = useState([]);
  const dispatch = useDispatch();
  const currentTopSellerStoreRefs = useRef(null);

  useEffect(() => {
    if (topsellers?.length && currentTopSellerStoreRefs?.current === storeId)
      return;
    if (!hasResults && !hasCorrections) {
      fetchTopSellers({
        size: 5,
        page: 0
      })
        .then(({ data }) => {
          if (data.items && data.items?.length > 0) {
            const topSellers = data?.items?.map(product =>
              loopProductDefaults(
                product,
                sweetenedFilters,
                sweetenedSignalWords,
                sweetenedSymbols
              )
            );
            setTopSellers(topSellers);
            currentTopSellerStoreRefs.current = storeId;
          }
        })
        .catch(e => {
          console.error(e);
        });
    }
  }, [hasResults, hasCorrections, storeId, currentTopSellerStoreRefs?.current]);

  useEffect(() => {
    const keydown = e => {
      if (e.keyCode === 27) {
        if (props?.needle?.length > 0) {
          dispatch(setNeedle(''));
        } else {
          dispatch(setOpenSearch(false));
        }
      }
    };
    if (props.searchIsOpen) {
      window.addEventListener('keydown', keydown);
    } else {
      window.removeEventListener('keydown', keydown);
    }
    return () => window.removeEventListener('keydown', keydown);
  }, [props.searchIsOpen, props.needle]);

  return (
    <Fragment>
      {query === '' && !hasResults ? (
        <div
          className={cs('b-searchsuggest', {
            noticeHidden: !notificationStatus
          })}
          style={{ top: topHeight, maxHeight: maxHeight }}
        >
          <Fragment>
            <div className={'b-searchsuggest__result'}>
              {topsellers.length > 0 && (
                <Suggestions
                  count={topsellers.length}
                  onShowAll={onShowAll}
                  query={query}
                  title={'Mest köpta varor i din butik'}
                  type="product"
                  onClose={onClose}
                  stopLink={true}
                >
                  {topsellers.slice(0, 5).map(item => (
                    <ProductSuggestion
                      product={item}
                      key={item.id}
                      noLazy={false}
                      setKeepOpen={setKeepOpen}
                      addToCart={() => addToCartFn(item)}
                      increaseItemQuantity={quantity =>
                        increaseItemQuantity(item.cartItemId, quantity)
                      }
                      decreaseItemQuantity={quantity =>
                        decreaseItemQuantity(item.cartItemId, quantity)
                      }
                    />
                  ))}
                </Suggestions>
              )}
            </div>
          </Fragment>
          {recentSearches?.length ? (
            <div className={'b-searchsuggest__empty'}>
              {meta && (
                <ul className={'b-searchsuggest__recent-popular'}>
                  {recentSearches.map((item, index) => (
                    <li key={item.phrase + index}>
                      <Link
                        to={`/sok?Q=${item.phrase}`}
                        onClick={() => {
                          loop54ClickNotifier(item?.id);
                          onClose();
                        }}
                      >
                        <span className={'u-icon u-icon-clock'}>
                          <img src={Clock} width="20" height="20" alt="Clock" />
                        </span>
                        <span className={'u-text u-full-height'}>
                          {item.phrase}
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ) : null}
        </div>
      ) : (
        <div
          className={cs('b-searchsuggest', {
            noticeHidden: !notificationStatus
          })}
          style={{ top: topHeight }}
        >
          <div className="closepanel u-screen-md-hide u-screen-lg-hide">
            <CloseButton onClick={onClose} />
          </div>
          {loading && <SearchLoader />}

          <div className={'b-searchsuggest__result'}>
            {hasResults || hasCorrections ? (
              <Fragment>
                {(hasCorrections || hasSuggestions) && (
                  <div className={'b-searchsuggest__completions'}>
                    {hasCorrections && meta.corrections.length > 0 && (
                      <p style={{ marginTop: '20px' }}>
                        Din sökning <b>&quot;{query}&quot;</b>, gav tyvärr inga
                        träffar
                      </p>
                    )}

                    <div className="b-searchsuggest__test">
                      {hasCorrections && meta.corrections.length > 0 && (
                        <span style={{ marginRight: '15px' }}>Menade du:</span>
                      )}
                      {hasCorrections ? (
                        <SearchMetaCompletions
                          completions={meta.corrections}
                          query={handleRevisited}
                          searchNode={searchNode}
                        />
                      ) : null}

                      {hasSuggestions ? (
                        <SearchMetaCompletions
                          completions={meta.completions}
                          query={handleRevisited}
                          searchNode={searchNode}
                        />
                      ) : null}
                    </div>
                  </div>
                )}
                <SearchSuggestions
                  addToCart={addToCart}
                  cateredmeal={cateredmeal}
                  page={page}
                  store={store}
                  category={category}
                  decreaseItemQuantity={decreaseItemQuantity}
                  increaseItemQuantity={increaseItemQuantity}
                  isAuthenticated={isAuthenticated}
                  onClose={onClose}
                  onShowAll={onShowAll}
                  products={product}
                  query={query}
                  setKeepOpen={setKeepOpen}
                  recipes={recipe}
                  redirectToLogin={redirectToLogin}
                  resultCount={
                    meta.filters?.find(f => f.name === 'Type')?.options
                  }
                  cateringCategoryPage={cateringCategoryPage}
                  productCategoryPage={productCategoryPage}
                  recipeCategoryPage={recipeCategoryPage}
                  totalCount={totalCount}
                  storeId={storeId}
                />

                {hasResults && (
                  <div
                    className="c-search-result__view-all  c-search-view-all__desktop"
                    style={{ height: topHeight }}
                  >
                    <Link
                      to={`/sok?Q=${encodeURIComponent(query)}&store=${
                        storeId ? storeId : ''
                      }&page=0`}
                    >
                      <Button
                        disabled={!totalCount > 0}
                        onClick={onClose}
                        minWidth={'170px'}
                      >
                        {' '}
                        Visa fler sökresultat
                      </Button>
                    </Link>
                  </div>
                )}
              </Fragment>
            ) : (
              <div>
                {!query?.length ||
                  (!hasResults && !hasCorrections && totalCount === 0 && (
                    <Fragment>
                      <div className="b-searchsuggest__test">
                        <p style={{ marginTop: '20px' }}>
                          Din sökning <b>&quot;{query}&quot;</b> gav tyvärr inga
                          träffar
                        </p>
                      </div>
                      {topsellers.length > 0 && (
                        <Suggestions
                          count={topsellers.length}
                          onShowAll={onShowAll}
                          query={query}
                          title={'Mest köpta varor i din butik'}
                          type="product"
                          onClose={onClose}
                          stopLink={true}
                        >
                          {topsellers.slice(0, 5).map(item => (
                            <ProductSuggestion
                              product={item}
                              key={item.id}
                              noLazy={false}
                              setKeepOpen={setKeepOpen}
                              addToCart={() => addToCartFn(item)}
                              increaseItemQuantity={quantity =>
                                increaseItemQuantity(item.cartItemId, quantity)
                              }
                              decreaseItemQuantity={quantity =>
                                decreaseItemQuantity(item.cartItemId, quantity)
                              }
                            />
                          ))}
                        </Suggestions>
                      )}
                    </Fragment>
                  ))}
              </div>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

SearchResult.propTypes = {
  addToCart: PropTypes.func,
  loading: PropTypes.bool,
  notificationStatus: PropTypes.bool,
  cateredmeal: PropTypes.shape({
    count: PropTypes.number,
    items: PropTypes.array,
    title: PropTypes.string
  }),
  cateringCategoryPage: PropTypes.shape({
    count: PropTypes.number,
    items: PropTypes.array,
    title: PropTypes.string
  }),
  clearOverlay: PropTypes.func,
  page: PropTypes.shape({
    count: PropTypes.number,
    items: PropTypes.array,
    title: PropTypes.string
  }),
  category: PropTypes.shape({
    count: PropTypes.number,
    items: PropTypes.array,
    title: PropTypes.string
  }),
  increaseItemQuantity: PropTypes.func,
  decreaseItemQuantity: PropTypes.func,
  isActive: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  isLoading: PropTypes.bool,
  location: PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.any
  }),
  meta: PropTypes.shape({
    completions: PropTypes.arrayOf(PropTypes.object),
    corrections: PropTypes.arrayOf(PropTypes.object),
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.shape({
          id: PropTypes.string,
          options: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              count: PropTypes.string
            })
          ).isRequired
        })
      })
    ),
    pageCount: PropTypes.number,
    pageIndex: PropTypes.number,
    pageSize: PropTypes.number,
    recentSearches: PropTypes.array,
    revisited: PropTypes.shape({
      emptySearchWord: PropTypes.string,
      showingResultsFor: PropTypes.string
    }),
    sort: PropTypes.any,
    totalCount: PropTypes.number
  }),
  onClose: PropTypes.func,
  onShowAll: PropTypes.any,
  product: PropTypes.shape({
    count: PropTypes.number,
    items: PropTypes.array,
    title: PropTypes.string
  }),
  productCategoryPage: PropTypes.shape({
    count: PropTypes.number,
    items: PropTypes.array,
    title: PropTypes.string
  }),
  push: PropTypes.func,
  query: PropTypes.string,
  recipe: PropTypes.shape({
    count: PropTypes.number,
    items: PropTypes.array,
    title: PropTypes.string
  }),
  recipeCategoryPage: PropTypes.shape({
    count: PropTypes.number,
    items: PropTypes.array,
    title: PropTypes.string
  }),
  redirectToLogin: PropTypes.func,
  search: PropTypes.func,
  searchNode: PropTypes.object,
  setVisibility: PropTypes.func,
  store: PropTypes.shape({
    count: PropTypes.number,
    items: PropTypes.array,
    title: PropTypes.string
  }),
  toggleOverlay: PropTypes.func,
  toggleSearch: PropTypes.func
};
