import React, { Fragment } from 'react';
import CategoryFilter from '../../components/CategoryFilter';
import ContentBlocksArea from '../../components/ContentBlocksArea';
import ErrorInfo from '../../components/ErrorInfo';
import ProductList from '../../components/ProductList';
import OverFlowContainer from '../../components/OverFlowContainer';
import { LinkablePageTitle as PageTitle } from '../../components/PageTitle';

import './ProductCategory.scss';
import { IPage } from '../../types/episerver/page';
import { IExtendedProduct } from '../../types/storefront/product';
import qs from 'query-string';

export interface IProductCategory {
  fetching: boolean;
  filters: any[];
  filterProducts: Function;
  getPageContent: Function;
  id: number;
  isAuthenticated: boolean;
  location: any;
  page: IPage;
  pageError: boolean;
  pagination: any;
  products: IExtendedProduct[];
  redirectToLogin: Function;
  removeFavorite: Function;
  requestMoreProducts: Function;
  selectedFilters: any[];
  setProductFavorite: Function;
  sortProducts: Function;
  storeId: number;
  title: any;
  sortOptions: any[];
  impressionListName: string;
  initialFetched: boolean;
  listTeaser: string;
}

const ProductCategory = ({
  storeId,
  initialFetched,
  fetching,
  products,
  selectedFilters,
  filters,
  pagination,
  setProductFavorite,
  removeFavorite,
  redirectToLogin,
  isAuthenticated,
  requestMoreProducts,
  sortProducts,
  title,
  filterProducts,
  location,
  id,
  page,
  pageError,
  getPageContent,
  sortOptions = [],
  impressionListName
}: IProductCategory) => {
  const setProductFavoriteFn = isAuthenticated
    ? setProductFavorite
    : redirectToLogin;
  const removeFavoriteFn = isAuthenticated ? removeFavorite : redirectToLogin;

  const listTeaser = {
    link: page?.teaserLink,
    image: page?.teaserImage,
    text: page?.teaserText
  };
  const requestPage = selectedPage => {
    const params = qs.parse(location.search);
    requestMoreProducts(
      id,
      {
        page: selectedPage,
        categoryName: title?.current?.name,
        SortOrder: params?.SortOrder,
        SortField: params?.SortField,
        ...page?.tagsettings
      },
      location,
      storeId
    );
  };
  return (
    <Fragment>
      <PageTitle {...title} sort />
      {pageError ? (
        <ErrorInfo
          title="Hoppsan, något gick fel!"
          text="Det går inte att hämta denna sidan just nu."
          retryButtonFn={getPageContent}
        />
      ) : (
        <OverFlowContainer>
          <ContentBlocksArea blocks={page?.contentBlocks} />
        </OverFlowContainer>
      )}
      <CategoryFilter
        id={id}
        filters={filters}
        selectedFilters={selectedFilters}
        sortProducts={(id, params) => {
          document.cookie = `sortArticles=${params.SortField};path=/`;
          const pageDefaults = page?.tagsettings ?? {};

          sortProducts(id, { ...params }, location, storeId, {
            ...pageDefaults,
            categoryName: title?.current?.name
          });
        }}
        filterAction={filter => {
          const pageDefaults = page?.tagsettings ?? {};

          return filterProducts(id, filter, location, {
            ...pageDefaults,
            categoryName: title?.current?.name
          });
        }}
        pagination={pagination}
        sortOptions={sortOptions}
      />

      <ProductList
        fetching={fetching}
        initialFetched={initialFetched}
        setProductFavorite={setProductFavoriteFn}
        removeFavorite={removeFavoriteFn}
        requestMoreProducts={requestPage}
        pagination={pagination}
        items={products}
        listTeaser={listTeaser}
        impressionListName={impressionListName}
      />
      {pageError ? null : (
        <OverFlowContainer>
          <ContentBlocksArea blocks={page?.bottomContentBlocks} />
        </OverFlowContainer>
      )}
    </Fragment>
  );
};

export default ProductCategory;
