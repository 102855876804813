import React, { useState } from 'react';
import SelectedFilters from '../SelectedFilters';
import CategoryFiltersSort from './CategoryFiltersSort';
import CategoryFiltersGroup from './CategoryFiltersGroup';
import FilterButton from '../Buttons/FilterButton';
import { filterTypes } from '../../types/categoryFilterTypes';
import { Checkbox } from '../Form/FormItem/FormItems';
import './CategoryFilter.scss';
import DropArrow from '../DropArrow';
import Select from '../Select';
import { LOOP_FACETS } from '../../types/loop54Facets';
import { sortParamsHelper } from '../../lib/sorting';

const CategoryFiltersGroupButton = ({ label, active, onClick }) => (
  <FilterButton
    onClick={onClick}
    active={active}
    className={'default'}
    buttonType={'filter'}
  >
    {label} <DropArrow small direction={active ? 'up' : 'down'} />
  </FilterButton>
);

interface ICategoryFilter {
  id?: number | string;
  filters?: any[];
  selectedFilters?: any[];
  sortProducts?: Function;
  sortOptions?: any[];
  filterAction: Function;
  pagination?: any;
  recipe?: boolean;
  filterByPrice?: Function;
  activeAssortment?: any;
}
const CategoryFilter = ({
  id,
  recipe,
  filters = [],
  selectedFilters = [],
  pagination,
  filterAction,
  sortProducts,
  sortOptions = [],
  filterByPrice,
  activeAssortment
}: ICategoryFilter) => {
  const [visibleGroups, setVisibleGroups] = useState<string[]>([]);
  const toggleGroupVisibility = id => {
    setVisibleGroups(prevState => {
      if (prevState.includes(id)) {
        return prevState.filter(item => item !== id);
      } else {
        return [id];
      }
    });
  };
  return (
    <div className="c-category-filters">
      <div
        className={`c-category-filters__top ${
          filterByPrice ? 'c-category-filters__top--customfilter' : null
        }`}
      >
        <div className="c-category-filters__filters">
          {filters?.length > 0
            ? filters?.map(({ name, options }) => {
                return options?.length > 0 && name !== LOOP_FACETS.TYPE ? (
                  <CategoryFiltersGroupButton
                    key={name}
                    onClick={() => toggleGroupVisibility(name)}
                    active={visibleGroups.includes(name)}
                    label={filterTypes[name] || name}
                  />
                ) : null;
              })
            : null}
          <div className="c-category-filters__sort--mobile">
            <Select
              onChange={value => {
                sortProducts &&
                  sortProducts(id, sortParamsHelper(value, sortOptions));
              }}
              options={sortOptions}
              defaultValue={
                sortOptions?.find(
                  sort =>
                    sort.value.includes(pagination?.sort) &&
                    sort.order === pagination?.sortOrder
                )?.value || pagination?.sort
              }
            />
          </div>
        </div>
        {filterByPrice && activeAssortment ? (
          <div className="c-category-filters__customsort">
            Visa endast köpbara recept
            <div>
              <Checkbox
                isChecked={pagination.priceOnly}
                onClick={filterByPrice}
              />
            </div>
          </div>
        ) : null}
        <div className="c-category-filters__sort">
          <CategoryFiltersSort
            id={id}
            options={sortOptions}
            sortAction={sortProducts}
            activeSort={
              sortOptions?.find(
                opt =>
                  opt.value?.includes(pagination.sort) &&
                  opt.order === pagination.sortOrder
              )?.value
            }
          />
        </div>
      </div>
      {filters?.length > 0 &&
        filters
          ?.filter(item => visibleGroups.includes(item.name))
          ?.map(item => {
            return (
              <CategoryFiltersGroup
                key={item.id}
                filterAction={filterAction}
                {...item}
                heading={filterTypes[item.name] || item.name}
                recipe={recipe}
              />
            );
          })}
      {selectedFilters.length > 0 && (
        <div className="c-category-filters__bottom">
          <SelectedFilters
            filters={selectedFilters}
            deselectFilter={filterAction}
          />
        </div>
      )}
    </div>
  );
};

export default CategoryFilter;
