import { IValidParams } from '../../lib/getValidParams';
import { removeFalsyValues } from '../../lib/removeFalseyKeyValue';
import { encode } from 'js-base64';

export const applyTagsToObjectValues = (tags): IValidParams => {
  const modifiedObj = Object.keys(tags).map(key => {
    // @TODO should be handled in the backend once we have fully migrated from esales.
    const newKey = key === 'brandfilter' ? 'brands' : `${key}`;

    if (typeof tags[key] !== 'boolean' && tags[key] !== 'GARANT') {
      return {
        [newKey]:
          typeof tags[key] == 'object'
            ? tags[key].map(t => encode(t))
            : encode(tags[key])
      };
    }
    return { [newKey]: tags[key] };
  });

  return removeFalsyValues(Object.assign({}, ...modifiedObj));
};
