import {
  FETCH_RECIPES_REQUESTED,
  FETCH_RECIPES_RECEIVED,
  FETCH_RECIPES_FAILED,
  PAGINATE_RECIPES_REQUESTED,
  PAGINATE_RECIPES_RECEIVED,
  FETCH_RECIPE_REQUESTED,
  FETCH_RECIPE_RECEIVED,
  FETCH_RECIPE_FAILED,
  QUANTITY_INCREASED,
  QUANTITY_DECREASED
} from '../../types/reducers/recipe';
import makeReducer from '../makeReducer';

const initialState = {
  fetching: false,
  filters: {},
  recipes: [],
  pagination: {
    count: 0,
    pageIndex: 1,
    pageSize: 50,
    totalCount: 0
  },
  error: null
};

const hardFetching = (state, action) => ({
  ...state,
  recipes: [],
  fetching: action.fetching
});

const fetching = (state, action) => ({
  ...state,
  fetching: action.fetching
});

const error = (state, action) => ({
  ...state,
  error: action.error,
  fetching: false
});
const ACTION_HANDLERS = {
  [FETCH_RECIPES_REQUESTED]: hardFetching,
  [FETCH_RECIPE_REQUESTED]: fetching,
  [FETCH_RECIPES_FAILED]: error,
  [FETCH_RECIPE_FAILED]: error,
  [FETCH_RECIPES_RECEIVED]: (state, action) => {
    const { filters, ...pagination } = action.payload.meta;
    return {
      ...state,
      fetching: false,
      // recipes: action.payload.recipes.map(x => ({...x, variants: null})),
      recipes: action.payload.recipes,
      filters,
      pagination: pagination
    };
  },
  [PAGINATE_RECIPES_REQUESTED]: fetching,
  [PAGINATE_RECIPES_RECEIVED]: (state, action) => {
    const { filters, ...pagination } = action.payload.meta;
    return {
      ...state,
      fetching: false,
      recipes: action.payload.recipes,
      filters,
      pagination: pagination
    };
  },
  [FETCH_RECIPE_RECEIVED]: (state, action) => {
    return {
      ...state,
      fetching: false,
      recipes: state.recipes.concat(action.payload)
    };
  },
  [QUANTITY_INCREASED]: (state, action) => {
    const products = state.recipes[action.recipeId].products.map(product => {
      return product.id === action.productId
        ? { ...product, quantity: product.quantity + action.quantity }
        : product;
    });

    return {
      ...state,
      recipes: {
        ...state.recipes,
        [action.recipeId]: {
          ...state.recipes[action.recipeId],
          products
        }
      }
    };
  },

  [QUANTITY_DECREASED]: (state, action) => {
    const products = state.recipes[action.recipeId].products.map(product => {
      return product.id === action.productId
        ? { ...product, quantity: product.quantity - action.quantity }
        : product;
    });

    return {
      ...state,
      recipes: {
        ...state.recipes,
        [action.recipeId]: {
          ...state.recipes[action.recipeId],
          products
        }
      }
    };
  }
};

export default makeReducer(ACTION_HANDLERS, initialState);
