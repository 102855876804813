import { IProductStoreDetails } from '../types/loop54Product';
import { ProductAvailability } from '../types/xim/product';

// @TODO REMOVE
export const getNextAvailabilityDateForStore = (
  availabilityArray: ProductAvailability[],
  store: number | string | null,
  compareDate: Date = new Date()
) => {
  if (!availabilityArray || availabilityArray.length === 0) {
    return null;
  }

  const availability = availabilityArray.find(
    availability => availability.storeNumber === store
  );

  if (availability) {
    const { availableFrom, stockStatus } = availability;

    const date = compareDate;
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 1);
    const availableFromDate = new Date(availableFrom);
    availableFromDate.setHours(0, 0, 0, 0);

    if (availableFromDate <= date) {
      return null;
    }

    if (availableFromDate > date || stockStatus === 2) {
      return availableFrom;
    }
    return null;
  }
  return null;
};

export const nextAvailabilityDate = (
  storeDetails: IProductStoreDetails,
  compareDate: Date = new Date()
) => {
  const availableFrom = storeDetails?.p_availablefrom;
  if (!availableFrom) {
    return null;
  }
  const date = compareDate;
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + 1);
  const availableFromDate = new Date(availableFrom);
  availableFromDate.setHours(0, 0, 0, 0);

  if (availableFromDate <= date) {
    return null;
  }
  // @TODO handle stockStatus when available
  // if (availableFromDate > date || stockStatus === 2) {
  if (availableFromDate > date) {
    return availableFrom;
  }
  return null;
};

export const hasAvailability = (storeDetails: IProductStoreDetails | null) => {
  if (!storeDetails) {
    return false;
  }
  const availableFrom = new Date(storeDetails?.p_availablefrom);
  availableFrom.setHours(0, 0, 0, 0);
  return (
    storeDetails?.p_availablefrom &&
    storeDetails?.p_availableto &&
    new Date() > availableFrom &&
    // @TODO do we need to check time on this ?
    new Date() < new Date(storeDetails?.p_availableto)
  );
};
