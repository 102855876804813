import React, { Fragment, useMemo } from 'react';
import { compose } from 'recompose';
import { withInfiniteScroll } from '../InfiniteScrollHOC';
import { ProdCard } from '../Skeletons';
import ProductCard from '../ProductCard';
import Spot from '../Spot';
import { domSafe } from '../../lib/document';
import Pagination from '../Pagination';
import { IExtendedProduct } from '../../types/storefront/product';
import CardGrid from '../CardGrid';
import './ProductList.scss';
import { generatePagination } from '../../lib/pagination/paginationHelper';

interface IProductList {
  fetching: boolean;
  setProductFavorite: Function;
  removeFavorite: Function;
  requestMoreProducts?: Function;
  pagination?: any;
  items: IExtendedProduct[];
  listTeaser?: any;
  withoutPaginationAndFilter?: boolean;
  buttonText?: string;
  skeletons?: number;
  colStructure?: string;
  impressionListName?: string;
  itemsPerPage?: number;
  isSearch?: boolean;
  initialFetched?: boolean;
}

export const ProductList = ({
  fetching,
  setProductFavorite,
  removeFavorite,
  requestMoreProducts,
  pagination,
  items,
  listTeaser,
  withoutPaginationAndFilter,
  buttonText,
  skeletons = 20,
  colStructure,
  impressionListName,
  itemsPerPage = 20,
  isSearch,
  initialFetched
}: IProductList) => {
  const { count, amountOfPagesToShow } = useMemo(() => {
    if (isSearch) {
      return generatePagination(pagination);
    }
    return {
      count: pagination?.totalCount,
      amountOfPagesToShow: pagination?.pageCount
    };
  }, [pagination, isSearch]);
  return (
    <Fragment>
      <CardGrid
        noPad
        renderItem={() => (
          <>
            {listTeaser && listTeaser.image && <Spot {...listTeaser} />}
            {(initialFetched === false || (fetching && items?.length === 0)) &&
              [...Array(skeletons)].map((e, i) => (
                <ProdCard key={i} className="card" />
              ))}
            {items &&
              items.length > 0 &&
              items.map((item, index) => {
                return (
                  <ProductCard
                    key={item.id}
                    colStructure={colStructure}
                    product={item}
                    setProductFavorite={setProductFavorite}
                    removeFavorite={removeFavorite}
                    buttonText={buttonText}
                    impressionListName={impressionListName}
                    impressionListPosition={index + 1}
                  />
                );
              })}
          </>
        )}
      />

      {items?.length > 0 && pagination && !withoutPaginationAndFilter && (
        <Pagination
          fetchPage={requestMoreProducts}
          pageCount={amountOfPagesToShow}
          totalCount={count}
          itemsPerPage={pagination?.pageSize ?? itemsPerPage}
        />
      )}
    </Fragment>
  );
};

const infiniteScrollCondition = props => {
  if (domSafe()) {
    return (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 &&
      props.items.length &&
      !props.fetching &&
      props.items.length > 20 &&
      props.pagination.totalCount > props.items.length
    );
  } else {
    return false;
  }
};

export const ProductListWithInfiniteScroll = compose(
  withInfiniteScroll(infiniteScrollCondition)
)(ProductList);

export default ProductList;
