import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CloseButton, Button } from '../Buttons';
import FlyoutBody from './FlyoutComponents/FlyoutBody';
import FlyoutFooter from './FlyoutComponents/FlyoutFooter';
import './Flyout.scss';
import { useLocation } from 'react-router-dom';

const FlyoutComp = ({
  tight = true,
  nopadding = false,
  title,
  children,
  onCloseClicked,
  hasFooter = false,
  confirmModalRef,
  show,
  match: { params }
}) => {
  const flyoutRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const isOffer = params?.hasOwnProperty('offerId');
  const tabPlaceholder = useRef(null);
  useEffect(() => {
    // @TODO temporary solution to focus flyout on open
    // @TODO need to save where the flyout was opened so when it closes focus returns to that element
    tabPlaceholder.current?.focus();
  }, []);
  return (
    <div
      ref={flyoutRef}
      className={classNames('c-panel', {
        tight,
        nopadding,
        ['k-panel']: isOffer
      })}
    >
      <div
        className={classNames('c-stdpnlcnt', {
          'has-foot': hasFooter,
          'offer-header': isOffer,
          nohead: !title
        })}
      >
        {title ? (
          <div
            className={classNames('pnlhead', {
              'pnlhead--is-scrolled': isScrolled
            })}
          >
            <div className="closepanel">
              <button className="tab-placeholder" ref={tabPlaceholder} />
              <CloseButton onClick={onCloseClicked} />
            </div>
            <h2>{title}</h2>
          </div>
        ) : (
          <div className="closepanel">
            <CloseButton onClick={onCloseClicked} />
          </div>
        )}
        <FlyoutBody isScrolled={setIsScrolled}>{children}</FlyoutBody>
        {hasFooter && (
          <FlyoutFooter>
            <Button light onClick={onCloseClicked}>
              Avbryt
            </Button>
          </FlyoutFooter>
        )}
      </div>
    </div>
  );
};

FlyoutComp.propTypes = {
  tight: PropTypes.bool,
  nopadding: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.any,
  onCloseClicked: PropTypes.func.isRequired,
  hasFooter: PropTypes.bool,
  confirmModalRef: PropTypes.any.isRequired,
  show: PropTypes.bool.isRequired
};

const Flyout = props => props.show && <FlyoutComp {...props} />;

export default Flyout;
