import React from 'react';
import { AvailabilityText } from './AvailabilityText';
import { Icons } from '@citygross/icons';
import { TextTypes } from '@citygross/typography';
import './Availability.scss';
import cs from 'classnames';

type TAvailability = {
  availability: {
    availableFrom?: string;
    availableTo?: string;
    expired?: boolean;
  };
  isCateredMeal?: boolean;
  isDetailPage?: boolean;
};

export const Availability: React.FC<TAvailability> = ({
  availability,
  isCateredMeal,
  isDetailPage = false
}) => (
  <div className={cs('c-availability', { background: isDetailPage })}>
    {availability.availableFrom ||
    availability.availableTo ||
    availability.expired ? (
      <Icons.Alert width={isDetailPage ? 14 : 12} />
    ) : null}

    <AvailabilityText
      availability={availability}
      fontSize={
        isDetailPage ? TextTypes.TextSize.EXTRASMALL : TextTypes.TextSize.TINY
      }
      isCateredMeal={isCateredMeal}
    />
  </div>
);
