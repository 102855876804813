import React, { useEffect, useState, useRef, useMemo } from 'react';
import ProductCarousel from '../../components/ProductCarousel';
import ProductCard from '../../components/ProductCard';
import { formatPrice } from '../../store/middleware/analytics';
import * as ga4 from '@citygross/analytics';
import ProductArticleSkeleton from '../../components/ProductArticleSkeleton';
import { IExtendedProduct } from '../../types/storefront/product';
import { ga4ImpressionList } from '../../lib/analytics/analytics';

type TProductCardList = {
  items: IExtendedProduct[];
  maxItems: number;
  cardFuncs?: any;
  allRows?: boolean;
  loading: boolean;
  colStructure?: string;
  impressionListName?: string;
  isCarousel?: boolean;
  children?: any;
};

const ProductCardList = ({
  items,
  maxItems,
  cardFuncs,
  allRows,
  loading,
  colStructure,
  impressionListName,
  isCarousel
}: TProductCardList) => {
  const [prods, setProds] = useState<IExtendedProduct[]>([]);
  useEffect(() => {
    setProds(allRows ? items : items?.slice(0, maxItems));
  }, [items, allRows]);
  const maxItemRef = useRef(0);

  useEffect(() => {
    if (prods.length > 0 && maxItems !== maxItemRef.current) {
      maxItemRef.current = maxItems;

      try {
        if (impressionListName) ga4ImpressionList(prods, impressionListName);
      } catch (error) {
        console.error(error);
      }
    }
  }, [prods]);

  const prodCard = (
    item: IExtendedProduct,
    index: number,
    onCarousel?: boolean
  ) => (
    <ProductCard
      onCarousel={onCarousel}
      key={item.id}
      product={item}
      colStructure={colStructure}
      setProductFavorite={(id, item) => cardFuncs.setProductFavorite(id, item)}
      removeFavorite={item => cardFuncs.removeFavorite(item)}
      onClose={() => {}}
      impressionListName={impressionListName || ''}
      impressionListPosition={index + 1}
      buttonText={'Lägg till'}
    />
  );

  const ProdCardList = useMemo(() => {
    return prods.map((item, index) => prodCard(item, index, isCarousel));
  }, [isCarousel, prods]);

  const Carousel = useMemo(() => {
    return (
      <ProductCarousel productsLength={prods.length}>
        {ProdCardList}
      </ProductCarousel>
    );
  }, [isCarousel, ProdCardList]);

  if (!items || items?.length === 0 || loading) {
    return (
      <ProductArticleSkeleton
        loading={loading}
        productsLength={items?.length}
        maxItems={maxItems}
      />
    );
  }

  return isCarousel ? <>{Carousel}</> : <>{ProdCardList}</>;
};

export default ProductCardList;
