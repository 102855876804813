import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { productFavoritesSelector } from '../../selectors/product/productSelectors';
import { fetchLoopProductById } from '../../api/endpoints/product';
import ProductList from '../../containers/ProductList';
import { selectCurrentAssortment } from '../../selectors/assortments';
import * as ga4 from '@citygross/analytics';
import { ILoop54Product } from '../../types/loop54Product';
import { hasAvailability } from '../../lib/availability';
import { ga4ImpressionList } from '../../lib/analytics/analytics';

const impressionListName = 'Favorite products';

interface IFavoriteProduct {
  createdDateTime: string;
  customer: number | string | null;
  customerId: number | null;
  favoriteType: number;
  id: number;
  itemNo: string;
}

interface IFavoriteProducts {
  favoriteProducts: IFavoriteProduct[];
  storeId: number;
}
interface IFavoriteProductsState {
  products: ILoop54Product[];
  loading: boolean;
}

class FavoriteProducts extends Component<
  IFavoriteProducts,
  IFavoriteProductsState
> {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      loading: true
    };
  }

  componentDidMount() {
    this.getFavoriteProducts();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.favoriteProducts !== this.props?.favoriteProducts) {
      this.getFavoriteProducts();
    }
  }

  getFavoriteProducts() {
    const { favoriteProducts, storeId } = this.props;
    const productIds = favoriteProducts.map(favorite => favorite.itemNo);
    if (productIds.length > 0) {
      fetchLoopProductById(productIds, null, productIds.length).then(
        ({ data }) => {
          this.setState({
            products: data?.items,
            loading: false
          });
          try {
            ga4ImpressionList(data.items, impressionListName);
          } catch (error) {
            console.error(error);
          }
        }
      );
    } else {
      this.setState({
        products: [],
        loading: false
      });
    }
  }

  render() {
    const { products, loading } = this.state;

    const availableProducts = products.filter(product =>
      // @TODO check if this works with CM
      hasAvailability(product.productStoreDetails)
    );

    return loading || (products && products.length > 0) ? (
      <ProductList
        products={availableProducts}
        skeletons={12}
        fetching={loading}
        withoutPaginationAndFilter
        impressionListName={impressionListName}
      />
    ) : (
      <div className="c-account--empty">
        <p className="mb-10">
          Du har inte valt några favoriter bland våra matvaror än.
        </p>

        <Link className="c-cmdbtn primary" to="/matvaror">
          Bläddra produkter
        </Link>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  favoriteProducts: productFavoritesSelector(state),
  storeId: selectCurrentAssortment(state)
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteProducts);
