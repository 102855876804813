import React from 'react';
import { EPriceVariant } from '@citygross/components';
import { comparisonTypes } from '@citygross/utils';
import { PriceDetail } from '../../types/xim/product';
import { BodyText, TextTypes } from '@citygross/typography';
import { theme } from '@citygross/design-tokens';
import './PriceDetails.scss';

type TPriceDetails = {
  currentPrice?: PriceDetail | null;
  displayLowestPriceLast30Days?: boolean;
  fontSize?: TextTypes.TextSize;
  hasReturnablePackageDeposit: boolean;
  lowestPriceLast30Days?: number;
  ordinaryPrice?: PriceDetail | null;
  priceVariant: EPriceVariant;
  unit?: string;
};

export const PriceDetails: React.FC<TPriceDetails> = ({
  currentPrice,
  displayLowestPriceLast30Days,
  fontSize,
  hasReturnablePackageDeposit,
  lowestPriceLast30Days,
  ordinaryPrice,
  priceVariant,
  unit
}) => {
  const comparativePrice = currentPrice?.comparativePrice;
  const comparativePriceUnit =
    currentPrice?.comparativePriceUnit &&
    comparisonTypes(currentPrice.comparativePriceUnit);

  return (
    <div className="price-details">
      <div>
        {priceVariant !== EPriceVariant.REGULAR && (
          <BodyText
            color={theme.palette?.darkest}
            fontWeight="medium"
            size={fontSize}
          >
            {`Ord pris ${ordinaryPrice?.price.toFixed(2)} kr${
              hasReturnablePackageDeposit ? unit : `/${unit}`
            }`}
          </BodyText>
        )}

        {(comparativePrice ?? 0) > 0 && (
          <BodyText color={theme.palette?.darkest} size={fontSize}>
            {`Jfr pris ${comparativePrice?.toFixed(
              2
            )} kr/${comparativePriceUnit}`}
          </BodyText>
        )}
      </div>

      {displayLowestPriceLast30Days && lowestPriceLast30Days && (
        <BodyText color={theme.palette?.darkest} size={fontSize}>
          Tidigare lägsta pris {lowestPriceLast30Days} kr/{unit}
        </BodyText>
      )}
    </div>
  );
};
