import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import Slider from 'react-slick';
import MediaQuery from 'react-responsive';
import { push } from 'connected-react-router';
import { SearchSuggestionChip } from '@citygross/components';
import './SearchMetaCompletion.scss';
import { setNeedle, setOpenSearch } from '../../../slices/searchSlice';
import { loop54ClickNotifier } from '../../../api/endpoints/loopNotify';

export const SearchMetaCompletions = ({
  completions,
  corrections,
  domNode,
  push,
  searchNode
}) => {
  const dispatch = useDispatch();
  const handleClick = phrase => {
    dispatch(setNeedle(phrase));
    const query = encodeURIComponent(phrase);
    push(`/sok?Q=${query}`);
    onClose();
  };

  const onClose = () => {
    removeClickOutsideEventListener();
    dispatch(setOpenSearch(false));
  };

  const removeClickOutsideEventListener = () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };

  const mergedCompletionsAndCorrections = [...completions, ...corrections];

  const handleClickOutside = event => {
    if (
      searchNode &&
      domNode &&
      !searchNode.contains(event.target) &&
      !domNode.contains(event.target)
    ) {
      this.onClose();
    }
  };

  const swipeSettings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slide: 'button',
    slidesToShow: 1,
    slidesToScroll: 2,
    variableWidth: true
  };
  return (
    <Fragment>
      <MediaQuery maxWidth={767}>
        <div className={'c-search-meta-completions u-pb-2'}>
          <Slider {...swipeSettings}>
            {mergedCompletionsAndCorrections.length &&
              mergedCompletionsAndCorrections.length > 0 &&
              mergedCompletionsAndCorrections.slice(0, 5).map(item => (
                <SearchSuggestionChip
                  onClick={() => {
                    handleClick(item.query);
                    loop54ClickNotifier(item?.id);
                  }}
                  key={item.query}
                  text={item.query}
                  hoverBackgroundColor={'#ffef7a'}
                  hoverBorderColor={'#ebc728'}
                  // remove colors once default is fixed in frontend.components
                />
              ))}
          </Slider>
        </div>
      </MediaQuery>
      <MediaQuery minWidth={768}>
        <div className={'c-search-meta-completions u-pb-1'}>
          <div className="chip-wrapper">
            {mergedCompletionsAndCorrections.length &&
              mergedCompletionsAndCorrections.length > 0 &&
              mergedCompletionsAndCorrections.slice(0, 5).map(item => (
                <SearchSuggestionChip
                  onClick={() => {
                    handleClick(item.query);
                    loop54ClickNotifier(item?.id);
                  }}
                  key={item.query}
                  text={item.query}
                  hoverBackgroundColor={'#ffef7a'}
                  hoverBorderColor={'#ebc728'}
                  // remove colors once default is fixed in frontend.components
                />
              ))}
          </div>
        </div>
      </MediaQuery>
    </Fragment>
  );
};

SearchMetaCompletions.propTypes = {
  clearOverlay: PropTypes.func,
  completions: PropTypes.arrayOf(PropTypes.object),
  corrections: PropTypes.arrayOf(PropTypes.object),
  domNode: PropTypes.instanceOf(PropTypes.element),
  location: PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.any
  }),
  push: PropTypes.func,
  searchNode: PropTypes.instanceOf(PropTypes.HTMLDivElement),
  toggleSearch: PropTypes.any
};

SearchMetaCompletions.defaultProps = {
  completions: [],
  corrections: []
};

function mapStateToProps(state, props) {
  return {
    domNode: props.domNode,
    location: state.router.location,
    meta: state.newSearch.meta
  };
}

const mapDispatchToProps = {
  push
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchMetaCompletions);
