import { domSafe } from '../document';
import { ImpressionType, ProductType } from 'dynamicdog-analytics';
import {
  EFromLinkPosition,
  EPreviousLinkType,
  favoriteLinkEvent,
  IImpressionItemType,
  IItemType,
  previouslyPurchasLinkEvent
} from '@citygross/analytics';
import { IExtendedProduct } from '../../types/storefront/product';
import * as ga4 from '@citygross/analytics';
import { formatPrice } from '../../store/middleware/analytics';
import { isProduct, isCateredMeal } from '../cart.js';
import { ILoop54Product } from '../../types/loop54Product';
import { IExtendedRecipe } from '../../types/Loop54Recipes';

export const IMPRESSION_NAMES = {
  CART: 'Varukorg'
};

// For pushing data directly to the dataLayer
export const sendTracking = values => {
  if (domSafe()) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(values);
  }
};

export const sendCustomGaEvent = ({ label, event }) => {
  if (domSafe()) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: label,
      ...event
    });
  }
};

export const sendSearchFieldEvent = phrase => {
  sendCustomGaEvent({
    label: 'SearchFieldChange',
    event: {
      search: {
        phrase: phrase
      }
    }
  });
};

export const generateGA4ItemsFromEecProducts = (
  eecProducts: ProductType[]
): IItemType[] => {
  return eecProducts.map(product => {
    return {
      item_id: product.id,
      item_name: product.name,
      price: product.price,
      quantity: product.quantity,
      item_brand: product.brand,
      item_category: product.category,
      item_variant: product.variant
    };
  });
};

export const generateGA4ImpressionsArrayFromEecImpressions = (
  eecImpressionTypeArray: ImpressionType[]
): IImpressionItemType[] => {
  return eecImpressionTypeArray.map(eecImpression =>
    generateSingleGA4ImpressionsItemFromEecImpression(eecImpression)
  );
};

export const generateSingleGA4ImpressionsItemFromEecImpression = (
  eecImpressionType: ImpressionType
): IImpressionItemType => {
  return {
    item_id: eecImpressionType.id,
    item_name: eecImpressionType.name,
    index: eecImpressionType.position,
    item_brand: eecImpressionType.brand,
    item_category: eecImpressionType.category,
    item_list_name: eecImpressionType.list,
    item_list_id: eecImpressionType.list
  };
};

interface IGa4ImpressionClickTracking_ProductProps {
  product: IExtendedProduct;
  impressionListPosition: number;
  impressionListName: string;
}
export const ga4ImpressionClickTracking_Recipe = ({
  impressionListName,
  impressionListPosition,
  recipe
}: {
  impressionListName: string;
  impressionListPosition: number;
  recipe: IExtendedRecipe;
}) => {
  if (impressionListName && impressionListPosition) {
    try {
      const { id, name, source, url, preview } = recipe;
      const ga4SelectItem = {
        item_list_id: impressionListName,
        item_list_name: impressionListName,
        items: [
          {
            item_id: id,
            item_name: name,
            item_brand: source || 'City Gross',
            item_category:
              '/recept' + url?.substring(0, url.lastIndexOf('/') + 1) || '',
            item_list_name: impressionListName,
            item_list_id: impressionListName,
            index: impressionListPosition,

            item_variant: id,
            price: preview?.minimumPrice || undefined,
            quantity: 1,
            discount: 0
          }
        ]
      };

      ga4.selectItem(ga4SelectItem);
    } catch (error) {
      console.error(error);
    }
  }
};
export const ga4ImpressionClickTracking_CateredMeal = ({
  title,
  url,
  selectedVariant,
  defaultVariant,
  minOrder,
  impressionListName,
  impressionListPosition
}: {
  title: string;
  url: string;
  selectedVariant: any;
  defaultVariant: any;
  minOrder: number;
  impressionListName: string;
  impressionListPosition: number;
}) => {
  if (impressionListName) {
    const productCategory =
      url?.substring(0, url?.lastIndexOf('/') + 1) || undefined;
    const isCake =
      productCategory?.includes('tartor') &&
      !productCategory?.includes('personer');

    try {
      const ga4SelectItem = {
        item_list_id: impressionListName,
        item_list_name: impressionListName,
        items: [
          {
            item_id: selectedVariant?.gtin,
            item_name: `${title} ${
              isCake
                ? `- ${selectedVariant?.quantityFrom ??
                    selectedVariant?.quantityFrom} portioner`
                : ''
            }`,
            index: impressionListPosition,
            item_brand: 'City Gross',
            item_category: url?.substring(0, url.lastIndexOf('/') + 1) || '',
            item_list_name: impressionListName,
            item_list_id: impressionListName,

            item_variant: defaultVariant,
            price: selectedVariant?.storeDetails.v_current_price,
            discount:
              selectedVariant?.storeDetails.v_current_price -
              selectedVariant?.storeDetails.v_ordinary_price,
            quantity: minOrder || 1
          }
        ]
      };
      ga4.selectItem(ga4SelectItem);
    } catch (error) {
      console.error(error);
    }
  }
};
export const ga4ImpressionClickTracking_Product = ({
  product,
  impressionListPosition,
  impressionListName
}: IGa4ImpressionClickTracking_ProductProps) => {
  try {
    const price = product?.productStoreDetails?.prices;
    const ga4SelectItem = {
      items: [
        {
          item_id: product.gtin,
          item_name: product.name,
          item_brand: product.brand || 'City Gross',
          item_category:
            product.url?.substring(0, product.url.lastIndexOf('/') + 1) || '',
          item_list_name: impressionListName,
          item_list_id: impressionListName,
          index: impressionListPosition,
          item_variant: undefined,

          price: price?.ordinaryPrice?.price || 0,
          quantity: 1,
          discount: 0
        }
      ],
      item_list_id: impressionListName,
      item_list_name: impressionListName
    };

    if (price?.hasPromotion && price?.promotions) {
      ga4SelectItem.items[0].quantity = price.promotions?.[0]?.minQuantity || 0;
      ga4SelectItem.items[0].discount = Number(
        formatPrice(
          (price?.promotions?.[0]?.minQuantity || 0) *
            price.ordinaryPrice.price -
            (price?.promotions?.[0]?.value || 0) || 0
        )
      );
    }

    ga4.selectItem(ga4SelectItem);
  } catch (error) {
    console.error(error);
  }
};

export const ga4FavoriteOrPurchaseLinkClick = (
  link,
  position: EFromLinkPosition
) => {
  if (link === '/matvaror/mina-favoritvaror') {
    favoriteLinkEvent(EPreviousLinkType.ITEM, position);
  }
  if (link === '/recept/mina-favoritrecept') {
    favoriteLinkEvent(EPreviousLinkType.RECIPE, position);
  }
  if (link === '/matvaror/mina-tidigare-kopta-varor') {
    previouslyPurchasLinkEvent(EPreviousLinkType.ITEM, position);
  }
  if (link === '/recept/mina-mest-kopta-recept') {
    previouslyPurchasLinkEvent(EPreviousLinkType.RECIPE, position);
  }
};

export const ga4OpenRegister = () => {
  ga4.sendTracking({
    event: 'begin_sign_up'
  });
};

type TRegisterTrackItem = {
  approved_or_declined: string;
  error_message?: string;
};
export const ga4VerifyPnrRegister = (approved: boolean, err?: string) => {
  const trackItem: TRegisterTrackItem = {
    approved_or_declined: approved ? 'approved' : 'declined'
  };
  if (err) {
    trackItem.error_message = err;
  }

  ga4.sendTracking({
    event: 'start_sign_up',
    ...trackItem
  });
};

export const ga4RegisterVerify = () => {
  ga4.sendTracking({
    event: 'verify_information_sign_up'
  });
};
export const ga4RegisterSignUp = (approved: boolean, err?: string) => {
  const trackItem: TRegisterTrackItem = {
    approved_or_declined: approved ? 'approved' : 'declined'
  };
  if (err) {
    trackItem.error_message = err;
  }

  ga4.sendTracking({
    event: 'sign_up',
    ...trackItem
  });
};

export const ga4FavoriteProductTracking = (
  name: string,
  id: string,
  value?: number
) => {
  ga4.sendTracking({
    event: 'product_favorite_mark',
    product_type: 'matvara',
    product_name: name,
    product_id: id,
    product_value: value ?? 0
  });
};
export const ga4FavoriteRecipeTracking = (name: string, id: string) => {
  ga4.sendTracking({
    event: 'product_favorite_mark',
    product_type: 'recept',
    product_name: name,
    product_id: id
  });
};

export const ga4CouponInput = code => {
  ga4.sendTracking({
    event: 'submit_coupon',
    coupon_input: code
  });
};

export const sendCateringItemAddTracking = (
  product: any,
  orderlines: any,
  impressionListPosition: any
) => {
  const impressionListName = 'Catering category';

  const { originalPrice, quantity, totalPrice, variant, gtin } = orderlines;
  // eslint-disable-next-line no-prototype-builtins
  const qty = quantity.quantityFrom || 1;
  const productCategory =
    product.url?.substring(0, product.url.lastIndexOf('/') + 1) || undefined;

  try {
    const isCake = productCategory.includes('tartor');
    let itemToAdd: any = {
      item_id: gtin,
      item_name:
        product.name +
        `${
          isCake
            ? ` - ${quantity?.quantityFrom ?? quantity?.quantityFrom} portioner`
            : ''
        }`,
      price: Number(formatPrice(originalPrice)),
      quantity: isCake ? 1 : qty,
      item_brand: 'City Gross',
      item_category: productCategory,
      item_variant: variant,
      discount: 0
    };
    if (impressionListPosition) {
      (itemToAdd.item_list_id = impressionListName),
        (itemToAdd.item_list_name = impressionListName),
        (itemToAdd.index = impressionListPosition);
    }

    const ga4CateringItem = {
      value: Number(formatPrice(totalPrice)),
      currency: 'SEK',
      items: [itemToAdd]
    };
    ga4.addToCart(ga4CateringItem);
  } catch (error) {
    console.error(error);
  }
};

export const generateGA4FromCartItems = cart => {
  const items = cart?.items
    ?.filter(item => isProduct(item) && !isCateredMeal(item))
    ?.map(item => {
      const ga4GroceryItem = {
        item_id: item.gtin,
        item_name: item.name,
        price: Number(formatPrice(item.price?.gross || item.normalPrice)),
        quantity: item.quantity.value,
        item_brand: item.brand || item.product?.brand || 'City Gross',
        item_category:
          item.product?.url?.substring(
            0,
            item.product.url.lastIndexOf('/') + 1
          ) || '',
        discount: item.totalDiscountAmount || 0,
        item_variant: item.variant || null
      };

      return ga4GroceryItem;
    });

  const recipes = cart?.recipes?.map(recipe => {
    return {
      item_id: recipe.recipeId,
      item_name: recipe.name,
      price: 0,
      quantity: 1,
      discount: 0,
      item_brand: recipe.recipe?.source || 'City Gross',
      item_category:
        '/recept' +
          recipe.recipe?.url?.substring(
            0,
            recipe.recipe.url.lastIndexOf('/') + 1
          ) || '/',
      item_variant: recipe.variantId
    };
  });

  const cateredMeals =
    cart?.cateredMeals?.map(cateredMeal => {
      const productCategory =
        cateredMeal?.cateredMeal?.url?.substring(
          0,
          cateredMeal?.cateredMeal?.url?.lastIndexOf('/') + 1
        ) || undefined;
      const isCake =
        productCategory?.includes('tartor') &&
        !productCategory?.includes('personer');
      return {
        item_id: cateredMeal.gtin,
        item_name: `${cateredMeal.name} ${
          isCake
            ? `- ${cateredMeal?.cateredMeal?.quantity?.quantityFrom ??
                cateredMeal?.cateredMeal?.quantityFrom} portioner`
            : ''
        }`,
        price: cateredMeal?.netPrice,
        quantity: cateredMeal?.quantity?.value,
        discount: 0,
        item_brand: 'City Gross',
        item_category:
          cateredMeal?.cateredMeal?.url?.substring(
            0,
            cateredMeal?.cateredMeal?.url?.lastIndexOf('/') + 1
          ) || '/',
        item_variant: cateredMeal?.cateredMeal?.name
      };
    }) || [];
  return [...items, ...recipes, ...cateredMeals];
};

export const ga4ImpressionList = (
  items: ILoop54Product[],
  impressionListName: string
) => {
  const ga4ImpressionItems = items.map((prod, index) => {
    let unitPrice =
      prod?.productStoreDetails?.prices?.ordinaryPrice?.price || 0;
    let discount = 0;
    let quantity = 1;

    const priceInfo = prod.productStoreDetails?.prices;

    if (priceInfo?.hasPromotion) {
      const promo = priceInfo?.promotions && priceInfo?.promotions?.[0];
      quantity = promo?.minQuantity || 1;
      discount =
        quantity > 1
          ? unitPrice * quantity - (promo?.value || 0)
          : unitPrice - (promo?.value || 0);
    }

    return {
      item_id: prod.gtin,
      item_name: prod.name,
      item_brand: prod.brand || 'City Gross',
      item_category:
        prod.url?.substring(0, prod.url.lastIndexOf('/') + 1) || undefined,
      item_list_name: impressionListName,
      item_list_id: impressionListName,
      index: index + 1,

      price: Number(formatPrice(unitPrice)),
      discount: Number(formatPrice(discount)),
      quantity: quantity
    };
  });

  ga4.viewItemList({
    // @ts-ignore
    items: ga4ImpressionItems,
    item_list_id: impressionListName,
    item_list_name: impressionListName
  });
};
