import React from 'react';
import { Box } from '@citygross/components';
import { BodyText, TextTypes } from '@citygross/typography';
import { theme } from '@citygross/design-tokens';
import { PromotionDates } from './PromotionDates';
import { ILoop54Promotion } from '../../../types/loop54Product';

type TPromotionConditionsBox = {
  promotion: ILoop54Promotion;
  unit: string;
};

export const PromotionConditionsBox: React.FC<TPromotionConditionsBox> = ({
  promotion,
  unit
}) => (
  <Box
    background={theme.palette?.blueLighter}
    padding={16}
    paddingVertical={12}
  >
    {!!promotion?.maxAppliedPerReceipt && (
      <BodyText color={theme.palette?.darkest} size={TextTypes.TextSize.SMALL}>
        {`Max ${promotion.maxAppliedPerReceipt} ${
          unit === 'kg' ? 'kg' : 'köp'
        } per kund`}
      </BodyText>
    )}

    {promotion?.minAmount && (
      <BodyText color={theme.palette?.darkest} size={TextTypes.TextSize.SMALL}>
        Handla andra varor för minst {promotion.minAmount}kr
      </BodyText>
    )}

    <PromotionDates promotion={promotion} />
  </Box>
);
