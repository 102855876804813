import React from 'react';
import { Icons } from '@citygross/icons';
import { BodyText, TextTypes } from '@citygross/typography';
import './WarningLabel.scss';

export type TWarningLabel = {
  isDetailPage?: boolean;
  label?: string;
};

export const WarningLabel = ({
  isDetailPage = false,
  label
}: TWarningLabel) => {
  return (
    <div className="limitedStock">
      <Icons.Alert width={isDetailPage ? 14 : 12} />

      <BodyText
        size={
          isDetailPage ? TextTypes.TextSize.EXTRASMALL : TextTypes.TextSize.TINY
        }
      >
        {label}
      </BodyText>

      <div className="limitedStockTooltip">
        Vara är begränsad och kontroll av tillgänglighet görs i kassan
      </div>
    </div>
  );
};
