import {
  FETCH_RECIPE_REQUESTED,
  FETCH_RECIPE_RECEIVED,
  FETCH_RECIPE_FAILED,
  FETCH_RECIPES_REQUESTED,
  FETCH_RECIPES_RECEIVED,
  FETCH_RECIPES_FAILED,
  PAGINATE_RECIPES_REQUESTED,
  PAGINATE_RECIPES_RECEIVED,
  QUANTITY_INCREASED,
  QUANTITY_DECREASED
} from '../../types/reducers/recipe';

export function isFetchingRecipe(fetching) {
  return {
    type: FETCH_RECIPE_REQUESTED,
    fetching
  };
}

export function receiveRecipe(payload) {
  return {
    type: FETCH_RECIPE_RECEIVED,
    payload: payload
  };
}

export function fetchRecipeError(error) {
  return {
    type: FETCH_RECIPE_FAILED,
    error
  };
}

export function isFetchingRecipes(fetching) {
  return {
    type: FETCH_RECIPES_REQUESTED,
    fetching
  };
}

export function receiveRecipes(payload) {
  return {
    type: FETCH_RECIPES_RECEIVED,
    payload: {
      recipes: payload.data,
      meta: payload.meta
    }
  };
}

export function fetchRecipesError(error) {
  return {
    type: FETCH_RECIPES_FAILED,
    error
  };
}

export function isPaginatingRecipes(fetching) {
  return {
    type: PAGINATE_RECIPES_REQUESTED,
    fetching
  };
}
export function paginateRecipes(payload) {
  return {
    type: PAGINATE_RECIPES_RECEIVED,
    payload: {
      recipes: payload.data,
      meta: payload.meta
    }
  };
}

export function increaseRecipeProductQuantity(
  recipeId,
  productId,
  quantity = 1
) {
  return {
    type: QUANTITY_INCREASED,
    recipeId,
    productId,
    quantity
  };
}

export function decreaseRecipeProductQuantity(
  recipeId,
  productId,
  quantity = 1
) {
  return {
    type: QUANTITY_DECREASED,
    recipeId,
    productId,
    quantity
  };
}
