import { EListItemAlignment, ListItem } from '@citygross/components';
import React from 'react';
import { IExtendedProduct } from '../../types/storefront/product';
import { setImage } from '../../lib/product';
import BuyButton from '../BuyButton';
import { getOfferLowestPrice } from '../../lib/offerInfo';
import ProductPlaceholder from '../../components/assets/produkt-fallback.svg';
import './OfferProduct.scss';
type TOfferProduct = {
  product: IExtendedProduct;
  displayLowestPriceLast30Days?: boolean;
};

export const OfferProduct = ({
  product,
  displayLowestPriceLast30Days
}: TOfferProduct) => {
  const lowestPrice =
    displayLowestPriceLast30Days && getOfferLowestPrice(product);

  const isAvailable = product.productStoreDetails?.p_has_price;

  return (
    <ListItem
      alignment={EListItemAlignment.TOP}
      fallBackImage={ProductPlaceholder}
      item={{
        name: product.name,
        image: setImage(product?.images, 0)?.url + '?w=56',
        description: product?.descriptiveSize || '',
        secondaryDescription: lowestPrice && (
          <p className="text-tiny">Tidigare lägsta pris. {lowestPrice}</p>
        )
      }}
    >
      {isAvailable ? (
        <div className="button">
          <BuyButton product={product} fullWidth />
        </div>
      ) : (
        <span className="not-available">Ej tillgänglig online</span>
      )}
    </ListItem>
  );
};
