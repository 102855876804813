import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import withBaseBlock from '../../components/BaseBlock';
import {
  activeStoreNoSelector,
  selectCurrentAssortment
} from '../../selectors/assortments';
import {
  loopProductDefaults,
  sweetenedFiltersSelector,
  sweetenedSignalWordsSelector,
  sweetenedSymbolsSelector
} from '../../selectors/product/productSelectors';
import { authenticatedSelector } from '../../selectors/user';
import { getCategoryPageByUrl } from '../../selectors/routing/routingSelector.js';
import { pageLayoutSelector } from '../../selectors/page';
import qs from 'query-string';
import {
  fetchLoopProductById,
  fetchLoopProductsByCategory,
  fetchProductsByFilterQuery
} from '../../api/endpoints/product';
import { mapLoopDataToProductState } from '../../lib/loop54Data';
import { getValidParams } from '../../lib/getValidParams';
import { removeFavorite, setProductFavorite } from '../../actions/auth';
import {
  addToCart,
  increaseItemQuantity,
  decreaseItemQuantity
} from '../../actions/cart';
import { redirectToLogin } from '../../actions/app';
import CardGrid from '../../components/CardGrid/CardGrid';
import ProductCardList from '../../modules/ProductCardList';
import { IExtendedProduct } from '../../types/storefront/product';
import { LoadMoreToolbar } from '../../components/LoadMoreToolbar';
import { TProductBlockPagination } from '../../types/pagination';
import { TProductArticleBlock } from './ProductArticleBlock.types';
import { applyTagsToObjectValues } from './ProductArticleBlock.utils';
import { ILoop54ProductsData } from '../../types/loop54Product';

const ProductArticleBlock = ({ ...props }: TProductArticleBlock) => {
  const [fetchedProducts, setFetchedProducts] = useState<IExtendedProduct[]>(
    []
  );
  const [pagination, setPagination] = useState<TProductBlockPagination | null>(
    null
  );
  const [allRows, setAllRows] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [fetchingFailed, setFetchingFailed] = useState(false);
  const [currentStoreId, setCurrentStoreId] = useState<number | null>(null);
  const [initialLoad, setInitialLoad] = useState(false);

  useEffect(() => {
    if (!initialLoad || (currentStoreId !== props.storeId && !loading)) {
      fetchProducts();
      setInitialLoad(true);
    }
  }, [currentStoreId, loading, initialLoad, props.storeId]);

  const extendProducts = items => {
    const { sweetenedFilters, sweetenedSignalWords, sweetenedSymbols } = props;
    return items?.items?.map(item => {
      return loopProductDefaults(
        item,
        sweetenedFilters,
        sweetenedSignalWords,
        sweetenedSymbols
      );
    });
  };

  const handleLoadMoreProducts = data => {
    const { data: extendedData, meta } = mapLoopDataToProductState<
      IExtendedProduct[]
    >(data);
    setFetchedProducts([...fetchedProducts, ...extendedData]);
    setPagination(meta);
    setLoading(false);
  };

  const loadMoreProducts = () => {
    if (!allRows) {
      return setAllRows(true);
    }
    setLoading(true);
    const { tags } = props;
    // add pagination to queryString
    const modifiedTags = qs.stringify(applyTagsToObjectValues(tags));
    const newPageIndex = pagination?.pageIndex ? pagination?.pageIndex + 1 : 1;

    if (
      props?.previewFromCategoryPage &&
      props.link?.categoryPageId &&
      props.previewCategoryPage?.name
    ) {
      const modifiedTags = getValidParams(applyTagsToObjectValues(tags));
      fetchLoopProductsByCategory(
        props.link?.categoryPageId,
        {
          store: props.storeId,
          categoryName: props.previewCategoryPage?.name,
          page: newPageIndex
        },
        modifiedTags
      ).then(data => {
        handleLoadMoreProducts(data?.data);
      });
    } else {
      fetchProductsByFilterQuery(modifiedTags, newPageIndex).then(
        ({ data }) => {
          handleLoadMoreProducts(data);
        }
      );
    }
  };

  const handleFetchedProducts = (
    data: ILoop54ProductsData,
    withPagination = true
  ) => {
    const { storeId } = props;

    if (!data?.items?.length) {
      return setFetchingFailed(true);
    }
    const fetchedProducts = extendProducts(data);

    setFetchedProducts(fetchedProducts);
    setFetchingFailed(false);
    setCurrentStoreId(storeId);
    setLoading(false);
    if (withPagination) {
      setPagination({
        totalCount: data.totalCount,
        count: data.pageSize,
        pageIndex: data.currentPage
      });
    }
  };
  const fetchProducts = () => {
    setLoading(true);
    const { tags, ximEntitiesList } = props;
    if (
      props?.previewFromCategoryPage &&
      props.link?.categoryPageId &&
      props.previewCategoryPage?.name
    ) {
      const modifiedTags = getValidParams(applyTagsToObjectValues(tags));
      fetchLoopProductsByCategory(
        props.link?.categoryPageId,
        {
          store: props.storeId,
          categoryName: props.previewCategoryPage?.name
        },
        modifiedTags
      )
        .then(data => {
          handleFetchedProducts(data?.data);
        })
        .catch(e => {
          setFetchingFailed(true);
        });
    } else if (ximEntitiesList && ximEntitiesList.length > 0) {
      fetchLoopProductById(ximEntitiesList)
        .then(data => {
          handleFetchedProducts(data.data, false);
        })
        .catch(e => {
          setFetchingFailed(true);
        });
    } else {
      const modifiedTags = qs.stringify(applyTagsToObjectValues(tags));

      fetchProductsByFilterQuery(modifiedTags)
        .then(({ data }) => {
          handleFetchedProducts(data);
        })
        .catch(e => {
          setFetchingFailed(true);
        });
    }
  };
  const {
    ximEntitiesList,
    paginationEnabled,
    fontColor,
    isCarousel,
    mappedRedirectToLogin,
    mappedAddToCart,
    mappedIncreaseItemQuantity,
    mappedDecreaseItemQuantity,
    mappedSetProductFavorite,
    mappedRemoveFavorite,
    isAuthenticated,
    numRows = 2,
    ...rest
  } = props;
  if (fetchingFailed) {
    return null;
  }
  const cardFuncs = {
    addToCart: mappedAddToCart,
    increaseItemQuantity: mappedIncreaseItemQuantity,
    decreaseItemQuantity: mappedDecreaseItemQuantity,
    setProductFavorite: isAuthenticated
      ? mappedSetProductFavorite
      : mappedRedirectToLogin,
    removeFavorite: isAuthenticated
      ? mappedRemoveFavorite
      : mappedRedirectToLogin
  };

  return (
    <div>
      <CardGrid
        noPad
        fontColor={fontColor}
        isCarousel={isCarousel}
        {...rest}
        renderItem={maxItems => (
          <ProductCardList
            items={fetchedProducts || []}
            maxItems={numRows * maxItems}
            cardFuncs={cardFuncs}
            allRows={allRows}
            loading={loading}
            impressionListName={props.name}
            isCarousel={isCarousel}
          />
        )}
      />
      {paginationEnabled &&
        !ximEntitiesList &&
        fetchedProducts?.length &&
        !isCarousel && (
          <div className="load-more-container">
            <LoadMoreToolbar
              hideArticleCount={allRows}
              totalCount={pagination?.totalCount || 0}
              count={fetchedProducts?.length}
              requestMore={loadMoreProducts}
              fetching={loading}
            />
          </div>
        )}
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    storeId: selectCurrentAssortment(state),
    pageLayout: pageLayoutSelector(state, props),
    store: activeStoreNoSelector(state),
    sweetenedFilters: sweetenedFiltersSelector(state),
    sweetenedSignalWords: sweetenedSignalWordsSelector(state),
    sweetenedSymbols: sweetenedSymbolsSelector(state),
    isAuthenticated: authenticatedSelector(state),
    previewCategoryPage:
      props?.previewFromCategoryPage && props?.link?.url
        ? getCategoryPageByUrl(state, props?.link?.url)
        : null
  };
};
const mapDispatchToProps = {
  mappedIncreaseItemQuantity: increaseItemQuantity,
  mappedDecreaseItemQuantity: decreaseItemQuantity,
  mappedRedirectToLogin: redirectToLogin,
  mappedAddToCart: addToCart,
  mappedSetProductFavorite: setProductFavorite,
  mappedRemoveFavorite: removeFavorite
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withBaseBlock(ProductArticleBlock));
