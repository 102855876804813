import { Marking } from '../../types/episerver/marking';
import {
  TSearchFilter,
  TSearchFilterOption
} from '../../types/search/searchResult';

export const markingFilterData = (
  appProductMarkings: Marking[],
  filters: TSearchFilter
) => {
  const mergedFilters: TSearchFilterOption[] = appProductMarkings?.reduce(
    (list: TSearchFilterOption[], sweetFilter) => {
      const match = filters?.options?.find(
        option => sweetFilter.code === option.option
      );
      if (match) {
        list.push({
          ...match,
          niceName: sweetFilter.name
        });
      }
      return list;
    },
    []
  );

  return mergedFilters;
};
