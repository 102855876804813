import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { authenticatedSelector } from '../../selectors/user';
import LoadBlock from '../../components/LoadingBlock';
import { selectCurrentAssortment } from '../../selectors/assortments';
import {
  getSingleOffer,
  selectODP,
  selectODPFetching
} from '../../slices/offersSlice';
import { OfferSingle } from '../../modules/OfferSingle/OfferSingle';
import { TOffer } from '../../types/offers/offers';

type TOfferSinglePage = {
  id: string;
  isFetching: boolean;
  isFlyout: boolean;
  offer: TOffer;
};

const OfferSinglePage = ({
  id,
  isFetching,
  isFlyout,
  offer
}: TOfferSinglePage) => {
  const dispatch = useDispatch();
  const storeId = useSelector(selectCurrentAssortment);

  useEffect(() => {
    if (id && !isFetching) {
      dispatch(getSingleOffer({ offerId: id, ignoreStore: true }));
    }
  }, [id, storeId]);

  return offer && !isFetching ? (
    <OfferSingle isFlyout={isFlyout} offer={offer} />
  ) : (
    <LoadBlock height={40} />
  );
};

const mapStateToProps = (state, props) => {
  const offerId = props.match.params.offerId;

  return {
    id: offerId,
    storeId: selectCurrentAssortment(state),
    isFetching: selectODPFetching(state),
    isAuthenticated: authenticatedSelector(state),
    offer: selectODP(state)
  };
};

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OfferSinglePage)
);
