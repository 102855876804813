import React from 'react';
import Image from '../../../components/Image';
import { H2 } from '@citygross/typography';
import { theme } from '@citygross/design-tokens';
import { IMappedSafetyDataSheetInformation } from '../../../types/storefront/product';
import './ProductHazardContainer.scss';

type TProductHazardContainer = {
  mappedSafetyDataSheetInformation: IMappedSafetyDataSheetInformation;
};

export const ProductHazardContainer: React.FC<TProductHazardContainer> = ({
  mappedSafetyDataSheetInformation
}) => {
  return (
    <>
      {mappedSafetyDataSheetInformation.highlightedInformation && (
        <div className="hazard">
          {mappedSafetyDataSheetInformation.highlightedInformation
            .filter(
              x => x.qualifier === 'http://www.unece.org/GHS/SymbolDescription'
            )
            .map(({ image, code }) => (
              <div key={code}>
                {image?.url && (
                  <Image
                    src={image.url}
                    alt={image?.altText}
                    width={25}
                    height={25}
                    noLazy
                    className="c-productdetails__hazardicon--img"
                  />
                )}
              </div>
            ))}

          {mappedSafetyDataSheetInformation.highlightedInformation
            .filter(x => x.qualifier === 'http://www.unece.org/GHS/SignalWord')
            .map(({ description, code }) => (
              <span key={code} className="signal-word">
                {description}
              </span>
            ))}
        </div>
      )}

      <H2 color={theme.palette?.darkest}>Skyddsangivelse</H2>

      {mappedSafetyDataSheetInformation.hazardCodes?.map(
        ({ code, description }) => (
          <div className="hazard" key={code}>
            <span>{description}</span>
          </div>
        )
      )}
    </>
  );
};
