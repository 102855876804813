import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { LazyProgressivePicture, ImageFitTypes } from '../ProgressivePicture';
import { TOffer } from '../../types/offers/offers';
import './OfferCard.scss';
import { Icons } from '@citygross/icons';
import {
  CgButton,
  ECgButtonSize,
  EPriceVariant,
  EProductHeader,
  PriceStripe,
  PriceTag,
  ProductHeader
} from '@citygross/components';
import { BodyText, TextTypes } from '@citygross/typography';
import OfferPriceDetails from '../OfferPriceDetails';
import { comparisonTypes } from '@citygross/utils';
import { getOfferVariant } from '../../lib/offerInfo';
import ProductPlaceholder from '../assets/produkt-fallback.svg';
import cs from 'classnames';

type TOfferCard = {
  colStructure?: string;
  offer: TOffer;
  onCarousel?: boolean;
};

export const OfferCard = ({ colStructure, offer, onCarousel }: TOfferCard) => {
  const router = useHistory();
  const { pathname } = useLocation();

  const linkTo = {
    // TODO: revisit this path when we have a proper url from api. also revisit ROUTE_OFFER_PATTERN in lib/route/defaults.ts
    pathname: `${pathname}/-k${offer.id}`,
    state: { flyout: true, title: 'Erbjudande', nopadding: true, tight: false }
  };

  const offerVariant = getOfferVariant(offer);
  const multiPrice = offer.products?.[0]?.minQuantity;

  const subTitleArray = [offer.brand, offer.weightVolume].filter(Boolean);

  return (
    <div
      className={cs(colStructure, {
        'p-carousel_card': onCarousel
      })}
    >
      <Link className="offer-card-container" draggable={false} to={linkTo}>
        <div className="offer-card-upper">
          <BodyText size={TextTypes.TextSize.TINY} textAlign="right">
            Villkor gäller
          </BodyText>

          <div className="image-container">
            <div className="image">
              <LazyProgressivePicture
                largeImage={{
                  url: offer.imageUrl,
                  width: 164,
                  height: 164
                }}
                fitType={ImageFitTypes.Contain}
                inheritHeight
                maxWidth={{
                  large: 164,
                  small: 164
                }}
                placeholder={{
                  image: ProductPlaceholder,
                  bgColor: 'transparent'
                }}
              />
            </div>

            {offerVariant === EPriceVariant.PRIO && (multiPrice ?? 0) > 1 && (
              <div className="price-stripe-container">
                <PriceStripe variant={EPriceVariant.PRIO} />
              </div>
            )}
          </div>

          <div className="price-tag-container">
            <PriceTag
              multiPrice={multiPrice}
              pant={offer.requiresReturnablePackageDeposit}
              price={offer.price}
              variant={offerVariant}
              unit={comparisonTypes(offer.products?.[0]?.priceDetails?.unit)}
            />
          </div>

          <ProductHeader
            location={EProductHeader.CARD}
            title={offer.name}
            subTitle={subTitleArray.join(', ')}
          />
        </div>

        <div className="offer-card-content">
          <OfferPriceDetails
            comparisonPrice={offer.comparisonPrice}
            fontSize={TextTypes.TextSize.TINY}
            pant={offer.requiresReturnablePackageDeposit}
            regularRetailPrice={offer.regularRetailPrice}
            save={offer.save}
            textAlign="center"
          />

          <CgButton
            flexReverse
            fullWidth
            icon={<Icons.ChevronRight width={18} height={18} />}
            onClick={() => {
              router.push(linkTo);
            }}
            size={ECgButtonSize.small}
            variant={1}
          >
            Välj
          </CgButton>
        </div>
      </Link>
    </div>
  );
};

OfferCard.defaultProps = {
  colStructure: 'l-column-30_xs-30_sm-20_md-15_lg-12_xlg-10-mobileGutter'
};
