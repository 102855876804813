import {
  FETCH_CATEGORY_PRODUCTS_REQUESTED,
  FETCH_CATEGORY_PRODUCTS_RECEIVED,
  FETCH_CATEGORY_PRODUCTS_FAILED,
  PAGINATE_CATEGORY_PRODUCTS_REQUESTED,
  PAGINATE_CATEGORY_PRODUCTS_RECEIVED,
  FETCH_PRODUCT_REQUESTED,
  FETCH_PRODUCT_RECEIVED,
  FETCH_PRODUCT_FAILED,
  SORT_PRODUCTS,
  ProductState
} from '../../types/reducers/product';

const initialState: ProductState = {
  fetching: false,
  sortOrder: 'relevance',
  filters: [],
  category: [],
  products: [],
  pagination: {
    count: 0,
    pageIndex: 1,
    pageSize: 50,
    totalCount: 0
  },
  error: null
};

const hardFetching = (state: ProductState, action: any) => ({
  ...state,
  category: [],
  fetching: action.fetching
});

const fetching = (state: ProductState, action: any) => ({
  ...state,
  fetching: action.fetching
});

const error = (state: ProductState, action: any) => ({
  ...state,
  error: action.error,
  fetching: false
});

export default function productReducer(
  state = initialState,
  // TODO: This should NOT be any, make a union of the actiontypes
  // Reason of any currently is because our ts-linter isn't working correctly.
  action: any //AppActionTypes
): ProductState {
  switch (action.type) {
    case FETCH_CATEGORY_PRODUCTS_REQUESTED: {
      return hardFetching(state, action);
    }
    case FETCH_PRODUCT_REQUESTED: {
      return fetching(state, action);
    }
    case [FETCH_CATEGORY_PRODUCTS_FAILED, FETCH_PRODUCT_FAILED]: {
      return error(state, action);
    }
    case PAGINATE_CATEGORY_PRODUCTS_REQUESTED: {
      return fetching(state, action);
    }
    case FETCH_CATEGORY_PRODUCTS_RECEIVED: {
      const { filters, ...pagination } = action.payload.meta;
      return {
        ...state,
        fetching: false,
        error: null,
        category: action.payload.products,
        filters,
        pagination: pagination
      };
    }
    case PAGINATE_CATEGORY_PRODUCTS_RECEIVED: {
      const { filters, ...pagination } = action.payload.meta;
      return {
        ...state,
        fetching: false,
        error: null,
        category: action.payload.products,
        filters,
        pagination: pagination
      };
    }

    case FETCH_PRODUCT_RECEIVED: {
      return {
        ...state,
        error: null,
        fetching: false,
        products: [...action.payload, ...state.products]
      };
    }

    case SORT_PRODUCTS: {
      return {
        ...state,
        sortOrder: action.on
      };
    }

    default: {
      return state;
    }
  }
}
