import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { SearchResult } from '../SearchResult.js';
import './QuickSearch.scss';
import { useWindowSize } from '@citygross/utils';
import { HEADER_BREAKPOINT } from '../../../components/Header/Header.js';
import { selectNewSearchResult } from '../../../slices/searchSlice';
import { authenticatedSelector } from '../../../selectors/user';

type TNewSearch = {
  needle?: string;
  loading?: boolean;
  notificationStatus: boolean;
  notificationHeight: number;
  searchIsOpen?: boolean;
  storeId?: string;
  closeSearch?: () => void;
  setKeepOpen?: () => void;
  rounded?: boolean;
  searchInputMobileRef?: React.RefObject<HTMLInputElement>;
};

const SearchInputHeight = 61;

const QuickSearch = ({
  loading,
  needle,
  notificationHeight,
  notificationStatus,
  closeSearch,
  setKeepOpen,
  searchIsOpen,
  searchInputMobileRef,
  ...props
}: TNewSearch) => {
  const newSearchResult = useSelector(selectNewSearchResult);

  const { width } = useWindowSize();
  const [maxHeight, setMaxHeight] = useState<string | number>('100%');
  const ref = useRef<HTMLDivElement>(null);
  const removeFocus = () => {
    searchInputMobileRef && searchInputMobileRef?.current?.blur();
  };
  const listenToScrollEvents = el => {
    el.addEventListener('wheel', removeFocus, false);
    el.addEventListener('touchmove', removeFocus, false);
  };

  const stopListeningToScrollEvents = el => {
    el.removeEventListener('wheel', removeFocus, false);
    el.removeEventListener('touchmove', removeFocus, false);
  };
  useEffect(() => {
    if (!window.visualViewport?.height) return;

    setMaxHeight(window.visualViewport?.height);

    const checkVisualViewPort = () => {
      if (!window.visualViewport?.height) return;

      setMaxHeight(window.visualViewport?.height - SearchInputHeight + 'px');
    };
    visualViewport?.addEventListener('resize', checkVisualViewPort);
    listenToScrollEvents(window);

    return () => {
      stopListeningToScrollEvents(window);
      visualViewport?.removeEventListener('resize', checkVisualViewPort);
    };
  }, []);

  return (
    <div className={'search-result-overlay__container'}>
      <div
        className="search-result__wrapper"
        style={{
          maxHeight: width > HEADER_BREAKPOINT ? '100%' : maxHeight
        }}
        ref={ref}
      >
        <div className="search-result__container">
          <SearchResult
            {...props}
            {...newSearchResult}
            query={needle}
            loading={loading}
            notificationStatus={notificationStatus}
            notificationHeight={notificationHeight}
            maxHeight={width > HEADER_BREAKPOINT ? '100%' : maxHeight}
            setKeepOpen={setKeepOpen}
            onClose={() => {
              closeSearch && closeSearch();
            }}
          />
        </div>
      </div>
      {searchIsOpen && <div className={'search-mobile__overlay'} />}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    location: state.router.location,
    isActive: state.app.search.isToggled,
    isAuthenticated: authenticatedSelector(state)
  };
}
export default connect(mapStateToProps)(QuickSearch);
