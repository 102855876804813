import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import * as endpoints from '../api/endpoints/search';
import { makeRecipeEnhancerSelector } from '../selectors/recipe/recipeSelectors.js';
import { getCartItems } from '../selectors/cart/cartSelectorNew';
import { makeProductEnhancerSelectorLoop } from '../selectors/product/productSelectors';
import { productCombination } from '../selectors/cart/cartItemsCombiners';
import { Location } from 'history';
import qs from 'query-string';
import { TSearchResultItems, TSearchState } from '../types/search/searchResult';
import {
  IExtendedILoop54QuickSearchResult,
  IExtendedLoop54SearchResult
} from '../types/Loop54Search';
import {
  DEFAULT_SEARCH_TYPE,
  LOOP_SEARCH_QUERY_PARAM,
  mapSearchResponseToState,
  SEARCH_TYPES,
  searchQueryHelper
} from '../lib/searchSlice';
import { filterFacetCombiner } from '../lib/filterFacetCombiner';
import { LOOP_FACETS } from '../types/loop54Facets';

const initialResultSearchResult: TSearchResultItems = {
  product: {
    title: 'Matvaror',
    items: [],
    count: 0
  },
  productCategoryPage: {
    title: 'MatvarorCategoryPage',
    items: [],
    count: 0
  },
  recipe: {
    title: 'Recept',
    items: [],
    count: 0
  },
  recipeCategoryPage: {
    title: 'ReceptCategoryPage',
    items: [],
    count: 0
  },
  page: {
    title: 'Sidor',
    items: [],
    count: 0
  },
  cateredmeal: {
    title: 'Catering',
    items: [],
    count: 0
  },
  cateringCategoryPage: {
    title: 'cateringCategoryPage',
    items: [],
    count: 0
  },
  store: {
    title: 'Butiker',
    items: [],
    count: 0
  }
};
const initialState: TSearchState = {
  ...initialResultSearchResult,
  refetchQuickSearch: false,
  meta: {},
  needle: '',
  count: 0,
  open: false,
  keepOpen: false,
  searchResult: {
    currentSearch: '',
    recipe: {
      items: []
    },
    recipeCategoryPage: {
      items: []
    },
    product: {
      items: []
    },
    category: {
      items: []
    },
    productCategoryPage: {
      items: []
    },
    page: {
      items: []
    },
    cateredmeal: {
      items: []
    },
    cateringCategoryPage: {
      items: []
    },
    store: {
      items: []
    },
    pagination: {
      pageIndex: 1,
      pageSize: 50,
      filters: [],
      totalCount: 1
    },
    loading: false,
    activeFilter: null
  },
  loading: false,
  errorMsg: ''
};

export const fetchQuickSearch = createAsyncThunk(
  'newSearch/fetchQuickSearch',
  async (fetchQuickSearch: string, thunkAPI) => {
    try {
      const query = qs.parse(fetchQuickSearch);
      if (!query.q) {
        return { searchQuery: query.q };
      }
      const response = await endpoints.quickSearch(
        `${LOOP_SEARCH_QUERY_PARAM}=${query?.q ?? ''}`
      );
      return { ...response.data, searchQuery: query.q };
    } catch (_e) {
      let e: any = _e;

      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export type TFetchExtendedSearch = {
  Q?: string;
  SearchQuery?: string;
  page?: number;
  take?: number;
  skip?: number;
  store?: number;
  location?: Location;
  type?: string;
  Brands?: string;
  COOKING_TIMES?: string;
  PRODUCT_MARKING?: string;
  RECIPE_MARKING?: string;
  SortField?: string;
  SortOrder?: string;
};

export const fetchExtendedSearch = createAsyncThunk(
  'newSearch/fetchExtendedSearch',
  async (paramObj: TFetchExtendedSearch, thunkAPI) => {
    try {
      const query = searchQueryHelper(paramObj, thunkAPI);
      const response = await endpoints.loopSearch(query);

      return {
        ...response.data,
        currentSearch: paramObj?.Q,
        type: paramObj?.type || DEFAULT_SEARCH_TYPE,
        query
      };
    } catch (_e) {
      let e: any = _e;

      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const fetchPaginatedSearch = createAsyncThunk(
  'newSearch/fetchPageinatedSearch',
  async (paramObj: TFetchExtendedSearch, thunkAPI) => {
    const query = searchQueryHelper(paramObj, thunkAPI, true);
    const response = await endpoints.loopSearch(query);

    return {
      ...response.data,
      currentSearch: paramObj?.Q,
      type: paramObj?.type || DEFAULT_SEARCH_TYPE,
      query
    };
  }
);

const searchSlice = createSlice({
  name: 'newSearch',
  initialState: initialState,
  reducers: {
    setNeedle: (state, action) => {
      state.needle = action.payload;
    },
    setOpenSearch: (state, action) => {
      if (state.keepOpen) {
        state.open = true;
        state.keepOpen = false;
      } else {
        state.open = action.payload;
      }
    },
    setKeepOpen: (state, action) => {
      state.keepOpen = action.payload;
    },
    refetchQuickSearch: (state, action) => {
      state.refetchQuickSearch = action.payload;
    }
  },
  extraReducers: builder => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchQuickSearch.pending, state => {
        if (state.loading === false) {
          state.loading = true;
        }
      })
      .addCase(
        fetchQuickSearch.fulfilled,
        (state, action: PayloadAction<IExtendedILoop54QuickSearchResult>) => {
          if (action.payload) {
            return receiveSearch(state, action.payload);
          }
        }
      )
      .addCase(fetchQuickSearch.rejected, (state, action) => {
        if (action.error.message) {
          state.errorMsg = action.error.message;
          state.loading = false;
        }
      })
      .addCase(fetchExtendedSearch.pending, state => {
        if (state.searchResult.loading === false) {
          state.searchResult.loading = true;
        }
      })
      .addCase(
        fetchExtendedSearch.fulfilled,
        (state, action: PayloadAction<IExtendedLoop54SearchResult>) => {
          if (action.payload) {
            // @TODO BACKEDND NEED TO FILTER OUT STOREPAGES AND CATEGORY PAGES
            const searchResponse = mapSearchResponseToState(action.payload);
            return {
              ...state,
              searchResult: {
                ...state.searchResult,
                ...searchResponse
              }
            };
          }
        }
      )
      .addCase(fetchExtendedSearch.rejected, (state, action) => {
        if (action.error.message) {
          state.errorMsg = action.error.message;
          state.searchResult.loading = false;
        }
      })
      .addCase(fetchPaginatedSearch.pending, (state, action) => {
        if (state.loading === false) {
          state.searchResult.loading = true;
        }
      })
      .addCase(fetchPaginatedSearch.fulfilled, (state, action) => {
        if (action.payload) {
          const searchResponse = mapSearchResponseToState(action.payload);

          return {
            ...state,
            searchResult: {
              ...state.searchResult,
              ...searchResponse
            }
          };
        }
      })
      .addCase(fetchPaginatedSearch.rejected, (state, action) => {
        if (action.error.message) {
          state.errorMsg = action.error.message;
          state.searchResult.loading = false;
        }
      });
  }
});

export const {
  setNeedle,
  setOpenSearch,
  setKeepOpen,
  refetchQuickSearch
} = searchSlice.actions;

// @TODO: should perhaps move all selectors
export const selectRefetchQuickSearch = state =>
  state.newSearch.refetchQuickSearch;
export const selectIsSearchOpen = state => state.newSearch.open;
export const selectIsSearchKeepOpen = state => state.newSearch.keepOpen;
export const selectNewSearchNeedle = state => state.newSearch.needle;
export const selectNewSearch = state => state.newSearch;
export const selectNewSearchLoading = state => state.newSearch.loading;
export const selectNewSearchResultLoading = state =>
  state.newSearch.searchResult.loading;
export const selectCurrentSearch = state =>
  state.newSearch.searchResult.currentSearch;
export const selectNewSearchMeta = state => state.newSearch.meta;
const sweetenedProductMarkings = state => state.app.settings?.productMarkings;
const sweetenedRecipeFiltersSelector = state =>
  state.app.settings?.recipeMarkings;
const selectSearchProducts = state => state.newSearch.product.items;
const selectSearchRecipes = state => state.newSearch.recipe.items;
const productsWithDefault = makeProductEnhancerSelectorLoop(
  selectSearchProducts
);

export const selectCombinedSearchRecipes = makeRecipeEnhancerSelector(
  selectSearchRecipes
);

export const selectCombinedSearchProducts = createDraftSafeSelector(
  [getCartItems, productsWithDefault],
  productCombination
);

export const selectNewSearchResult = state => {
  const { newSearch } = state;
  const { recipe, product } = newSearch;
  return {
    ...newSearch,
    recipe: {
      ...recipe,
      items: selectCombinedSearchRecipes(state)
    },
    product: {
      ...product,
      items: selectCombinedSearchProducts(state)
    }
  };
};

const getSearchPageResultProducts = state =>
  state.newSearch.searchResult.product.items;
const getSearchPageResultRecipes = state =>
  state.newSearch.searchResult.recipe.items;
const getActiveFilterType = state => state.newSearch.searchResult.activeFilter;

const searchResultPageProductsWithDefault = makeProductEnhancerSelectorLoop(
  getSearchPageResultProducts
);
const selectSearchPageFilter = state =>
  state.newSearch.searchResult.pagination.filters;

export const combinedSearchFilters = createDraftSafeSelector(
  [
    selectSearchPageFilter,
    getActiveFilterType,
    sweetenedProductMarkings,
    sweetenedRecipeFiltersSelector
  ],
  (filters, activeFilter, productMarkings, recipeMarkings) => {
    if (activeFilter === SEARCH_TYPES.product) {
      return filterFacetCombiner(filters, productMarkings, [
        LOOP_FACETS.PRODUCT_MARKING
      ]);
    } else if (activeFilter === SEARCH_TYPES.recipe) {
      return filterFacetCombiner(filters, recipeMarkings, [
        LOOP_FACETS.RECIPE_MARKING
      ]);
    } else {
      return [];
    }
  }
);

export const selectSearchResultPageProducts = createDraftSafeSelector(
  [getCartItems, searchResultPageProductsWithDefault],
  productCombination
);
export const selectSearchResultPageRecipes = makeRecipeEnhancerSelector(
  getSearchPageResultRecipes
);

export const newSearchReducer = searchSlice.reducer;

function receiveSearch(state, payload: IExtendedILoop54QuickSearchResult) {
  if (!payload.searchQuery) {
    return { ...initialState, open: state.open };
  }
  const { autoCompletions, searchResults } = payload;
  const {
    products = [],
    recipes = [],
    pages = [],
    stores = [],
    categories = [],
    cateredMeals = [],
    facets,
    totalPages: pageCount,
    currentPage: pageIndex,
    pageSize,
    totalCount
    // @TODO can we fetch these in loop ?
    // productcategorypage = [],
    // recipecategorypage = [],
    // cateringcategorypage = [],
  } = searchResults;
  // @TODO currently not included in api
  // corrections: [],

  return {
    ...state,
    product: {
      title: 'Matvaror',
      items: products,
      count: products.length
    },
    category: {
      title: 'Kategorier',
      items: categories,
      count: categories.length
    },
    recipe: {
      title: 'Recept',
      items: recipes,
      count: recipes.length
    },
    page: {
      title: 'Sidor',
      items: pages,
      count: pages.length
    },
    cateredmeal: {
      title: 'Catering',
      items: cateredMeals,
      count: cateredMeals.length
    },
    store: {
      title: 'Butiker',
      items: stores,
      count: stores.length
    },
    meta: {
      filters: facets,
      pageCount,
      pageIndex,
      pageSize,
      sort: null,
      totalCount,
      needle: payload.searchQuery,
      completions: autoCompletions.items
    },
    loading: false,
    refetchQuickSearch: false
    //@TODO wait for backend to separate these?
    // recipeCategoryPage: {
    //   title: 'ReceptCategoryPage',
    //   items: recipecategorypage,
    //   count: recipecategorypage.length
    // },
    // productCategoryPage: {
    //   title: 'MatvarorCategoryPage',
    //   items: productcategorypage,
    //   count: productcategorypage.length
    // },
    // cateringCategoryPage: {
    //   title: 'cateringCategoryPage',
    //   items: cateringcategorypage,
    //   count: cateringcategorypage.length
    // },

    // count: payload.data.length
  };
}
