import { createSelector } from 'reselect';
import { activeStoreNoSelector } from '../assortments';
import config from '../../config/config';
import { filterFacetCombiner } from '../../lib/filterFacetCombiner.ts';
import { LOOP_FACETS } from '../../types/loop54Facets';

const getRecipes = state => state.recipe.recipes;
const getBlockRecipes = (_, recipes) => recipes || [];

const filtersSelector = state => state.recipe.filters;
const sweetenedFiltersSelector = state => state.app.settings?.recipeMarkings;
const favoriteRecipesSelector = state =>
  state.auth &&
  state.auth.recipeFavorites &&
  state.auth.recipeFavorites.length > 0
    ? state.auth.recipeFavorites
    : [];
const favoriteRecipesLoadingSelector = state =>
  state.auth ? state.auth.favoriteRecipesLoading : [];

// TODO: Create a general selector for product / recipes
export const filtersCombinationSelector = createSelector(
  [filtersSelector, sweetenedFiltersSelector],
  (filters, sweetenedFilters) => {
    const combinedFilters = filterFacetCombiner(filters, sweetenedFilters, [
      LOOP_FACETS.RECIPE_MARKING
    ]);
    return Object.values(combinedFilters);
  }
);

// TODO: Create a general selector for product / recipes
export const selectedFiltersSelector = createSelector(
  // combining with epi-values
  [filtersCombinationSelector],
  filters => {
    return Object.values(filters).reduce((acc, { id, options }) => {
      const selected = options
        .filter(opt => opt.selected)
        .map(opt => ({ parent: id, ...opt }));

      return [...acc, ...selected];
    }, []);
  }
);

export const setImage = (images, type) => {
  const image = (images || []).find(image => image.type === type);
  return image
    ? { ...image, url: `${config.PICTURE_BASE_URL}/recipes/${image.url}` }
    : { url: '', alt: '', type: 0 };
};

const determineMarkingVariants = (preview, sweetenedFilters) => {
  if (!preview) {
    return [];
  }
  const matchedMarkings = sweetenedFilters
    ?.map(sweetFilter => {
      return preview?.variants?.some(variant => variant === sweetFilter.code)
        ? sweetFilter
        : undefined;
    })
    ?.filter(v => v);
  return matchedMarkings;
};

const recipeDefaults = (r, sweetenedFilters, favorites, favoritesLoading) => {
  const favorite = favorites.filter(item => item.itemNo === r.id);
  const preview = r?.recipeStoreDetails?.preview || null;
  return {
    ...r,
    images: r.images || [],
    image: setImage(r.images, 0),
    tags: r.tags || [],
    preview: preview,
    markings: determineMarkingVariants(preview, sweetenedFilters) || [],
    favorite: favorite.length > 0 ? favorite[0] : false,
    isFavoriteLoading: favoritesLoading.includes(r.id)
  };
};

export const makeRecipeEnhancerSelector = (selector = getRecipes) =>
  createSelector(
    [
      selector,
      sweetenedFiltersSelector,
      favoriteRecipesSelector,
      favoriteRecipesLoadingSelector,
      activeStoreNoSelector
    ],
    (
      recipes,
      sweetenedFilters,
      favoriteRecipes,
      favoriteRecipesLoading,
      storeId
    ) => {
      return recipes?.map(recipe =>
        recipeDefaults(
          recipe,
          sweetenedFilters,
          favoriteRecipes,
          favoriteRecipesLoading,
          storeId
        )
      );
    }
  );

// For blocks that gets recipes from props/epi instead of state.
export const makeBlockRecipeEnhancerSelector = createSelector(
  [
    getBlockRecipes,
    sweetenedFiltersSelector,
    favoriteRecipesSelector,
    favoriteRecipesLoadingSelector
  ],
  (recipes, sweetenedFilters, favoriteRecipes, favoriteRecipesLoading) => {
    return recipes.map(recipe =>
      recipeDefaults(
        recipe,
        sweetenedFilters,
        favoriteRecipes,
        favoriteRecipesLoading
      )
    );
  }
);

export const recipeFavoritesSelector = createSelector(
  [favoriteRecipesSelector],
  favorites => {
    return favorites;
  }
);
