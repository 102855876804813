import { createSelector } from 'reselect';
import {
  determineMarkingVariant,
  determineSafetyDataSheetInformation,
  loopDetermineAvailable
} from '../../lib/product';
import { activeStoreNoSelector } from '../assortments';
import { AppState } from '../../reducers';
import { Marking } from '../../types/xim/product';
import { Marking as EpiserverMarking } from '../../types/episerver/marking';
import { SignalWords } from '../../types/episerver/signalWords';
import { WarningSymbol } from '../../types/episerver/warningSymbol';
import { calculateSellingUnitOfMeasure } from '../../lib/price';
import { IExtendedProduct } from '../../types/storefront/product';
import { ILoop54Product } from '../../types/loop54Product';
import { LOOP_FACETS } from '../../types/loop54Facets';
import { filterFacetCombiner } from '../../lib/filterFacetCombiner';

const getAllProducts = state => state.product.products;
const getCategoryProducts = (state: AppState) => state.product.category;
const filtersSelector = (state: AppState) => state.product.filters;
export const sweetenedFiltersSelector = (state: AppState) =>
  state.app.settings?.productMarkings;
export const sweetenedSignalWordsSelector = (state: AppState) =>
  state.app.settings?.signalWords;
export const sweetenedSymbolsSelector = (state: AppState) =>
  state.app.settings?.warningSymbols;
const authState = (state: AppState) => state.auth;

const favoriteProductsSelector = state => {
  return state.auth &&
    state.auth.productFavorites &&
    state.auth.productFavorites.length > 0
    ? state.auth.productFavorites
    : [];
};

export const favoriteProductsLoadingSelector = createSelector(
  [authState],
  authState => (authState ? authState.favoriteProductsLoading : [])
);

export const filtersCombinationSelector = createSelector(
  [filtersSelector, sweetenedFiltersSelector],
  (filters, sweetenedFilters) => {
    const combinedFilters = filterFacetCombiner(filters, sweetenedFilters, [
      LOOP_FACETS.PRODUCT_MARKING
    ]);

    return combinedFilters;
  }
);

export const selectedFiltersSelector = createSelector(
  // combining with epi-values
  [filtersCombinationSelector],
  filters => {
    return Object.values(filters).reduce((acc: any, { name, options }) => {
      const selected =
        options
          ?.filter(opt => opt.selected)
          ?.map(opt => ({ parent: name, ...opt })) || [];

      return [...acc, ...selected];
    }, []);
  }
);

export const loopProductDefaults = (
  p: ILoop54Product,
  sweetenedFilters?: EpiserverMarking[] | null,
  sweetenedSignalWords?: SignalWords[],
  sweetenedSymbols?: WarningSymbol[]
): IExtendedProduct => {
  const shouldPromote =
    p.newProductFrom && p.newProductTo
      ? Boolean(
          new Date() > new Date(p.newProductFrom) &&
            new Date() < new Date(p.newProductTo)
        )
      : false;

  // Due to PIM not delivering this marking anymore
  const customMarkings: Marking[] | null = shouldPromote
    ? [
        {
          qualifier: 'https://www.citygross.se',
          agency: 'https://www.citygross.se',
          code: 'NYHET',
          codeListVersion: '1',
          descriptions: []
        },
        ...(p.markings ? p.markings : [])
      ]
    : p.markings
    ? [...p.markings]
    : [];

  if (
    p?.tags?.length &&
    p.tags.some(
      tag =>
        tag.namespace.includes('CUSTOM') && tag?.name?.toUpperCase() !== 'KLIPP'
    )
  ) {
    p.tags.forEach(tag => {
      const isMarking = sweetenedFilters?.find(
        filter => filter.code === tag.name && filter.ispublished
      );
      if (isMarking) {
        customMarkings?.push({
          qualifier: 'https://www.citygross.se',
          agency: 'https://www.citygross.se',
          code: tag.name,
          codeListVersion: '1',
          descriptions: []
        });
      }
    });
  }
  return {
    ...p,
    unit: calculateSellingUnitOfMeasure(p),
    mappedMarkings:
      determineMarkingVariant(customMarkings, sweetenedFilters) || [],
    available: loopDetermineAvailable(p?.productStoreDetails),
    mappedSafetyDataSheetInformation: determineSafetyDataSheetInformation(
      p.safetyDataSheetInformation,
      sweetenedSignalWords,
      sweetenedSymbols
    )
  };
};

// TODO Refactor this so that we can type productGetter by a generic...
export const makeProductEnhancerSelectorLoop = productGetter =>
  createSelector(
    [
      productGetter,
      sweetenedFiltersSelector,
      sweetenedSignalWordsSelector,
      sweetenedSymbolsSelector
    ],
    (
      products: ILoop54Product[],

      sweetenedFilters,
      sweetenedSignalWords,
      sweetenedSymbols
    ) =>
      products
        ? products.map(product =>
            loopProductDefaults(
              product,
              sweetenedFilters,
              sweetenedSignalWords,
              sweetenedSymbols
            )
          )
        : []
  );

export const productFavoritesSelector = createSelector(
  [favoriteProductsSelector],
  favorites => {
    return favorites;
  }
);

export const categoryProductsWithDefaults = makeProductEnhancerSelectorLoop(
  getCategoryProducts
);

export const allProductsWithDefaults = makeProductEnhancerSelectorLoop(
  getAllProducts
);
