import React from 'react';
import { BodyText, TextTypes } from '@citygross/typography';
import { theme } from '@citygross/design-tokens';

type TOfferCard = {
  comparisonPrice?: string;
  fontSize: TextTypes.TextSize;
  pant?: boolean;
  regularRetailPrice?: string;
  save?: string;
  textAlign?: 'center' | 'left';
};

export const OfferPriceDetails = ({
  comparisonPrice,
  fontSize,
  pant,
  regularRetailPrice,
  save,
  textAlign = 'left'
}: TOfferCard) => (
  <>
    <div>
      {regularRetailPrice && (
        <BodyText
          color={theme.palette?.darkest}
          fontWeight="semiBold"
          size={fontSize}
          textAlign={textAlign}
        >
          Ord pris {regularRetailPrice} {pant && '+ pant'}
        </BodyText>
      )}

      {comparisonPrice && (
        <BodyText
          color={theme.palette?.darkest}
          size={fontSize}
          textAlign={textAlign}
        >
          Jfr pris {comparisonPrice}
        </BodyText>
      )}
    </div>

    {save && (
      <BodyText
        color={theme.palette?.alertRed}
        fontWeight="semiBold"
        size={fontSize}
        textAlign={textAlign}
      >
        {save}
      </BodyText>
    )}
  </>
);
