import {
  StoreMeta,
  DeliveryMethods,
  HomeDeliverySortimentOptions
} from '../assortments';
import { Store } from '../episerver/store';

const NAME = 'assortments';

export const RECEIVE_STORE_INFORMATIONS = `${NAME}/RECEIVE_STORE_INFORMATIONS`;
export const SITES_LOADING = `${NAME}/SITES_LOADING`;

export const TOGGLE_MENU_TAB = `${NAME}/TOGGLE_MENU_TAB`;
export const TOGGLE_MODAL = `${NAME}/TOGGLE_MODAL`;
export const TOGGLE_ASSORTMENT_INFO_MODAL = `${NAME}/TOGGLE_ASSORTMENT_INFO_MODAL`;
export const TOGGLE_LAST_SELECTED = `${NAME}/TOGGLE_LAST_SELECTED`;

export const SET_ACTIVE_ASSORTMENT = `${NAME}/SET_ACTIVE_ASSORTMENT`;
export const ASSORTMENTS_LOADING = `${NAME}/ASSORTMENTS_LOADING`;

export const SET_INITIAL_ASSORTMENT_VALUES = `${NAME}/SET_INITIAL_ASSORTMENT_VALUES`;
export const SET_ACTIVE_DELIVERYMETHOD = `${NAME}/SET_ACTIVE_DELIVERYMETHOD`;

export interface AssortmentsState {
  siteId: number | string | null;
  homeSiteId: number | string | null;
  storeNo: number | null;
  storeProvider?: string | null;
  zipCode: number | string | null;
  deliveryMethod: DeliveryMethods | null;
  homeDeliveryAssortmentValues: HomeDeliverySortimentOptions | null;
  storePickerModalActive: boolean;
  assortmentInfoModalActive: boolean;
  menuTabActive: boolean;
  lastSelectedActive: boolean;
  stores: StoreMeta[] | null;
  epiStores: Store[] | null;
  assortmentLoading: boolean;
  sitesLoading: boolean;
}

// interface ChangeAssortmentAction {
//   type: typeof CHANGE_ASSORTMENT;
//   payload: number;
// }

export interface SetAssortmentLoadingAction {
  type: typeof ASSORTMENTS_LOADING;
  loading: boolean;
}
export interface SetSitesLoadingAction {
  type: typeof ASSORTMENTS_LOADING;
  loading: boolean;
}

export interface ReceiveStoreInformationsAction {
  type: typeof RECEIVE_STORE_INFORMATIONS;
  payload: StoreMeta[];
}

export interface SetToggleModalActiveAction {
  type: typeof TOGGLE_MODAL;
  active: boolean;
}

export interface SetToggleInfoModalActiveAction {
  type: typeof TOGGLE_ASSORTMENT_INFO_MODAL;
  active: boolean;
}

export interface SetLastSelectedAction {
  type: typeof TOGGLE_LAST_SELECTED;
  active: boolean;
}

export interface ToggleModalAction {
  type: typeof TOGGLE_MODAL;
  payload: null;
}

export interface ToggleMenuTabAction {
  type: typeof TOGGLE_MENU_TAB;
  payload: null;
}

export interface SetAssortmentAction {
  type: typeof SET_ACTIVE_ASSORTMENT;
  deliveryType: DeliveryMethods;
  siteId: number | string;
  homeSiteId: number | string | null;
  zipCode: number | string | null;
  storeNo: number | string;
  storeProvider?: string | null;
  sortimentOptions?: HomeDeliverySortimentOptions | null;
}
export interface SetInitialAssortmentValuesAction {
  type: typeof SET_INITIAL_ASSORTMENT_VALUES;
  deliveryType: DeliveryMethods;
  zipCode: number;
  siteId: number;
}

export interface SetDeliveryMethodAction {
  type: typeof SET_ACTIVE_DELIVERYMETHOD;
  deliveryType: DeliveryMethods;
}

export type AssortmentsActionTypes =
  | ToggleModalAction
  | ReceiveStoreInformationsAction
  | SetAssortmentAction
  | SetInitialAssortmentValuesAction
  | ToggleMenuTabAction
  | SetDeliveryMethodAction
  | SetLastSelectedAction;
