import React, { Fragment } from 'react';
import ContentBlocksArea from '../../components/ContentBlocksArea';
import RecipeList from '../../components/RecipeList';
import { LinkablePageTitle as PageTitle } from '../../components/PageTitle';
import CategoryFilter from '../../components/CategoryFilter/CategoryFilter';
import ErrorInfo from '../../components/ErrorInfo';

import './RecipeCategory.scss';
import OverFlowContainer from '../../components/OverFlowContainer';
import { IPage } from '../../types/episerver/page';
import { IExtendedRecipe } from '../../types/Loop54Recipes';

interface IRecipeCategory {
  error: boolean;
  errorBadCategory: boolean;
  fetching: boolean;
  filterRecipes: Function;
  filters: Array<any>;
  getPageContent: Function;
  getRecipes: Function;
  id: number;
  isAuthenticated: boolean;
  location: any;
  page: IPage;
  pageError: boolean;
  pagination: any;
  recipes: IExtendedRecipe[];
  redirectToLogin: Function;
  removeFavorite: Function;
  requestMoreRecipes: Function;
  selectedFilters: Array<any>;
  setRecipeFavorite: Function;
  sortRecipes: Function;
  storeId: number;
  title: any;
  sortOptions: Array<any>;
  impressionListName: string;
}

const RecipeCategory = (props: IRecipeCategory) => {
  const {
    error,
    errorBadCategory,
    fetching,
    filterRecipes,
    filters,
    getPageContent,
    getRecipes,
    id,
    isAuthenticated,
    location,
    page,
    pageError,
    pagination,
    recipes,
    redirectToLogin,
    removeFavorite,
    requestMoreRecipes,
    selectedFilters,
    setRecipeFavorite,
    sortRecipes,
    storeId,
    title,
    sortOptions,
    impressionListName
  } = props;
  const setRecipeFavoriteFn = isAuthenticated
    ? setRecipeFavorite
    : redirectToLogin;
  const removeFavoriteFn = isAuthenticated ? removeFavorite : redirectToLogin;
  const defaultParams = {
    ...page?.tagsettings,
    categoryName: title?.current?.name
  };
  return (
    <Fragment>
      <PageTitle {...title} sort />
      {pageError ? (
        <ErrorInfo
          title="Hoppsan, något gick fel!"
          text="Det går inte att hämta denna sidan just nu."
          retryButtonFn={getPageContent}
        />
      ) : (
        <OverFlowContainer>
          <ContentBlocksArea blocks={page?.contentBlocks} />
        </OverFlowContainer>
      )}
      {error ? (
        <ErrorInfo
          title={!pageError && 'Hoppsan, något gick fel!'}
          text={
            errorBadCategory
              ? 'Sidan innehåller fel, receptkategorin saknas.'
              : 'Det går inte att hämta recepten just nu.'
          }
          retryButtonFn={getRecipes}
          sideMargins
        />
      ) : (
        <Fragment>
          <CategoryFilter
            id={id}
            filters={filters}
            selectedFilters={selectedFilters}
            pagination={pagination}
            sortProducts={(id, params) => {
              document.cookie = `sortArticles=${params.SortField};path=/`;
              sortRecipes(id, params, location, defaultParams);
            }}
            filterByPrice={() => {
              document.cookie = `sortPrices=${!pagination.priceOnly};path=/`;
              filterRecipes(
                id,
                {},
                location,
                {
                  PriceOnly: !pagination.priceOnly
                },
                { categoryName: title?.current?.name }
              );
            }}
            filterAction={filter =>
              filterRecipes(
                id,
                filter,
                location,
                storeId ? { store: storeId } : null,
                defaultParams
              )
            }
            recipe
            sortOptions={sortOptions}
            activeAssortment={storeId}
          />

          <RecipeList
            fetching={fetching}
            categoryId={id}
            requestMoreRecipes={(id, params) =>
              requestMoreRecipes(id, params, location, defaultParams)
            }
            setRecipeFavorite={setRecipeFavoriteFn}
            removeFavorite={removeFavoriteFn}
            pagination={pagination}
            items={recipes}
            impressionListName={impressionListName}
          />
        </Fragment>
      )}
      {pageError ? null : (
        <OverFlowContainer>
          <ContentBlocksArea blocks={page?.bottomContentBlocks} />
        </OverFlowContainer>
      )}
    </Fragment>
  );
};

export default RecipeCategory;
