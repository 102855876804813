import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import qs from 'query-string';
import { DEFAULT_RECIPEPAGE_SIZE } from '../../api/endpoints/recipe';
import { getPageById } from '../../actions/page';
import * as recipeActions from '../../actions/recipe';
import { breadCrumbSelector } from '../../selectors/routing/routingSelector';
import { pageIdSelector, pageSelector } from '../../selectors/page';
import { authenticatedSelector } from '../../selectors/user';
import {
  makeRecipeEnhancerSelector,
  selectedFiltersSelector,
  filtersCombinationSelector
} from '../../selectors/recipe/recipeSelectors';
import { setRecipeFavorite, removeFavorite } from '../../actions/auth';
import { redirectToLogin } from '../../actions/app';
import RecipeCategory from '../../modules/RecipeCategory/RecipeCategory';
import { selectCurrentAssortment } from '../../selectors/assortments';
import * as ga4 from '@citygross/analytics';
import { IPage } from '../../types/episerver/page';
import { IExtendedRecipe } from '../../types/Loop54Recipes';
import { RECIPE_SORT_OPTS } from '../../lib/sorting';

const impressionListName = 'Recipe Category';
interface IRecipeCategoryPage {
  page: IPage;
  id: number;
  storeId: number;
  recipes: IExtendedRecipe[];
  location: any;
  selectedFilters: any;
  pagination: any;
  title: any;
  filters: any;
  fetching: boolean;

  isAuthenticated: boolean;
  getRecipesByCategory: Function;
  getPageById: Function;
  filterRecipes: Function;
  sortRecipes: Function;
  requestMoreRecipes: Function;
  redirectToLogin: Function;
  setRecipeFavorite: Function;
  removeFavorite: Function;
}
interface IRecipeCategoryPageState {
  error: boolean;
  errorInfo: any;
  pageError: boolean;
  errorBadCategory?: any;
  pageId: number | null;
}
class RecipeCategoryPage extends Component<
  IRecipeCategoryPage,
  IRecipeCategoryPageState
> {
  constructor(props: IRecipeCategoryPage) {
    super(props);

    this.state = {
      error: false,
      errorInfo: null,
      pageError: false,
      pageId: null
    };
  }

  componentDidMount() {
    const { id, page } = this.props;

    if (page.id !== id) {
      this.getPageContent();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { storeId, recipes } = this.props;
    if (!this.state.pageId) return;
    if (
      prevProps.storeId !== storeId ||
      prevState.pageId !== this.state.pageId
    ) {
      this.getRecipes();
    }

    const prevPropRecipes = prevProps.recipes.reduce((acc, curr) => {
      return (acc += curr.id);
    }, '');

    const currRecipes = this.props.recipes.reduce((acc, curr) => {
      return (acc += curr.id);
    }, '');

    if (prevPropRecipes !== currRecipes && currRecipes.length > 0) {
      try {
        const ga4ImpressionItems = recipes.map((recipe, index) => {
          let recipePrice = 0;

          const preview = recipe.recipeStoreDetails?.preview;

          if (preview) recipePrice = preview.minimumPrice || 0;

          return {
            item_id: recipe.id,
            item_name: recipe.name,
            item_brand: recipe.source || 'City Gross',
            item_category:
              '/recept' +
                recipe.url?.substring(0, recipe.url.lastIndexOf('/') + 1) || '',
            item_variant: recipe.id,

            item_list_name: impressionListName,
            item_list_id: impressionListName,
            index: index + 1,

            price: recipePrice,
            quantity: 1,
            discount: 0
          };
        });

        ga4.viewItemList({
          items: ga4ImpressionItems,
          item_list_id: impressionListName,
          item_list_name: impressionListName
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  getRecipes = () => {
    const { id, location, storeId, getRecipesByCategory } = this.props;
    let requestQueries = qs.parse(location.search);

    const resumePageIndex =
      requestQueries.page && Math.max(0, Number(requestQueries.page) - 1);
    requestQueries = {
      ...requestQueries,
      size: DEFAULT_RECIPEPAGE_SIZE.toString(),
      page: resumePageIndex ? resumePageIndex.toString() : null
    };

    if (storeId) {
      requestQueries.store = storeId.toString();
    }

    const prodSort = document.cookie.match(/sortArticles=([^;]+)/);
    const recipePriceOnlySort = document.cookie.match(/sortPrices=([^;]+)/);
    if (
      !requestQueries.Sort &&
      prodSort &&
      RECIPE_SORT_OPTS.find(opt => opt.value === prodSort[1])
    ) {
      requestQueries = {
        ...requestQueries,
        SortField: prodSort[1],
        SortOrder: this.props.pagination?.sortOrder ?? '',
        PriceOnly:
          (
            recipePriceOnlySort &&
            recipePriceOnlySort.length > 0 &&
            recipePriceOnlySort[1]
          )?.toString() ?? ''
      };
    }
    getRecipesByCategory(id, requestQueries, {
      ...this.props?.page?.tagsettings,
      categoryName: this.props?.title?.current?.name
    }).catch(({ data }) => {
      this.setState({
        error: true,
        errorBadCategory: !!(
          data &&
          data.length &&
          data.find(({ code }) => code === 'CATEGORY_NOT_CONFIGURED')
        )
      });
    });
  };

  getPageContent = () => {
    const { id, getPageById } = this.props;
    this.setState({
      pageError: false,
      pageId: id
    });
    getPageById(id).catch(err => {
      console.error('getPageById failed', err); // eslint-disable-line
      this.setState({
        pageError: true
      });
    });
  };

  render() {
    const { error, errorBadCategory, pageError } = this.state;
    return (
      <RecipeCategory
        {...this.props}
        fetching={this.props.fetching}
        id={this.props.id}
        error={error}
        errorBadCategory={errorBadCategory}
        pageError={pageError}
        getPageContent={this.getPageContent}
        getRecipes={this.getRecipes}
        sortOptions={RECIPE_SORT_OPTS}
        impressionListName={impressionListName}
        filters={this.props.filters}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  const location = props.location;
  const recipeSelector = makeRecipeEnhancerSelector();

  return {
    storeId: Number(selectCurrentAssortment(state)),
    recipes: recipeSelector(state),
    selectedFilters: selectedFiltersSelector(state),
    filters: filtersCombinationSelector(state),
    pagination: state.recipe.pagination,
    title: breadCrumbSelector(state, props),
    id: pageIdSelector(state, props),
    page: pageSelector(state, props),
    fetching: state.recipe.fetching,
    location,
    isAuthenticated: authenticatedSelector(state)
  };
};

const mapDispatchToProps = {
  getRecipesByCategory: recipeActions.getRecipesByCategory,
  getPageById,
  filterRecipes: recipeActions.filterRecipes,
  sortRecipes: recipeActions.sortRecipes,
  requestMoreRecipes: recipeActions.requestMoreRecipes,
  redirectToLogin,
  setRecipeFavorite,
  removeFavorite
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RecipeCategoryPage)
);
