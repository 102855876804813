import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { DEFAULT_PRODUCTPAGE_SIZE } from '../../api/endpoints/product';
import * as productActions from '../../actions/product';
import * as pageActions from '../../actions/page';
import { sortedCartItemsCategoryProductCombinationSelector } from '../../selectors/cart/cartSelector';
import {
  selectedFiltersSelector,
  filtersCombinationSelector
} from '../../selectors/product/productSelectors';
import { breadCrumbSelector } from '../../selectors/routing/routingSelector';
import { pageSelector, pageIdSelector } from '../../selectors/page';
import { authenticatedSelector } from '../../selectors/user';
import { setProductFavorite, removeFavorite } from '../../actions/auth';
import ProductCategory from '../../modules/ProductCategory/ProductCategory.tsx';
import { isNumber } from '../../lib/number';
import { selectCurrentAssortment } from '../../selectors/assortments';
import * as ga4 from '@citygross/analytics';
import { PRODUCT_SORT_OPTS } from '../../lib/sorting.ts';
import { sortFieldHelper } from '../../lib/loop54Data.ts';
import { ga4ImpressionList } from '../../lib/analytics/analytics.ts';

const impressionListName = 'Product Category';
class ProductCategoryPage extends PureComponent {
  state = {
    pageError: false,
    loading: false,
    currentStoreId: null,
    ga4ImpressionTrackingSent: false,
    pageId: null,
    initialFetched: false
  };

  componentDidMount() {
    // TODO This should be fetched if we dont already have the products
    // for this category.

    const { page, id } = this.props;

    if (page.id !== id) {
      this.getPageContent();
    }
  }

  getPageContent = () => {
    const { id, getPageById } = this.props;

    this.setState({
      pageError: false
    });
    getPageById(id)
      .then(d => {
        this.fetchCategory();
      })
      .catch(err => {
        this.setState({
          pageError: true
        });
      });
  };

  componentDidUpdate(prevProps) {
    if (this.state.loading || !this.props?.page?.id) return;
    if (this.prevProps?.page?.id !== this.state.pageId) {
      this.setState({
        pageId: this.prevProps?.page?.id
      });
    }
    const { storeId, products } = this.props;
    if (
      this.state.currentStoreId &&
      this.state.currentStoreId !== storeId &&
      !this.state.loading
    ) {
      this.fetchCategory();
    }
    if (!this.state.ga4ImpressionTrackingSent) {
      try {
        ga4ImpressionList(products, impressionListName);

        this.setState({
          ...this.state,
          ga4ImpressionTrackingSent: true
        });
      } catch (error) {
        console.error(error);
      }
    }

    if (
      prevProps.products.length !== products.length &&
      this.state.ga4ImpressionTrackingSent
    ) {
      this.setState({
        ...this.state,
        ga4ImpressionTrackingSent: false
      });
    }
  }
  fetchCategory() {
    const {
      id,
      title,
      location,
      storeId,
      getProductsByCategory,
      pagination
    } = this.props;
    this.setState({ loading: true });
    let requestQueries = qs.parse(location.search);
    const sort = sortFieldHelper(pagination?.sort);
    requestQueries = {
      ...requestQueries,
      store: storeId,
      categoryName: title?.current?.name,
      SortField: sort?.value,
      SortOrder: pagination?.sortOrder
    };
    const resumePageIndex =
      requestQueries.page && parseInt(requestQueries.page, 10);
    if (isNumber(resumePageIndex)) {
      requestQueries = {
        ...requestQueries,
        ...this.props.page?.tagsettings,
        size: DEFAULT_PRODUCTPAGE_SIZE,
        page: requestQueries.page - 1
      };
    }
    getProductsByCategory(id, requestQueries, this.props.page?.tagsettings)
      .then(() => {
        this.setState({
          loading: false,
          currentStoreId: storeId,
          initialFetched: true
        });
      })
      .catch(err => {
        this.setState({
          loading: false,
          currentStoreId: storeId,
          initialFetched: true
        });
        console.error('Retrieving products failed', err);
      });
  }

  render() {
    const {
      // Don't pass these props
      getPageById,
      getProductsByCategory,
      match,
      products,
      // ---
      ...passOnProps
    } = this.props;

    const { pageError } = this.state;

    return (
      <ProductCategory
        {...passOnProps}
        initialFetched={this.state.initialFetched}
        products={products}
        pageError={pageError}
        getPageContent={this.getPageContent}
        sortOptions={PRODUCT_SORT_OPTS}
        impressionListName={impressionListName}
      />
    );
  }
}

ProductCategoryPage.required = [
  (state, params) => {
    return productActions.getProductsByCategory(params.id);
  },
  (state, params, noCache) => {
    return pageActions.getPageById(
      params.id,
      state.router.location.search,
      noCache,
      params.userSiteId
    );
  }
];

const mapStateToProps = (state, props) => {
  const location = props.location;
  return {
    storeId: selectCurrentAssortment(state),
    products: sortedCartItemsCategoryProductCombinationSelector(state),
    selectedFilters: selectedFiltersSelector(state),
    filters: filtersCombinationSelector(state),
    pagination: state.product.pagination,
    title: breadCrumbSelector(state, props),
    location,
    isAuthenticated: authenticatedSelector(state),
    id: pageIdSelector(state, props),
    page: pageSelector(state, props),
    fetching: state.product.fetching
  };
};

const mapDispatchToProps = {
  getProductsByCategory: productActions.getProductsByCategory,
  getPageById: pageActions.getPageById,
  requestMoreProducts: productActions.requestMoreProducts,
  sortProducts: productActions.sortProducts,
  filterProducts: productActions.filterProducts,
  setProductFavorite,
  removeFavorite
};

ProductCategoryPage.propTypes = {
  getProductsByCategory: PropTypes.func.isRequired,
  page: PropTypes.object,
  id: PropTypes.number,
  storeId: PropTypes.number,
  location: PropTypes.object,
  getPageById: PropTypes.func.isRequired
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductCategoryPage)
);
