import React from 'react';
import MostBoughtProducts from '../../modules/MostBoughtProducts';
import MostBoughtRecipes from '../../modules/MostBoughtRecipes';
import { IMostBoughtProductsBlockProps } from '../../types/episerver/blocks/MostBoughtProductsBlock';

const MostBoughtProductsBlock: React.FC<IMostBoughtProductsBlockProps> = ({
  favouritesType,
  daysToFetch,
  maxItemsToFetch,
  notLoggedInText,
  missingDataText
}) => {
  if (favouritesType === 'products') {
    return (
      <MostBoughtProducts
        daysToFetch={daysToFetch}
        maxItemsToFetch={maxItemsToFetch}
        notLoggedInText={notLoggedInText}
        missingDataText={missingDataText}
      />
    );
  } else if (favouritesType === 'recipes') {
    return (
      <MostBoughtRecipes
        daysToFetch={daysToFetch}
        maxItemsToFetch={maxItemsToFetch}
        notLoggedInText={notLoggedInText}
        missingDataText={missingDataText}
      />
    );
  } else {
    return <div />;
  }
};

export default MostBoughtProductsBlock;
