import { loopRequest } from '../../lib/request';
import queryParams from '../../lib/queryParams';
import { ILoop54ProductResponse } from '../../types/loop54Product';
export const DEFAULT_PRODUCTPAGE_SIZE = 120;

export const fetchCateringProductsByVariant = (
  ids: string
): Promise<ILoop54ProductResponse> => {
  return loopRequest(`/Loop54/variant/${ids}/catered-meals`);
};

export const fetchLoopCateringProductByIds = (
  productIds: string
): Promise<ILoop54ProductResponse> =>
  loopRequest(`/Loop54/catered-meal/${productIds}`);

export const fetchLoopCateringCategory = (
  categoryId: string,
  params: any
): Promise<ILoop54ProductResponse> => {
  const opts = queryParams({
    take: DEFAULT_PRODUCTPAGE_SIZE,
    ...params
  });

  return loopRequest(`/Loop54/category/${categoryId}/catered-meals?${opts}`);
};
