import React, { useState, useEffect, useRef } from 'react';
import ProductList from '../ProductList';
import { setProductFavorite, removeFavorite } from '../../actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductsInOffer } from '../../api/endpoints/product';
import { selectCurrentAssortment } from '../../selectors/assortments';
import { AppState } from '../../reducers';
import { LoadMoreToolbar } from '../LoadMoreToolbar';
import * as ga4 from '@citygross/analytics';
import { formatPrice } from '../../store/middleware/analytics';
import { loopProductDefaults } from '../../selectors/product/productSelectors';
import { IExtendedProduct } from '../../types/storefront/product';
import { ga4ImpressionList } from '../../lib/analytics/analytics';

type TProductsInOffer = {
  isFlyout?: boolean;
  offerId: string;
  productId: string;
};

const ProductsInOffer = ({
  isFlyout,
  offerId,
  productId
}: TProductsInOffer) => {
  const [products, setProducts] = useState<IExtendedProduct[]>([]);
  const [paginationIndex, setPaginationIndex] = useState<number>(0);
  const dispatch = useDispatch();

  const activeStore = useSelector((state: AppState) =>
    selectCurrentAssortment(state)
  );

  const componentMounted = useRef(true);

  useEffect(() => {
    const ac = new AbortController();

    fetchProductsInOffer(offerId, productId)
      .then(({ data }) => {
        if (data.items && data.items.length > 0 && componentMounted.current) {
          const products = data.items.map(item => loopProductDefaults(item));
          setProducts(products);
        }

        try {
          const impressionListName = 'ProductSingle - varor i samma erbjudande';
          ga4ImpressionList(data.items, impressionListName);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error(e);

        if (componentMounted.current) {
          setProducts([]);
        }
      });

    return () => {
      ac.abort();
      componentMounted.current = false;
      if (isFlyout) setProducts([]);
    };
  }, [activeStore]);

  const getProducts = () => {
    if (paginationIndex === 0) {
      const arrayCopy = [...products];
      return arrayCopy.splice(0, 4);
    }

    return products;
  };

  if (!products || !products.length) return null;

  return (
    <div className="c-productdetails__related">
      <h2>Varor som ingår i samma erbjudande</h2>

      <ProductList
        fetching={false}
        setProductFavorite={(id, item) =>
          dispatch(setProductFavorite(id, item))
        }
        removeFavorite={x => dispatch(removeFavorite(x))}
        items={getProducts()}
        colStructure={'l-column-15_xs-30_sm-20_md-15_lg-15_xlg-15-mobileGutter'}
      />

      <LoadMoreToolbar
        requestMore={() => setPaginationIndex(1)}
        count={Math.min(
          products?.length || 0,
          paginationIndex === 0 ? 4 : Infinity
        )}
        totalCount={products?.length}
        fetching={false}
      />
    </div>
  );
};

export default ProductsInOffer;
