import { ILoop54Tags } from '../types/loop54Product';

export const isLimitedDeal = (tags: ILoop54Tags[]) => {
  if (tags) {
    return Boolean(
      tags.filter(item => item.namespace === 'KLIPP' && item.name === 'VARA')
        .length
    );
  }
  return false;
};

const isLimitedStock = (tags: ILoop54Tags[]) => {
  if (tags) {
    return Boolean(
      tags.filter(item => item.namespace === 'LIMITED' && item.name === 'STOCK')
        .length
    );
  }
  return false;
};

export const isLimited = (tags: ILoop54Tags[]) => ({
  limitedDeal: isLimitedDeal(tags),
  limitedStock: isLimitedStock(tags)
});
