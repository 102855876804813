import { RECIPE_FILTER_PARAMS } from '../actions/recipe/async';
import { ILoop54ProductsData } from '../types/loop54Product';
import { ILoop54ItemsData } from '../types/Loop54Recipes';
import { isNumber } from './number';
import { PRODUCT_SORT_OPTS } from './sorting';
export const sortFieldHelper = (sortField: string) => {
  return (
    PRODUCT_SORT_OPTS.find(opt =>
      opt.value.includes(sortField?.replace('_desc', ''))
    ) ?? PRODUCT_SORT_OPTS[0]
  );
};

export function mapLoopDataToProductState<T>(
  response: ILoop54ItemsData | ILoop54ProductsData,
  params?
): { meta: any; data: T } {
  const data = response.items;

  const filters = response.facets.map(f => {
    const filter = f;

    return {
      options: filter.options.map(fx => ({
        ...fx,
        selected: params?.[f.name]?.includes(fx.option) ?? fx.selected,
        parent: f.name
      })),
      name: f.name
    };
  });

  const sortOrder = sortFieldHelper(params?.SortField);

  const meta = {
    pageIndex: response.currentPage,
    pageCount: response.totalPages,
    pageSize: response.pageSize,
    totalCount: response.totalCount,
    filters,
    completions: null,
    corrections: null,
    recentSearches: null,
    priceOnly:
      params?.[RECIPE_FILTER_PARAMS.PRICE_ONLY] &&
      (params?.[RECIPE_FILTER_PARAMS.PRICE_ONLY] === true ||
        params[RECIPE_FILTER_PARAMS.PRICE_ONLY] === 'true'),
    sort: sortOrder?.value ?? null,
    sortOrder: params?.SortOrder ?? null,
    revisited: null
  };
  if (params && isNumber(params.size)) {
    meta.pageIndex = params.page;
  }
  // @ts-ignore can't typecast...
  return { data, meta };
}
