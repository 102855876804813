import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import Link from '../../../components/Link';

import './GroupedCategoriesSuggestion.scss';

const GroupedCategoriesSuggestion = ({
  cateringCategoryPageItems,
  count,
  isSidebar,
  itemClickHandler,
  productCategoryPageItems,
  recipeCategoryPageItems,
  onClose,
  query,
  title,
  type
}) => {
  return (
    <div
      className={cs('c-category-suggestions', {
        'c-category-suggestions--is-sidebar': isSidebar,
        'c-category-suggestions--is-main-area': !isSidebar
      })}
    >
      <Link to={`/sok?Q=${query}&type=${type}`} onClick={onClose}>
        <h3>
          <span className="c-category-suggestions__title">
            {title}
            {count >= 0 && (
              <span className={'c-category-suggestions__title--count'}>
                {' '}
                ({count})
              </span>
            )}
          </span>
        </h3>
      </Link>
      {productCategoryPageItems.length ? (
        <ul className={'c-category-suggestions__categorized-items'}>
          {productCategoryPageItems.slice(0, 5).map(item => (
            <li
              className={'c-category-suggestions__categorized-item'}
              key={item.id}
            >
              <Link
                to={item.url}
                onClick={() => {
                  itemClickHandler();
                }}
              >
                {item.parentname
                  ? `${item.parentname}  > ${item.name}`
                  : item.name}
              </Link>
            </li>
          ))}
        </ul>
      ) : null}
      {cateringCategoryPageItems.length ? (
        <ul className={'c-category-suggestions__categorized-items'}>
          {cateringCategoryPageItems.slice(0, 5).map(item => (
            <li
              className={'c-category-suggestions__categorized-item'}
              key={item.id}
            >
              <Link
                to={item.url}
                onClick={() => {
                  itemClickHandler();
                }}
              >
                Catering {'>'} {item.name}
              </Link>
            </li>
          ))}
        </ul>
      ) : null}
      {recipeCategoryPageItems.length ? (
        <ul className={'c-category-suggestions__categorized-items'}>
          {recipeCategoryPageItems.slice(0, 5).map(item => (
            <li
              className={'c-category-suggestions__categorized-item'}
              key={item.id}
            >
              <Link
                to={item.url}
                onClick={() => {
                  itemClickHandler();
                }}
              >
                Recept {'>'} {item.name}
              </Link>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

GroupedCategoriesSuggestion.propTypes = {
  cateringCategoryPageItems: PropTypes.array,
  count: PropTypes.number,
  isSidebar: PropTypes.bool,
  itemClickHandler: PropTypes.func,
  items: PropTypes.array,
  onClose: PropTypes.func,
  productCategoryPageItems: PropTypes.array,
  recipeCategoryPageItems: PropTypes.array,
  query: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string
};

export default GroupedCategoriesSuggestion;
