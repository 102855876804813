export interface IValidParams {
  [key: string]: any;
}
export const getValidParams = (params: IValidParams) => {
  const validParams: IValidParams = {};
  Object.entries(params).forEach(([key, value]) => {
    if (
      params[key] &&
      VALID_CATEGORY_FILTER_TAGS.includes(key.toLocaleLowerCase())
    ) {
      validParams[key] =
        Array.isArray(value) && value ? value.join(',') : value;
    }
  });
  return validParams;
};

const VALID_CATEGORY_FILTER_TAGS = [
  'bargainonly', // tags KLIPP:VARA
  'currentweekdiscountonly',
  'discountonly',
  'longtimediscountonly',
  'memberpriceonly',
  'categoryname'
];
