export const LOOP_FACETS = {
  PRODUCT_MARKING: 'ProductMarkings',
  Brands: 'Brands',
  RECIPE_MARKING: 'RecipeMarkings',
  COOKING_TIMES: 'CookingTimes',
  TYPE: 'Type'
};
export interface Iloop54Facets {
  name: string;
  options: ILoop54FacetOption[];
}

export interface ILoop54FacetOption {
  option: string;
  selected: boolean;
  count: number;
  id: string;
}
