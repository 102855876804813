import { createSelector } from 'reselect';
import { splitPretty, toRouteTransform } from '../../lib/route/split';
import { isMatch } from '../../lib/route/defaults';

const paramsSelector = (state, { match }) => match.params;
const pathSelector = (_state, props) => {
  if (props.location && isMatch(props.location.pathname)) {
    return props.location.pathname;
  }
  return props.match.path;
};
const lookupSelector = state => state.navigation.pathLookup;
export const treeSelector = state => state.navigation.tree;
const headerSelector = state => state.navigation.header;
const rootRoutesSelector = state => state.navigation.rootRoutes;
const specificRouteSelector = (state, props) => props;

export const activeSelector = createSelector([pathSelector], activePath => {
  const basePath = '/' + activePath.split('/')[1];

  return basePath;
});

export const breadCrumbSelector = createSelector(
  [pathSelector, lookupSelector],
  (path, lookup) => splitPretty(path, lookup, toRouteTransform)
);

export const activeNavigationSelector = createSelector(
  [treeSelector, pathSelector, paramsSelector],
  (tree, activePath, params) => {
    const basePath = '/' + activePath.split('/')[1];
    if (!tree.children) {
      return null;
    }
    // We check if recipe/productId exists in params,
    // if it does we want to cheat a sidebar
    if (params.recipeId) {
      return tree.children.find(child => child.type === 'RecipeFolderPage');
    }
    if (params.productId) {
      return tree.children.find(child => child.type === 'ProductFolderPage');
    }
    if (params.offerId) {
      return tree.children.find(child => child.type === 'ProductFolderPage');
    }
    if (params?.cateringName) {
      return tree.children.find(child => child.type === 'CateringFolderPage');
    }

    // If not we want to show real nav
    return tree.children.find(child => child.link.url === basePath);
  }
);

export const getPageByUrl = createSelector(
  [treeSelector, specificRouteSelector],
  (tree, specificRoute) => {
    if (!tree.children) {
      return null;
    }
    return tree.children.find(child => child.link.url === specificRoute);
  }
);

function findDeepestMatchingNode(node, specificRoute) {
  // Ensure the current node's link is part of the specific route
  if (!specificRoute.includes(node?.link?.url || '')) {
    return null; // Stop searching this branch if it doesn't match
  }

  if (node.children && node.children.length > 0) {
    for (const child of node.children) {
      const result = findDeepestMatchingNode(child, specificRoute);
      if (result && result.link?.url === specificRoute) {
        return result;
      }
    }
  }

  // If no deeper match is found, return the current node
  return node;
}
export const getCategoryPageByUrl = createSelector(
  [treeSelector, specificRouteSelector],
  (tree, specificRoute) => {
    if (!tree.children || !specificRoute) {
      return null;
    }
    return findDeepestMatchingNode(tree, specificRoute);
  }
);

// TODO: This is to get a list of stores on the storelistpage.
//Might do it more extendable with lookup of specific page-types etc.
export const directChildrenOfCurrentPageSelector = createSelector(
  [treeSelector, pathSelector],
  (tree, activePath) => {
    const basePath = '/' + activePath.split('/')[1];
    if (!tree.children) {
      return null;
    }
    return tree.children.find(child => child.link.url === basePath).children;
  }
);

export const directChildrenOfSpecificPageSelector = createSelector(
  [treeSelector, specificRouteSelector],
  (tree, activeNav) => {
    if (!tree.children) {
      return null;
    }
    return tree.children.find(child => child.link.url === activeNav).children;
  }
);

// TODO How to define the sortOrder? Since the navTop and navSection
// are nested together ?
export const rootRoutesSpliterSelector = createSelector(
  [rootRoutesSelector, headerSelector],
  (rootRoutes, headerRoutes) => {
    const { navTop, navSection } = rootRoutes.reduce(
      (acc, route) => {
        let index = headerRoutes.findIndex(r => r.link.url === route.path);
        if (~index) {
          acc.navTop[index] = { ...route };
          return acc;
        }

        acc.navSection.push(route);

        return acc;
      },
      { navTop: [], navSection: [] }
    );

    return {
      navTop,
      navSection
    };
  }
);
