import { AlertBox } from '@citygross/components';
import { Icons } from '@citygross/icons';
import { BodyText } from '@citygross/typography';
import React from 'react';
import { useTheme } from 'styled-components';
import './LoginErrorBox.scss';
import { is } from 'date-fns/locale';

export type TLoginError = 'credentials' | 'unknown';

const CREDENTIALS_ERROR = {
  title: 'Fel e-postadress eller lösenord',
  description: 'Kontrollera dina uppgifter'
};

const UNKNOWN_ERROR = {
  title: 'Tekniskt fel',
  description: 'Något gick fel. Försök igen.'
};

type TLoginErrorBox = {
  error: TLoginError | null;
};
export const LoginErrorBox = ({ error }: TLoginErrorBox) => {
  const theme = useTheme();
  const isCredentialsError = error === 'credentials';
  const errorText = isCredentialsError ? CREDENTIALS_ERROR : UNKNOWN_ERROR;
  return (
    <AlertBox
      background={
        isCredentialsError
          ? theme.palette?.blueLight
          : theme.palette?.yellowLighter
      }
      borderColor={
        isCredentialsError
          ? theme.palette?.alertBlue
          : theme.palette?.brandYellow
      }
    >
      <div className="login-error-box__container">
        <Icons.Info
          width={24}
          height={24}
          color={
            isCredentialsError
              ? theme.palette?.alertBlue
              : theme.palette?.darkest
          }
        />
        <div className="login-error-box__content">
          <BodyText fontWeight="medium">{errorText.title}</BodyText>
          <BodyText>{errorText.description}</BodyText>
        </div>
      </div>
    </AlertBox>
  );
};
