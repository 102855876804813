import { TFetchExtendedSearch } from '../slices/searchSlice';
import { IExtendedLoop54SearchResult } from '../types/Loop54Search';
import { updateHistory } from './utils';
import queryParams from './queryParams';
import { TSearchFilter } from '../types/search/searchResult';
import { Iloop54Facets, LOOP_FACETS } from '../types/loop54Facets';

export const SEARCH_TYPES = {
  product: 'product',
  recipe: 'recipe',
  catered_meals: 'cateredmeal',
  category: 'category',
  page: 'page',
  store: 'store'
};
export const DEFAULT_SEARCH_TYPE = SEARCH_TYPES.product;
export const SEARCH_PAGE_SIZE = 20;
export const LOOP_SEARCH_QUERY_PARAM = 'SearchQuery';

const ignoredKeys = ['page'];

export const makeQuery = (
  params: TFetchExtendedSearch,
  paramObj: TFetchExtendedSearch
) => {
  if (params?.Brands) {
    paramObj['Brands'] = params.Brands;
  }
  if (params?.COOKING_TIMES) {
    paramObj['CookingTimes'] = params.COOKING_TIMES;
  }
  if (params?.PRODUCT_MARKING) {
    paramObj['ProductMarkings'] = params.PRODUCT_MARKING;
  }
  if (params?.RECIPE_MARKING) {
    paramObj['RecipeMarkings'] = params.RECIPE_MARKING;
  }
  if (params.type) {
    paramObj['type'] = params.type;
  }
  if (params?.SortField) {
    paramObj['SortField'] = params.SortField;
  }
  if (params?.SortField && params?.SortOrder) {
    paramObj['SortOrder'] = params.SortOrder;
  }
  return paramObj;
};

const getSearchType = typeFacet => typeFacet?.find(t => t.selected).option;

const makeSearchFilters = (facets: Iloop54Facets[], activeFilter: string) =>
  facets
    ?.filter(fx => {
      if (activeFilter === SEARCH_TYPES.product) {
        return (
          fx.name !== LOOP_FACETS.COOKING_TIMES &&
          fx.name !== LOOP_FACETS.RECIPE_MARKING
        );
      }
      if (activeFilter === SEARCH_TYPES.recipe) {
        return (
          fx.name !== LOOP_FACETS.Brands &&
          fx.name !== LOOP_FACETS.PRODUCT_MARKING &&
          fx.name !== LOOP_FACETS.RECIPE_MARKING
        );
      }
      return fx.name;
    })
    ?.map(f => {
      const filter = f;
      const options = filter.options.map(fx => ({
        count: fx.count,
        option: fx.option,
        selected: fx.selected,
        id: f.name,
        parent: f.name
      }));
      return {
        options,
        name: f.name
      };
    });

export const mapSearchResponseToState = (
  payload: IExtendedLoop54SearchResult
) => {
  // @TODO add corrections
  const { autoCompletions, searchResults, currentSearch } = payload;

  const {
    products,
    cateredMeals,
    categories,
    recipes,
    facets,
    stores,
    pageSize,
    totalCount,
    totalPages: pageCount,
    currentPage: pageIndex,
    type,
    pages
  } = searchResults;

  const typeFacet = facets?.find(f => f.name === 'Type')?.options;

  const typeCount = typeFacet?.find(t => type && t.option?.includes(type))
    ?.count;
  const activeFilter = type ?? getSearchType(typeFacet);
  const filters: TSearchFilter[] = makeSearchFilters(facets, activeFilter);
  return {
    currentSearch,
    product: {
      items: products
    },
    store: {
      items: stores
    },
    cateredmeal: {
      items: cateredMeals
    },
    recipe: {
      items: recipes
    },
    page: {
      items: pages
    },
    category: {
      items: categories
    },
    pagination: {
      completions: autoCompletions?.items ?? [],
      corrections: [],
      filters: filters,
      pageCount,
      pageIndex,
      pageSize,
      // @TODO handle sorting
      sort: '',
      totalCount: typeCount ?? totalCount
    },
    activeFilter,
    loading: false
  };
};

export const searchQueryHelper = (
  paramObj: TFetchExtendedSearch,
  thunkAPI: any,
  isPaginatedSearch?: boolean
) => {
  const { Q, page, store, location } = paramObj;
  const locationPage = page && page !== 0 ? page : 1;

  if (paramObj?.type === SEARCH_TYPES.product) {
    delete paramObj?.COOKING_TIMES;
  }
  if (paramObj?.type === SEARCH_TYPES.recipe) {
    delete paramObj?.Brands;
    delete paramObj?.PRODUCT_MARKING;
  }
  const q = makeQuery(paramObj, {
    Q: Q ?? '',
    page: locationPage,
    store
  });

  const pageToFetch = Math.max(
    0,
    Number(page || 1) - (isPaginatedSearch ? 0 : 1)
  );

  thunkAPI.dispatch(
    updateHistory(
      { ...q, page: locationPage + (isPaginatedSearch ? 1 : 0) },
      location,
      isPaginatedSearch ? ignoredKeys : []
    )
  );

  const skipValue = page === 0 ? 0 : pageToFetch * SEARCH_PAGE_SIZE;

  const query = queryParams(
    makeQuery(paramObj, {
      [LOOP_SEARCH_QUERY_PARAM]: Q ?? '',
      skip: skipValue,
      store,
      take: SEARCH_PAGE_SIZE
    })
  );

  return query;
};
