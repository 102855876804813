import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CateringCard from '../../components/CateringCard';
import ContentBlocksArea from '../../components/ContentBlocksArea/index.js';
import { getCategoryCateringProducts } from '../../selectors/cart/cartSelector.js';
import { breadCrumbSelector } from '../../selectors/routing/routingSelector.js';
import { pageSelector, pageIdSelector } from '../../selectors/page.js';
import { authenticatedSelector } from '../../selectors/user';
import { withRouter } from 'react-router-dom';
import { setVariant } from '../../lib/catering.js';
import { redirectToLogin } from '../../actions/app';
import * as cateringActions from '../../actions/catering/index.js';
import * as pageActions from '../../actions/page/index.js';

import { selectCurrentAssortment } from '../../selectors/assortments/index';
import * as ga4 from '@citygross/analytics';
import Cookie from 'js-cookie';
import { sendCateringItemAddTracking } from '../../lib/analytics/analytics';
import OverFlowContainer from '../../components/OverFlowContainer/index';
import { ILoop54CateringItem } from '../../types/loop54Catering';
import { IPage } from '../../types/episerver/page';
import { TMappedCateringItem } from '../../types/xim/catering';

const impressionListName = 'Catering category';

interface IMappedCateredMeal extends ILoop54CateringItem, TMappedCateringItem {}

interface ICateringCategoryPage {
  getProductsByCategory: () => void;
  id: number;
  catering: {
    products: IMappedCateredMeal[];
  };
  storeId: number;
  redirectToLogin: () => void;
  isAuthenticated: boolean;
  getCateringProductsByCategory: typeof cateringActions.getCateringProductsByCategory;
  getPageById: typeof pageActions.getPageById;
  page: IPage;
  title?: {
    current?: {
      name?: string;
    };
  };
}

function CateringCategoryPage({
  id,
  getCateringProductsByCategory,
  getPageById,
  catering,
  storeId,
  redirectToLogin,
  isAuthenticated,
  page,
  title
}: ICateringCategoryPage) {
  const [prevStoreNumber, setPrevStoreNumber] = useState<number | null>(null);
  useEffect(() => {
    if (storeId === prevStoreNumber) return;
    setPrevStoreNumber(storeId);

    getCateringProductsByCategory(id, {
      categoryName: title?.current?.name
    }).then(data => {
      try {
        const assortmentValuesCookie = Cookie.get('assortment_values');
        const assortmentValues =
          assortmentValuesCookie && JSON.parse(assortmentValuesCookie);
        if (assortmentValues && assortmentValues.userSiteId) {
          const ga4ImpressionItems = data?.items?.map((prod, index) => {
            let price = 0;
            let discount = 0;
            let variant = undefined;
            let cakePortions = '';
            const productCategory =
              prod.url?.substring(0, prod.url.lastIndexOf('/') + 1) ||
              undefined;

            const isCake = productCategory?.includes('tartor');
            let matchedVariant;
            if (prod.variants && prod.variants.length > 0) {
              const variants = prod.variants?.sort((a, b) =>
                a.quantityFrom > b.quantityFrom ? 1 : -1
              );

              matchedVariant = variants.find(x => x.storeDetails?.v_has_price);
              variant = matchedVariant.name;
              price = matchedVariant?.storeDetails?.v_current_price;
              discount =
                matchedVariant?.storeDetails?.v_current_price -
                matchedVariant?.storeDetails?.v_ordinary_price;
            }
            if (isCake) {
              const variantQuantities = prod.variants.filter(
                x =>
                  x.name?.toLowerCase() === 'standard' &&
                  x.storeDetails?.v_has_price
              );
              if (variantQuantities?.length) {
                cakePortions = variantQuantities?.reduce(
                  (a, b) => (a < b.quantityFrom ? a : b.quantityFrom),
                  10
                );
              } else {
                cakePortions = matchedVariant.quantityFrom;
              }
            }
            return {
              item_id: matchedVariant?.gtin || prod.id,
              item_name:
                prod.name +
                `${cakePortions ? ` - ${cakePortions} portioner` : ''}`,
              item_brand: prod.brand || 'City Gross',
              item_category: productCategory,
              item_variant: variant,

              item_list_name: impressionListName,
              item_list_id: impressionListName,
              index: index + 1,

              price: price,
              quantity: prod.minOrder || (isCake ? 1 : cakePortions),
              discount: discount
            };
          });

          ga4.viewItemList({
            items: ga4ImpressionItems,
            item_list_id: impressionListName,
            item_list_name: impressionListName
          });
        } else {
          const ga4ImpressionItems = data?.items?.map((prod, index) => {
            const productCategory =
              prod.url?.substring(0, prod.url.lastIndexOf('/') + 1) ||
              undefined;

            const isCake = productCategory?.includes('tartor');
            return {
              item_id: prod.id,
              item_name: prod.name,
              item_brand: prod.brand || 'City Gross',
              item_category: productCategory,
              item_variant: undefined,

              item_list_name: impressionListName,
              item_list_id: impressionListName,
              index: index + 1,

              price: 0,
              quantity: prod.minOrder || (isCake ? 1 : 4),
              discount: 0
            };
          });

          ga4.viewItemList({
            items: ga4ImpressionItems,
            item_list_id: impressionListName,
            item_list_name: impressionListName
          });
        }
      } catch (error) {
        console.error(error);
      }
    });
    getPageById(id);
  }, [storeId]);

  const { products } = catering;
  return (
    <>
      <OverFlowContainer>
        <ContentBlocksArea blocks={page.contentBlocks} />
      </OverFlowContainer>
      <div className={'grid-container u-mx-0'}>
        {products &&
          products.length > 0 &&
          products.map((product, index) => {
            const hasSides = product.sides && product.sides.length > 0;
            const hasVariants =
              product.variants && Object.keys(product.variants).length > 0;

            const isBuffe = !!product.minOrder && !!product.maxOrder;
            const variants = product.variants && Object.keys(product.variants);
            const isStandard = variants?.findIndex(
              variant => variant.toLowerCase() === 'standard'
            );
            return hasVariants && product.sellable ? (
              <CateringCard
                id={product.id}
                key={product.id}
                title={product.name}
                sellable={product.sellable}
                label={product.label}
                singularLabel={product.singularLabel}
                description={product.description}
                unit={product.unit}
                variants={hasVariants && product.variants}
                hasVariants={hasVariants}
                hasSides={hasSides}
                sides={(hasSides && product.sides) || []}
                url={product.url}
                productImageUrl={product.image.url}
                isBuffe={isBuffe}
                minOrder={product.minOrder}
                maxOrder={product.maxOrder}
                sendCateringItemAddTracking={orderlines =>
                  sendCateringItemAddTracking(product, orderlines, index + 1)
                }
                defaultVariant={
                  ((variants &&
                    variants?.length &&
                    isStandard &&
                    variants?.[isStandard]) ||
                    variants?.[0]) ??
                  'standard'
                }
                setVariant={(variantType, selectedVariant) =>
                  setVariant(product.id, variantType, selectedVariant)
                }
                isAuthenticated={isAuthenticated}
                redirectToLogin={redirectToLogin}
                impressionListName={impressionListName}
                impressionListPosition={index + 1}
              />
            ) : null;
          })}
      </div>
      <OverFlowContainer>
        <ContentBlocksArea blocks={page.bottomContentBlocks} />
      </OverFlowContainer>
    </>
  );
}

const mapStateToProps = (state, props) => {
  return {
    storeId: Number(selectCurrentAssortment(state)),
    catering: getCategoryCateringProducts(state),
    title: breadCrumbSelector(state, props),
    isAuthenticated: authenticatedSelector(state),
    id: pageIdSelector(state, props),
    page: pageSelector(state, props),
    fetching: state.product.fetching
  };
};

const mapDispatchToProps = {
  getPageById: pageActions.getPageById,
  getCateringProductsByCategory: cateringActions.getCateringProductsByCategory,
  redirectToLogin
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CateringCategoryPage)
);
