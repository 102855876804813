import { fetchLoopProductById } from '../api/endpoints/product';
import { fetchLoopRecipesByIds } from '../api/endpoints/recipe.ts';
import { graphQlQuery } from './utils';
import { fetchCateringProductsByVariant } from '../api/endpoints/cateredmeals';
import moment from 'moment';

export const findNext = carts => {
  const sortedCarts = carts.sort(
    (a, b) => new Date(b.modifiedDate) - new Date(a.modifiedDate)
  );

  return sortedCarts.length
    ? sortedCarts.find(cart => cart.replacementForOrderId !== null) ||
        sortedCarts.find(cart => !cart.checkoutCompletedDate) ||
        null
    : null;
};

export const findNextAfterThis = (carts, currentCartId) => {
  const sortedCarts = carts.sort(
    (a, b) => new Date(b.modifiedDate) - new Date(a.modifiedDate)
  );

  return sortedCarts.length
    ? sortedCarts.find(
        cart => cart.replacementForOrderId !== null && cart.id !== currentCartId
      ) ||
        sortedCarts.find(
          cart => !cart.checkoutCompletedDate && cart.id !== currentCartId
        ) ||
        null
    : null;
};

export const getCharges = items =>
  items.filter(item => item.typeId === 'charge');
export const getItems = items => items.filter(item => item.typeId === 'item');

const is = ({ typeId }, type) => typeId?.toLowerCase() === type;
const isSubType = ({ subType }, type) => subType.toLowerCase() === type;

export const isRecipe = item => isSubType(item, 'recipebag');
export const isProduct = item => is(item, 'item');
export const isCateredMeal = item => isSubType(item, 'cateredmeal');
export const isCharge = item => is(item, 'charge');
export const isEmpty = cart =>
  !(cart.items.length || cart.recipes.length || cart.cateredMeals?.length);

export const splitByType = cart => {
  const { items = [], ...rest } = cart;
  const { items: newItems, recipes, charges, cateredMeals } = items.reduce(
    (acc, cartItem) => {
      if (isRecipe(cartItem)) {
        acc.recipes.push(cartItem);
      } else if (isCateredMeal(cartItem)) {
        acc.cateredMeals.push(cartItem);
      } else if (isCharge(cartItem)) {
        acc.charges.push(cartItem);
      } else {
        acc.items.push(cartItem);
      }
      return acc;
    },
    { items: [], recipes: [], charges: [], cateredMeals: [] }
  );
  return {
    ...rest,
    items: newItems,
    recipes,
    cateredMeals,
    charges
  };
};

const execAsyncOrDefault = (ids, fetcher, options) =>
  ids.length
    ? fetcher(ids, options?.sort ?? '', options?.size ?? '')
        .then(({ data }) => data)
        .catch(e => [])
    : Promise.resolve([]);
const execLoopAsyncOrDefault = (ids, fetcher, options) =>
  ids.length
    ? fetcher(ids, options?.sort ?? '', options?.size ?? '')
        .then(({ data }) => {
          return data?.items;
        })
        .catch(e => [])
    : Promise.resolve([]);

export const fetchCartItems = async cart => {
  const splittedCart = splitByType(cart);
  const ids = splittedCart.items
    .map(({ itemNo }) => itemNo)
    .filter(itemNo => !!itemNo); // Removes null, should probably not happen. But will now until stable

  const recipeIds = splittedCart.recipes
    .reduce((acc, recipeBag) => {
      const { recipes, editableRecipes } = recipeBag;
      const regularIds = recipes ? recipes.map(({ recipeId }) => recipeId) : [];
      const editableIds = editableRecipes
        ? editableRecipes.map(({ recipeId }) => recipeId)
        : [];
      acc = acc.concat(regularIds).concat(editableIds);
      return acc;
    }, [])
    .filter(item => !!item); // Removes null, should probably not happen. But will now until stable

  const cateredMealIds = splittedCart?.cateredMeals?.map(
    cateredMeal => cateredMeal.itemNo
  );
  try {
    const productsLoopPromise = await execLoopAsyncOrDefault(
      ids,
      fetchLoopProductById,
      {
        size: ids?.length
      }
    );
    const recipesPromise = await execAsyncOrDefault(
      recipeIds,
      fetchLoopRecipesByIds
    );
    const cateredMealPromise = await execAsyncOrDefault(
      cateredMealIds,
      fetchCateringProductsByVariant
    );
    let cateredMealVariants = [];
    if (cateredMealPromise?.items?.length) {
      cateredMealPromise.items.forEach(cateredMeal => {
        cateredMeal?.variants?.forEach(variant => {
          cateredMealIds?.includes(variant.id) &&
            cateredMealVariants.push({
              sides: cateredMeal.sides,
              unit: cateredMeal.unit,
              url: cateredMeal?.url,
              ...variant
            });
        });
      });
    }
    return [cart, [productsLoopPromise, recipesPromise, cateredMealVariants]];
  } catch (e) {
    return [cart];
  }
};

export const getPickingFees = async (
  deliveryMethod,
  zipCode,
  cartId,
  siteId,
  isReplacementOrder
) => {
  const data = await graphQlQuery(
    `query DeliverySlotsAvailability(
    $deliveryMethod: CustomerDeliveryMethod!,
    $zipCode: String,
    $cartId: String!,
    $siteId: Int,
    $isNewOrder: Boolean!
  ) {
  deliveryslotsAvailability(
    deliveryMethod: $deliveryMethod
    zipCode: $zipCode
    cartId: $cartId
    siteId: $siteId
    isNewOrder: $isNewOrder
  ) {
    date
    deliverySlots {
      pickingFee
      currentPickingFee
      freightFee
      pickingFeeLimit
      currentPickingFeeLimit
      originalPickingFee
      isEnabled
    }}}`,
    {
      deliveryMethod: deliveryMethod === 'pickupAtStore' ? 'Pickup' : 'Home',
      zipCode: deliveryMethod === 'pickupAtStore' ? '' : zipCode,
      cartId: cartId,
      siteId: siteId || 0,
      isNewOrder: !isReplacementOrder
    }
  );
  if (
    data &&
    data.deliveryslotsAvailability &&
    data.deliveryslotsAvailability.length > 0
  ) {
    let deliverySlots = [];
    data.deliveryslotsAvailability.forEach(slot => {
      deliverySlots = [...deliverySlots, ...slot.deliverySlots];
    });

    const lowestPickingFee = deliverySlots.reduce((prev, curr) => {
      return prev.currentPickingFee < curr.currentPickingFee ? prev : curr;
    }).currentPickingFee;

    const highestPickingFee = deliverySlots.reduce((prev, curr) => {
      return prev.currentPickingFee > curr.currentPickingFee ? prev : curr;
    }).currentPickingFee;

    const firstAvailableDay = data.deliveryslotsAvailability
      .sort((a, b) => (a.date > b.date ? 1 : -1))
      ?.find(
        day =>
          day.deliverySlots?.length > 0 &&
          day.deliverySlots?.some(slot => slot.isEnabled)
      );
    const firstAvailableSlot = firstAvailableDay.deliverySlots.reduce(
      (prev, curr) => {
        return prev.pickingFeeLimit < curr.pickingFeeLimit ? prev : curr;
      }
    );
    return {
      lowestPickingFee,
      highestPickingFee,
      lowestPickingFeeLimit: firstAvailableSlot?.pickingFeeLimit,
      originalPickingFee: firstAvailableSlot?.pickingFee
    };
  }
};
const OpenReservationsId = {
  Low: 'LOW',
  None: 'NONE',
  Ok: 'OK'
};
export const getFirstDeliveryDate = async (
  deliveryMethod,
  zipCode,
  cartId,
  siteId,
  isReplacementOrder
) => {
  const data = await graphQlQuery(
    `query DeliverySlotsAvailability(
    $deliveryMethod: CustomerDeliveryMethod!,
    $zipCode: String,
    $cartId: String!,
    $siteId: Int,
    $isNewOrder: Boolean!
  ) {
  deliveryslotsAvailability(
    deliveryMethod: $deliveryMethod
    zipCode: $zipCode
    cartId: $cartId
    siteId: $siteId
    isNewOrder: $isNewOrder
  ) {
    date
    openReservationsId
    deliverySlots {
      isEnabled
      deliveryDate
      deliveryFromTime
      deliveryToTime
    }}}`,
    {
      deliveryMethod: deliveryMethod === 'pickupAtStore' ? 'Pickup' : 'Home',
      zipCode: deliveryMethod === 'pickupAtStore' ? '' : zipCode.toString(),
      cartId: cartId,
      siteId: siteId,
      isNewOrder: !isReplacementOrder
    }
  );
  if (
    data &&
    data.deliveryslotsAvailability &&
    data.deliveryslotsAvailability.length > 0
  ) {
    const firstSlot = data.deliveryslotsAvailability
      ?.find(day => day.openReservationsId === OpenReservationsId.Ok)
      ?.deliverySlots?.find(slot => slot.isEnabled);

    return firstSlot;
  }
  return null;
};
export const getCateredDeliveryDates = async (
  cartId,
  siteId,
  isReplacementOrder
) => {
  const data = await graphQlQuery(
    `query DeliverySlotsAvailability(
    $deliveryMethod: CustomerDeliveryMethod!,
    $zipCode: String,
    $cartId: String!,
    $siteId: Int,
    $isNewOrder: Boolean!
  ) {
  deliveryslotsAvailability(
    deliveryMethod: $deliveryMethod
    zipCode: $zipCode
    cartId: $cartId
    siteId: $siteId
    isNewOrder: $isNewOrder
  ) {
    date
    openReservationsId
    deliverySlots {
      isEnabled
      deliveryDate
      deliveryFromTime
      deliveryToTime 
      maxCateredReservations
      cateredReservations
      lastReservationDateWithCateredMeal
    }}}`,
    {
      deliveryMethod: 'Pickup',
      zipCode: '',
      cartId: cartId,
      siteId: siteId,
      isNewOrder: !isReplacementOrder
    }
  );

  if (
    data &&
    data.deliveryslotsAvailability &&
    data.deliveryslotsAvailability.length > 0
  ) {
    const today = new Date();
    const fullSlots = data?.deliveryslotsAvailability?.filter(
      slots =>
        slots?.date >= moment(today).format('YYYY-MM-DD') &&
        slots?.deliverySlots?.some(
          slot =>
            slot.cateredReservations >= slot.maxCateredReservations &&
            slot?.lastReservationDateWithCateredMeal >=
              moment(today).format('YYYY-MM-DD')
        )
    );
    return fullSlots;
  }
  return null;
};

export const calculateTotalWithLowestPickingFee = (
  currentTotal,
  chargesArray,
  lowestPickingFee = 0
) => {
  let pickingFee = 0;

  const pickingFeeCharge = chargesArray?.find(
    charge => charge.subTypeId === 'ChargePickingFee'
  );
  if (pickingFeeCharge)
    pickingFeeCharge.price && pickingFeeCharge.price.gross
      ? (pickingFee += pickingFeeCharge.price.gross)
      : (pickingFee += pickingFeeCharge.amount);

  return currentTotal - pickingFee + lowestPickingFee;
};

export const lowestPickingFeeExists = lowestPickingFee => {
  return typeof lowestPickingFee === 'number';
};
